import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import {STATUS} from "@core/constants/singleProducts";
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import useStores from "../../../../useStores";
import {columns} from "../columns";
import {TABS} from "../constants";

const BaseMaterials = ({setSelected, selected, tab, campaignId}) => {
  const {SingleProductStore} = useStores();

  const {status, data: products, total, fetch} = SingleProductStore.singleProducts;

  const paramsByTab = {
    [TABS.AVAILABLE_BASE_MATERIALS]: {...fetch, singleCampaign: null, status: STATUS.AVAILABLE},
    [TABS.SELECTED_BASE_MATERIALS]: {...fetch, singleCampaign: campaignId, status: STATUS.IN_USE}
  };

  useEffect(() => {
    SingleProductStore.singleProducts.load({offset: 0, ...paramsByTab[tab]});

    return () => SingleProductStore.singleProducts.reset();
  }, [tab]);

  return (
    <Table
      setSelectedItems={setSelected}
      selectedItems={selected}
      allowRowSelection
      items={products}
      columns={columns}
      isLoaded={status.loaded}
      sort={fetch.sort}
      setSort={(sort) => SingleProductStore.singleProducts.load({sort, ...paramsByTab})}
      total={total}
      noDataText="No products."
    >
      <TableFooter
        saveTablePageToQueryParams
        isLoaded={status.loaded}
        items={products}
        total={total}
        limit={fetch.limit}
        offset={fetch.offset}
        onOffsetChange={(offset) => SingleProductStore.singleProducts.load({offset, ...paramsByTab})}
        onLimitChange={(limit) => SingleProductStore.singleProducts.load({limit, offset: 0, ...paramsByTab})}
      />
    </Table>
  );
};

export default observer(BaseMaterials);