import useResize from "@core/hooks/useResize";
import React, {useRef} from "react";
import {Group, Circle, Layer, Stage} from "react-konva";

const PADDING = 20;
const SHADOW_COLOR = "#000000";
const DEFAULT_SHADOW_BLUR = 1;
const ACTIVE_SHADOW_BLUR = 3;

const ItemsCanvas = ({items, activeItem, setActiveItem, onItemClick}) => {
  const stage = useRef({});
  const container = useRef({});

  const {width} = useResize(container);

  const center = {
    x: container.current.offsetWidth / 2,
    y: container.current.offsetWidth / 2,
  };

  const onMouseEnter = (index) => {
    setActiveItem(index);
    const container = stage.current.container();
    container.style.cursor = "pointer";
  };

  const onMouseLeave = () => {
    setActiveItem(null);
    const container = stage.current.container();
    container.style.cursor = "default";
  };

  return (
    <div ref={container}>
      <Stage
        ref={stage}
        width={width}
        height={width}
      >
        {container.current.offsetWidth && (
          <Layer>
            <Group
              y={PADDING}
              x={center.x}
            >
              {items.map((item, index) => {
                const radius = container.current.offsetWidth * item.radius;
                const y = radius + container.current.offsetWidth * item.y;
                const x = container.current.offsetWidth * item.x;
                const defaultShadowBlur = item.shadow ? DEFAULT_SHADOW_BLUR : 0;
                const shadowBlur = activeItem === index ? ACTIVE_SHADOW_BLUR : defaultShadowBlur;

                return (
                  <Circle
                    key={index}
                    x={x}
                    y={y}
                    radius={radius}
                    stroke={item.color}
                    strokeWidth={item.strokeWidth}
                    shadowColor={SHADOW_COLOR}
                    shadowBlur={shadowBlur}
                    fillEnabled={false}
                    hitStrokeWidth={item.strokeWidth + 2}
                    onMouseEnter={() => onMouseEnter(index)}
                    onMouseLeave={onMouseLeave}
                    onClick={onItemClick}
                  />
                );
              })}
            </Group>
          </Layer>
        )}
      </Stage>
    </div>
  );
};

export default ItemsCanvas;