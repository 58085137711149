import {TEST_RESULTS} from "@core/constants/testResults";
import React from "react";
import {Grid, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import {omit} from "ramda";
import * as yup from "yup";
import {Input, Select} from "@core/components/Form";
import IdentificationOfInspectedItems from "../../IdentificationOfInspectedItems";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import styles from "./styles";

const HydrostaticTest = ({
  classes,
  test,
  closeNewTest,
  saveTest,
  isProducerTest,
  inspectedCertificates,
  formRef,
  ...props
}) => {
  const validationSchema = yup.object().shape({
    testingFluid: yup.string().required("Testing fluid is required!"),
    minTime: yup.number()
      .positive("Should to be > 0")
      .typeError("Minimal holding time should be a number!")
      .required("Minimal holding time is required!"),
    minHoldingTimeUnits: yup.string().required("Minimal holding time units are required!"),
    pressureUnit: yup.string().required("Pressure unit is required!"),
    pressure: yup.number().typeError("Should be a number").required("Pressure is required!").positive("Should to be > 0"),
    witnesses: yup.array().of(yup.object()),
    notes: yup.string(),
    result: yup.string().required("Result is required!"),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const initialValues = {
    minTime: test?.properties.minTime || "",
    testingFluid: test?.properties.testingFluid || "",
    minHoldingTimeUnits: test?.properties.minHoldingTimeUnits || "sec",
    pressureUnit: test?.properties.pressureUnit || "bar",
    pressure: test?.properties.pressure || "",
    witnesses: test?.witnesses.map((witness) => witness.company) || [],
    notes: test?.properties.notes || "",
    result: test?.properties.result || "",
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || [],
    testExecutionDate: test?.properties.testExecutionDate || "",
  };

  const onSubmit = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);

    if (!closeNewTest) return;

    props.closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
      validateOnMount
      render={({
        values: {
          minTime,
          result,
          testingFluid,
          minHoldingTimeUnits,
          pressure,
          pressureUnit,
          notes,
        },
        errors,
        handleChange,
        touched,
        setFieldValue,
        setFieldTouched
      }) => {
        const change = (name, e) => {
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Grid container spacing={2}>
            {isProducerTest && (
              <Grid item xs={12}>
                <h1>Hydrostatic test</h1>
              </Grid>
            )}

            <Grid item container xs={isProducerTest ? 8 : 6} spacing={3}>
              <Grid container item xs={6} wrap="nowrap" alignItems="flex-end">
                <Grid item>
                  <Input
                    label='Minimum holding time'
                    name='minTime'
                    value={minTime}
                    required
                    error={Boolean(errors.minTime) && touched.minTime}
                    errorMessage={errors.minTime}
                    onChange={(e) => change("minTime", e)}
                    onBlur={() => setFieldValue("minTime", minTime.trim())}
                  />
                </Grid>

                <Grid item>
                  <Select
                    className={classes.unit}
                    defaultValue="sec"
                    value={minHoldingTimeUnits}
                    name='minHoldingTimeUnits'
                    error={Boolean(errors.minHoldingTimeUnits) && touched.minHoldingTimeUnits}
                    onChange={(event) => change("minHoldingTimeUnits", event)}
                  >
                    {["sec", "min", "hr"].map((E) => <MenuItem key={E} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>
              </Grid>

              <Grid item xs={6} container wrap="nowrap" alignItems="flex-end">
                <Grid item>
                  <Input
                    label='Test pressure'
                    name='pressure'
                    value={pressure}
                    type={"number"}
                    required
                    error={Boolean(errors.pressure) && touched.pressure}
                    errorMessage={errors.pressure}
                    onChange={(e) => change("pressure", e)}
                  />
                </Grid>

                <Grid item>
                  <Select
                    className={classes.unit}
                    defaultValue="bar"
                    value={pressureUnit}
                    name='pressureUnit'
                    error={Boolean(errors.pressureUnit) && touched.pressureUnit}
                    onChange={(event) => change("pressureUnit", event)}
                  >
                    {["bar", "MPa", "psi"].map((E) => <MenuItem key={E} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container spacing={3}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Input
                  label='Testing fluid'
                  name='testingFluid'
                  value={testingFluid}
                  required
                  error={Boolean(errors.testingFluid) && touched.testingFluid}
                  errorMessage={errors.testingFluid}
                  onChange={(e) => change("testingFluid", e)}
                  onBlur={() => setFieldValue("testingFluid", testingFluid.trim())}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <IdentificationOfInspectedItems
                isProducerTest={isProducerTest}
                inspectedCertificates={inspectedCertificates}
                test={test}
              />
            </Grid>

            <Grid container item spacing={3}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Select
                  value={result}
                  name='result'
                  label='Result'
                  required
                  onChange={(event) => change("result", event)}
                >
                  <MenuItem value={TEST_RESULTS.ACCEPTABLE}>{TEST_RESULTS.ACCEPTABLE}</MenuItem>
                  <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE}>{TEST_RESULTS.NOT_ACCEPTABLE}</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid container item spacing={3}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Input
                  multiline
                  rows={3}
                  label='Additional remarks'
                  name='notes'
                  value={notes}
                  onChange={(e) => change("notes", e)}
                />
              </Grid>
            </Grid>

            {isProducerTest ? (
              <ProducerTestFooter />
            ) : (
              <Grid item xs={12}>
                <LabTestFooter
                  onSubmit={onSubmit}
                  result={result}
                />
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default withStyles(HydrostaticTest, styles);
