import {Typography, Box} from "@mui/material";
import React from "react";

const FormErrors = ({children}) => {
  return (
    <Box>
      <Typography variant="h6" sx={{color: (theme) => theme.palette.warning.text}} gutterBottom>
        Please complete mandatory fields or correct invalid data
      </Typography>
      {children}
    </Box>
  );
};

export default FormErrors;