import * as yup from "yup";
import {getInitialValues} from "./services";

export const END_PRODUCT = {
  quantity: "",
};

export const SPECIFICATION = {
  norm: "",
  grades: [],
};

export const initialValuesDefault = getInitialValues();

export const validationSchema = yup.lazy(((values) => {
  return yup.object().shape({
    name: yup.string().required("Campaign name is required"),
    type: yup.string().required("Campaign type is required"),
    certificationType: yup.string().required("Certification type is required"),

    connectToPO: yup.bool(),
    existingMaterial: yup.bool(),

    customer: yup.string().when("connectToPO", {
      is: true,
      then: (schema) => schema.required("Customer is required"),
    }),
    purchaseOrder: yup.object().shape({
      orderNumber: values.connectToPO ? yup.string().required("Purchase order number is required") : yup.string(),
    }),
    manufacturingLocation: yup.string().required("Manufacturing location is required"),
    properties: yup.object().shape({
      product: yup.object().shape({
        qcpName: yup.string(),
        qcp: yup.string().when("qcpName", {
          is: true,
          then: (schema) => schema.required("QCP/ITP item is required"),
        }),
        productType: yup.object().shape({
          name: yup.string(),
          internalName: yup.string().required("Product type is required"),
          properties: yup.array().of(yup.object().shape({
            name: yup.string().required(),
            label: yup.string().required(),
            measurements: yup.string(),
          })),
        }),
        materialSpecifications: yup.array().of(yup.object().shape({
          norm: yup.string().required("Material specification is required"),
          grades: yup.array().of(yup.string()).min(1, "Grade / UNS is required").required("Grade / UNS is required"),
        })),
        nominalSizes: yup.array().of(yup.object().shape({
          quantity: yup.number().min(1).required("Product quantity is required"),
          ...values.properties.product.productType.properties.reduce((acc, {name, label}) => {
            acc[name] = yup.string().test(name, null, function (value) {
              return value || this.createError({message: `${label} is required`, path: this.path});
            });

            return acc;
          }, {}),
        })),
      }),
      statistics: yup.object().shape({
        estimatedHeats: yup.number()
          .min(1, "Should be a positive number")
          .test("estimatedHeats", "Estimated amount of heats is required", function (value) {
            const parent = this.from[2];
            const existingMaterial = parent.value.existingMaterial;

            return existingMaterial || value;
          }),
        estimatedHeatLots: yup.number()
          .min(1, "Should be a positive number")
          .test("estimatedHeatLots", "Estimated amount of lots is required", function (value) {
            const parent = this.from[2];
            const existingMaterial = parent.value.existingMaterial;

            return existingMaterial || value;
          }),
      }),
    }),
    singleProducts: yup.array().of(yup.string()),
  });
}));