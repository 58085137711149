import React from "react";
import FormErrors from "./components/FormErrors";
import TestResultTag from "@core/components/TestResultTag";
import ValidationErrors from "@core/components/TestForms/ValidationErrors";

const TestFormResult = ({isValid, result}) => {
  if(!isValid) {
    return (
      <ValidationErrors
        container={FormErrors}
      />
    );
  }
  
  if(result) {
    return (
      <TestResultTag
        result={result}
      />
    ); 
  }
  
  return null;
};

export default TestFormResult;