import React, {useMemo} from "react";
import {Box, Grid, Divider} from "@mui/material";
import MenuGroup from "../MenuGroup";
import useStores from "../../../../../../../../../useStores";
import {MENU_BY_MODULE} from "../../../../constants";
import {getMenuItemEnabled} from "../../services";

const MenuList = ({displayTitle}) => {
  const {UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;
  const role = UserStore.user.data.role;
  const features = UserStore.features;

  const menuGroups = useMemo(() => {
    return MENU_BY_MODULE[module.name].reduce((acc, group) => {
      const items = group.items
        .filter((item) => getMenuItemEnabled(item, features, role, module))
        .map((item) => {
          const items = item.items ? item.items.filter((item) => getMenuItemEnabled(item, features, role, module)) : [];

          return ({...item, items});
        });

      if(items.length) acc.push({...group, items});

      return acc;
    }, []);
  }, [UserStore.user.data._id, UserStore.features]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          marginBottom: 9,
          "& img": {
            position: "absolute",
            marginLeft: -1,
          }
        }}
      >
        <img
          height={40}
          src="/images/black-logo.svg"
        />
      </Box>
      <Grid item xs={12} container spacing={2} direction="column">
        {menuGroups.map((group, index) => (
          <React.Fragment key={index}>
            {!!index && (
              <Grid item>
                <Divider />
              </Grid>
            )}
            <Grid item>
              <MenuGroup
                displayTitle={displayTitle}
                title={group.title}
                items={group.items}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
};

export default MenuList;