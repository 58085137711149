import React from "react";
import {
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik, FieldArray} from "formik";
import * as yup from "yup";
import {Input} from "@core/components/Form";
import TestFooter from "../../LabTestFooter";
import styles from "./styles";

const TreatmentTest = ({classes, test, saveTest, formRef}) => {
  const validationSchema = yup.object().shape({
    elements: yup.array().of(yup.object().shape({
      treatment: yup.string().required("Treatment is required"),
      description: yup.string().required("Description is required"),
    })).required(),
  });

  const initialValues = {
    elements: test.properties.elements || [],
  };

  return (
    <div>
      <Formik
        validateOnMount
        onSubmit={saveTest}
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        render={(props) => {
          const {
            values: {elements},
            handleChange,
            setFieldTouched,
            setFieldValue
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          return <>
            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={7}>
                <FieldArray
                  name="elements"
                >
                  {({remove, push}) => {

                    return (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell size='small'>Treatment</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        {elements && elements.length > 0 ? (
                          <TableBody>
                            {elements.map((element, index) => (
                              <TableRow key={`treatmentTableRow${index}`}>
                                <TableCell>
                                  <Input
                                    name={`elements.${index}.treatment`}
                                    value={element.treatment}
                                    onChange={(e) => change(`elements.${index}.treatment`, e)}
                                    onBlur={() => setFieldValue(`elements.${index}.treatment`, element.treatment.trim())}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Input
                                    name={`elements.${index}.description`}
                                    value={element.description}
                                    onChange={(e) => change(`elements.${index}.description`, e)}
                                    onBlur={() => setFieldValue(`elements.${index}.description`, element.description.trim())}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.btnAdd}
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : null
                        }
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={3} className={classes.btnAddCell}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.btnAdd}
                                onClick={() => push("")}
                              >
                                Add
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    );
                  }}

                </FieldArray>
              </Grid>
              <Grid item xs={12} className={classes.gridRoot}>
                <TestFooter
                  onSubmit={saveTest}
                />
              </Grid>
            </Grid>
          </>;
        }}
      />

    </div>
  );
};

export default withStyles(TreatmentTest, styles);
