import ProducerTestFooter from "@core/components/ProducerTestFooter";
import {TEST_RESULTS} from "@core/constants/testResults";
import React, {useState} from "react";
import {FormControlLabel, Grid, MenuItem, Radio, RadioGroup} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import {observer} from "mobx-react";
import * as yup from "yup";
import {indexBy, prop, keys, omit, isEmpty} from "ramda";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {TEST_UNITS, TYPES} from "@core/constants/test";
import {PROOFLOAD_TYPES, PROOFLOAD_TYPES_TITLES} from "./data";
import styles from "./styles";

const validationSchema = yup.object().shape({
  norm: yup.string().required("Material specification is required"),
  grade: yup.string().required("Grade / UNS is required!"),
  type: yup.string().required("Type is required!"),
  nominalSize: yup.string().required("Nominal size is required!"),
  threadsPitch: yup.string().required("Threads pitch is required!"),
  stressArea: yup.string().required("Stress area is required!"),
  proofLoad: yup.string().required("Proof load is required"),
  result: yup.string().required("Result is required!"),
  inspectionDate: yup.string(),
  inspectorJobNumber: yup.string(),
  tags: yup.array().of(yup.string()),
  testExecutionDate: yup.string(),
});

const ProofloadTest = observer(({classes, ...props}) => {
  const [grades, setGrades] = useState({});

  useFetchTestNorms(TYPES.PROOFLOAD, props.initialValues?.norm, setGrades);

  const initialValues = {
    norm: "",
    grade: "",
    type: "",
    nominalSize: "",
    threadsPitch: "",
    stressArea: "",
    proofLoad: "",
    result: "",
    units: TEST_UNITS.METRIC,
    witnesses: props.test.witnesses.map((witness) => witness.company),
    inspectionDate: props.test.inspectionDate || "",
    inspectorJobNumber: props.test.inspectorJobNumber || "",
    tags: [],
    testExecutionDate: "",
  };
  
  return (
    <Formik
      innerRef={props.formRef}
      enableReinitialize
      onSubmit={(values) => {
        props.saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});

        if (!props.closeNewTest) return;

        props.closeNewTest();
      }}
      initialValues={{...initialValues, ...props.test.properties}}
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          setFieldValue,
          values
        } = props;

        const grade = values.grade && grades[values.grade] ? grades[values.grade] : {};
        const nominalSizes = grade.NominalSize ? grade.NominalSize[values.units] : {};

        const onUnits = (event) => {
          setFieldValue("units", event.target.value);
          setFieldValue("nominalSize", "");
          setFieldValue("threadsPitch", "");
          setFieldValue("stressArea", "");
          setFieldValue("proofLoad", "");
          setFieldValue("type", "");
          setFieldValue("result", "");
        };

        const changeLimits = (nominalSize, type) => {
          if (!nominalSizes[nominalSize]) return;

          const {ThreadsPitch: threadsPitch, StressArea: stressArea, HeavyHex: heavyHex, Hex: hex} = nominalSizes[nominalSize];

          setFieldValue("threadsPitch", threadsPitch, false);
          setFieldValue("stressArea", stressArea, false);

          if (!type) return;

          const proofLoad = type === PROOFLOAD_TYPES.HEAVY_HEX ? heavyHex : hex;

          setFieldValue("proofLoad", proofLoad, false);
        };

        return (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <h1>Proofload test</h1>
            </Grid>
            <Grid item xs={12} container spacing={5} alignItems="flex-end">
              <Grid item xs={4}>
                <NormAutocomplete
                  label="Material Specification"
                  name="norm"
                  testType={TYPES.PROOFLOAD}
                  onChange={({norm, value}) => {
                    setFieldValue("norm", norm);
                    setGrades(value ? indexBy(prop("Material"), value) : {});
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectField
                  disabled={!values.norm || isEmpty(grades)}
                  label="Grade / UNS"
                  name="grade"
                  placeholder={isEmpty(grades) && "Material specification not supported"}
                  required
                  onChange={() => changeLimits(values.nominalSize, values.type)}
                >
                  {keys(grades).map((grade) => (
                    <MenuItem key={grade} value={grade}>
                      {grade}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={4}>
                <RadioGroup
                  classes={{root: classes.units}}
                  row
                  value={values.units || TEST_UNITS.METRIC}
                  onChange={onUnits}
                >
                  <FormControlLabel value={TEST_UNITS.METRIC} control={<Radio color="primary" />}
                    label={TEST_UNITS.METRIC} />
                  <FormControlLabel value={TEST_UNITS.IMPERIAL} control={<Radio color="primary" />}
                    label={TEST_UNITS.IMPERIAL} />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={5}>
              <Grid item xs={4}>
                <SelectField
                  disabled={!values.grade || isEmpty(grades)}
                  placeholder={isEmpty(grades) && "Material specification not supported"}
                  label="Nominal size"
                  name="nominalSize"
                  required
                  onChange={(value) => changeLimits(value, values.type)}
                >
                  {keys(nominalSizes).map((nominalSize) => (
                    <MenuItem key={nominalSize} value={nominalSize}>
                      {nominalSize}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Threads pitch"
                  name="threadsPitch"
                  placeholder='0'
                  type="number"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Stress area"
                  name="stressArea"
                  placeholder='0'
                  type="number"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={5}>
              <Grid item xs={4}>
                <SelectField
                  label="Type"
                  name="type"
                  required
                  onChange={(value) => changeLimits(values.nominalSize, value)}
                >
                  {keys(PROOFLOAD_TYPES).map((type) => (
                    <MenuItem key={PROOFLOAD_TYPES[type]} value={PROOFLOAD_TYPES[type]}>
                      {PROOFLOAD_TYPES_TITLES[PROOFLOAD_TYPES[type]]}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Proof load"
                  name="proofLoad"
                  placeholder='0'
                  type="number"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={5}>
              <Grid item xs={8}>
                <SelectField
                  disabled={!values.proofLoad}
                  label="Result"
                  name="result"
                  required
                >
                  <MenuItem value={TEST_RESULTS.ACCEPTABLE}>
                    The nut resisted the proof load without stripping or rupture, and is removable from the test mandrel
                    by the fingers after the load is released
                  </MenuItem>
                  <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE}>
                    The nut didn't resist the proof load
                  </MenuItem>
                </SelectField>
              </Grid>
            </Grid>
            <ProducerTestFooter />
          </Grid>
        );
      }}
    </Formik>
  );
});

export default withStyles(ProofloadTest, styles);
