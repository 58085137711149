import React, {useEffect, useState} from "react";
import {Step, StepContent, StepLabel, Stepper} from "@mui/material";
import ChooseTests from "./components/ChooseTests";
import SelectedTests from "./components/SelectedTests";
import {observer} from "mobx-react";
import useStores from "../../../../useStores";
import referenceService from "@core/api/reference-service";
import {
  getTestsTxPayloadAndHash,
  submitTestsHashAndDataAsync
} from "@core/services/getTestTxPayloadSubmitHash";
import WS from "@core/api/socketConnection";

function MultiSignFlow() {
  const {UserStore, SigningStore, NotificationStore} = useStores();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedTests, setSelectedTests] = useState([]);
  const [onSignAllInProgress, setOnSignAllInProgress] = useState(false);

  useEffect(() => {
    WS.listen("transaction:test:submit", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINE") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      NotificationStore.showSuccess("Successfully approved!");
      setActiveStep(0);
    });

    return () => {
      WS.remove("transaction:test:submit");
    };
  }, []);

  const onSignAll = async () => {
    const userId = UserStore.user.data._id;
    const companyId = UserStore.user.data.company._id;
    setOnSignAllInProgress(true);

    try {
      const {transactionsPayload, hashesToSubmit} = getTestsTxPayloadAndHash(selectedTests, userId, companyId);
      await submitTestsHashAndDataAsync(hashesToSubmit);
      const reference = await referenceService.create({
        creator: userId,
        transactions: transactionsPayload
      });
      SigningStore.openSigner({id: reference._id}, {title: "Tests approvals", createdFor: "the campaign owner"});
    } catch (error) {
      NotificationStore.showError("There is an error in the signing process. Please contact support.");
    } finally {
      setOnSignAllInProgress(false);
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Select Tests To Sign</StepLabel>
          <StepContent>
            <ChooseTests
              selectedTests={selectedTests}
              setSelectedTests={setSelectedTests}
              moveToNextStep={() => setActiveStep(1)}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Sign Selected Tests</StepLabel>
          <StepContent>
            <SelectedTests
              tests={selectedTests}
              goToPreviousStep={() => setActiveStep(0)}
              onSignAll={onSignAll}
              onSignAllInProgress={onSignAllInProgress}
            />
          </StepContent>
        </Step>
      </Stepper>
    </>
  );
}

export default observer(MultiSignFlow);