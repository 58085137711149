import React, {useState} from "react";
import {Grid, Button} from "@mui/material";
import {useFormikContext} from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";

function SubmitForm({saveDraft}) {
  const {values, submitForm, isSubmitting} = useFormikContext();
  const [asDraftInProgress, setAsDraftInProgress] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const {id} = useParams();

  const submitDraft = async () => {
    setAsDraftInProgress(true);

    try {
      await saveDraft(values);
    } catch (e) {
      console.log("the error in submit form: ", e);
    } finally {
      setAsDraftInProgress(false);
    }
  };
  
  const onSubmit = async () => {
    setSubmitInProgress(true);
    try {
      await submitForm();
    } catch (e) {
      console.log("the error in submit form: ", e);
    } finally {
      setSubmitInProgress(false);
    }
  };
  
  return (
    <>
      <Grid container gap={3} justifyContent="flex-end">
        <Grid item>
          <Button
            disabled={isSubmitting}
            onClick={submitDraft}
            variant="outlined"
          >
            {
              asDraftInProgress &&
              <CircularProgress
                size={24}
                sx={{
                  color: "rgba(0, 0, 0, 0.26)",
                  mr: 1
                }}
              />
            }
            {id ? "Update draft" : "Save as draft"}
          </Button>
        </Grid>
        
        <Grid item>
          <Button
            onClick={onSubmit}
            disabled={isSubmitting}
            variant="contained"
          >
            {
              submitInProgress &&
              <CircularProgress
                size={24}
                sx={{
                  color: "rgba(0, 0, 0, 0.26)",
                  mr: 1
                }}
              />
            }
            {id ? "Start campaign" : "Create campaign"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default SubmitForm;