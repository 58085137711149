export default (theme) => ({
  header: {
    padding: "2px 8px 2px 20px",
    borderTopRightRadius: 4,
    borderBottom: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    minHeight: 50,
  },
  typeIndicator: {
    display: "flex",
    padding: "2px 0",
    alignItems: "center",
    "& h1": {
      whiteSpace: "nowrap",
      fontSize: 26,
    }
  },
  tagsContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    "& > div": {
      padding: theme.spacing(0.5),
      paddingRight: 0,
    },
    "&>div:last-child": {
      "& div": {
        margin: 0,
      }
    }
  },
  properties: {
    alignItems: "center",
    display: "flex",
  },
  removeButton: {
    color: theme.palette.error.main
  },
});
