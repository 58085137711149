import React from "react";
import {TableCell, Tooltip} from "@mui/material";
import {is} from "ramda";
import {CUSTOM_EXCEPTIONS} from "../../exceptions";
import {MEASUREMENTS_CONFIG} from "../../data";
import {getAcceptanceCriteriaValues} from "../../services";

const AcceptanceCell = ({toleranceUnits, standardUnits, reqsHeader, min, max}) => {
  if (reqsHeader && ((!min && !max) || (toleranceUnits !== standardUnits))) {
    return (
      <TableCell align="center" padding="none">
        {reqsHeader}
      </TableCell>
    );
  }

  const requirements = `${Number(min).toFixed(2)} - ${Number(max).toFixed(2)}`;

  if(reqsHeader) {
    return (
      <TableCell align="center" padding="none">
        <Tooltip title={reqsHeader}>
          <span>{requirements}</span>
        </Tooltip>
      </TableCell>
    );
  }

  return (
    <TableCell align="center" padding="none">
      {requirements}
    </TableCell>
  );
};

const TableAcceptanceRow = ({acceptance, measurements, units, isLocationVisible, poItem, acceptanceCriteria = {}}) => {
  return measurements.map((measurement) => {
    const acceptanceConfig = acceptanceCriteria[measurement] || CUSTOM_EXCEPTIONS[acceptance] || {};
    const standardUnits = is(Object, units) ? units[measurement] : MEASUREMENTS_CONFIG[measurement].units[units];
    const customAcceptanceConfig = CUSTOM_EXCEPTIONS[acceptance].data[measurement];

    if (!MEASUREMENTS_CONFIG[measurement].locations) {
      if(!acceptanceCriteria[measurement]) {
        return (
          <TableCell align="center" padding="none">-</TableCell>
        );
      }

      const exceptionConfig = acceptanceCriteria[measurement];
      const {toleranceUnits} = exceptionConfig || {};

      const reqsHeader = customAcceptanceConfig?.reqsHeader;
      const {min, max} = getAcceptanceCriteriaValues({
        standardUnits,
        ...customAcceptanceConfig,
        ...acceptanceConfig,
        ...exceptionConfig,
        poItem,
        acceptanceCriteria,
      });

      return (
        <AcceptanceCell
          reqsHeader={reqsHeader}
          min={min || 0}
          max={max || 0}
          toleranceUnits={toleranceUnits}
          standardUnits={standardUnits}
        />
      );
    }

    return MEASUREMENTS_CONFIG[measurement].locations.map((location) => {
      const name = location === "body" ? "body" : "ends";
      const locationAcceptanceConfig = acceptanceConfig[name] || {};
      const exceptionConfig = acceptanceCriteria[measurement] || {};

      const isVisible = isLocationVisible(location, measurement);

      if(!isVisible) return null;

      if(!acceptanceCriteria[measurement]) {
        return (
          <TableCell align="center" padding="none">-</TableCell>
        );
      }

      if(!exceptionConfig[name]) {
        return (
          <TableCell align="center" padding="none">-</TableCell>
        );
      }

      const {toleranceUnits} = exceptionConfig[name];

      const reqsHeader = customAcceptanceConfig && customAcceptanceConfig[name]?.reqsHeader;

      const {min = 0, max = 0} = getAcceptanceCriteriaValues({
        ...customAcceptanceConfig[location],
        location,
        standardUnits,
        ...locationAcceptanceConfig,
        ...exceptionConfig,
        poItem,
        acceptanceCriteria,
      });

      return (
        <AcceptanceCell
          reqsHeader={reqsHeader}
          min={min || 0}
          max={max || 0}
          toleranceUnits={toleranceUnits}
          standardUnits={standardUnits}
        />
      );
    });
  });
};

TableAcceptanceRow.defaultProps = {
  isLocationVisible: () => true,
};

export default TableAcceptanceRow;