import {Button, Grid} from "@mui/material";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {MultipleSelect} from "@core/components/Form";
import {SPECIFICATION} from "../../../constants";
import {FieldArray, useFormikContext} from "formik";
import React from "react";
import FormGroupListItemLayout from "../../FormGroupListItemLayout";

function MaterialSpecifications({usedNorms, norms}) {
  const {setFieldValue, values, isSubmitting, errors: allErrors, touched: allTouched} = useFormikContext();

  const errors = allErrors.properties?.product?.materialSpecifications || [];
  const touched = allTouched.properties?.product?.materialSpecifications || [];

  return (
    <>
      <FieldArray name="properties.product.materialSpecifications">
        {({remove, push}) => values.properties.product.materialSpecifications.map((specification, index) => (
          <>
            <FormGroupListItemLayout index={index}>
              <Grid item flex={1}>
                <NormAutocomplete
                  disabled={values.properties.product.qcp || isSubmitting}
                  label="Material specification"
                  name={`properties.product.materialSpecifications.${index}.norm`}
                  testType="certificate"
                  onChange={({norm}) => setFieldValue(`properties.product.materialSpecifications.${index}.norm`, norm)}
                  filterOptions={(options) => options.filter((option) => !usedNorms.includes(option.value.norm) || option.value.norm === specification.norm)}
                />
              </Grid>
              <Grid item flex={1}>
                <MultipleSelect
                  label="Grade / UNS"
                  required
                  disabled={!specification.norm || values.properties.product.qcp || isSubmitting}
                  value={specification.grades}
                  elements={norms
                    .filter((norm) => norm.Norm === specification.norm)
                    .map((value) => value.Material)
                  }
                  onChange={(value) => setFieldValue(`properties.product.materialSpecifications.${index}.grades`, value)}
                  error={!!errors[index]?.grades && touched[index]?.grades}
                  errorMessage={errors[index]?.grades}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={values.properties.product.materialSpecifications.length === 1 || values.properties.product.qcp || isSubmitting}
                  onClick={() => remove(index)}
                  variant="contained"
                  color="secondary"
                >
                  Remove
                </Button>
              </Grid>
            </FormGroupListItemLayout>
            {values.properties.product.materialSpecifications.length - 1 === index && (
              <Grid item xs={12} marginTop={3}>
                <Button
                  disabled={values.properties.product.qcp || isSubmitting}
                  onClick={() => push(SPECIFICATION)}
                  variant="contained"
                >
                  Add material specification
                </Button>
              </Grid>
            )}
          </>
        ))}
      </FieldArray>
    </>
  );
}

export default MaterialSpecifications;