import Footer from "@core/components/TestForms/ErosionTest/components/Footer";
import {TEST_RESULTS} from "@core/constants/testResults";
import {getIsWithinRequirements} from "@core/helpers";
import React from "react";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import ErodingParticle from "@core/components/TestForms/ErosionTest/components/ErodingParticle";
import Specimen from "@core/components/TestForms/ErosionTest/components/Specimen";
import TestConditions from "@core/components/TestForms/ErosionTest/components/TestConditions";
import {Grid, MenuItem, Typography} from "@mui/material";
import {omit, values as getValues} from "ramda";
import * as yup from "yup";
import ClientField from "../../../../Tests/Test/components/ClientField";
import Dimensions from "./components/Dimensions";
import ModiefiedTestResults from "./components/ModifiedTestResults";
import AsPerStandardTestResults from "./components/AsPerStandardTestResults";
import {
  TEST_CONDITIONS,
  TEST_STANDARDS,
  DEFAULT_TEST_RESULTS,
  DEFAULT_VALUES_BY_TEST_CONDITION
} from "./constants";
import {Formik} from "formik";
import MuiSelect from "@core/components/MuiSelect";

const TEST_RESULTS_COMPONENT = {
  [TEST_CONDITIONS.AS_PER_STANDARD]: AsPerStandardTestResults,
  [TEST_CONDITIONS.MODIFIED]: ModiefiedTestResults,  
};

const ErosionTest = ({test, closeNewTest, saveTest, formRef, client: owner, user, isProducerTest, editable = true}) => {
  const {witnesses, inspectorJobNumber, inspectionDate, properties} = test ?? {};

  const {client,
    laboratory,
    testStandard,
    testResults,
    pressure,
    impingementAngle,
    testConditions,
    acceptanceCriteria,
    thickness,
    weight,
    length,
    surface,
    heatTreatment,
    processingTreatment,
    preparation,
    cleaning,
    initialSurfaceRoughness,
    hardnessMeasurementUnit,
    sizeDistribution,
    shape,
    composition,
    purity,
    source,
    manufacturingMethod,
    averageParticleVelocity,
    steadyStateErosionRate,
    methodOfVelocityDetermination,
    particleFlow,
    particleFlux,
    temperatureOfSpecimen,
    temperatureOfParticles,
    temperatureOfCarrierGas,
    testDuration,
    methodOfDeterminingSteadyStateErosionCondition,
    carrierGasComposition,
    measurementMethod,
    methodOfDeterminingTheMassOfAbrasiveUsed,
    tags,
    testExecutionDate
  } = properties ?? {};

  const validationSchema = yup.object().shape({
    client: isProducerTest ? yup.string() : yup.string().required("Client is required!"),
    laboratory: isProducerTest ? yup.string() : yup.string().required("Laboratory is required!"),
    testStandard: yup.string().required("Test standard is required!"),
    testConditions: yup.string().required("Test conditions is required!"),
    thickness: yup.number().min(0, "Thickness should be > 0").required("Thickness is required!"),
    weight: yup.number().min(0, "Weight should be > 0").required("Weight is required!"),
    length: yup.number().min(0, "Length should be > 0").required("Length is required!"),
    surface: yup.number().min(0, "Surface should be > 0").required("Surface is required!"),
    heatTreatment: yup.string(),
    processingTreatment: yup.string(),
    preparation: yup.string().required("Preparation is required!"),
    cleaning: yup.string().required("Cleaning is required!"),
    initialSurfaceRoughness: yup.number().min(0, "Initial surface roughness should be > 0").required("Initial surface roughness is required!"),
    acceptanceCriteria: yup.number().min(0, "Acceptance criteria should be > 0"),
    hardnessMeasurementUnit: yup.string(),
    sizeDistribution: yup.string(),
    shape: yup.string(),
    composition: yup.string(),
    purity: yup.string(),
    source: yup.string(),
    manufacturingMethod: yup.string(),
    averageParticleVelocity: yup.number().min(0, "Average particle velocity should be > 0"),
    methodOfVelocityDetermination: yup.string(),
    particleFlow: yup.string().when("testConditions", {
      is: TEST_CONDITIONS.AS_PER_STANDARD,
      then:(schema) => schema.required("Particle flow rate is required!"),
    }),
    particleFlux: yup.string(),
    impingementAngle: yup.number().min(0, "Impingement angle should be > 0").required("Impingement angle is required!"),
    temperatureOfSpecimen: yup.number().min(0, "Temperature of specimen should be > 0"),
    temperatureOfParticles: yup.number().min(0, "Temperature of particles should be > 0"),
    temperatureOfCarrierGas: yup.number().min(0, "Temperature of carrier gas should be > 0"),
    testDuration: yup.number().min(0, "Test duration should be > 0").required("Test duration is required!"),
    methodOfDeterminingSteadyStateErosionCondition: yup.string(),
    carrierGasComposition: yup.string(),
    pressure: yup.number().min(0, "Pressure should be > 0"),
    measurementMethod: yup.string(),
    methodOfDeterminingTheMassOfAbrasiveUsed: yup.string(),
    steadyStateErosionRate: yup.number().min(0, "Steady state erosion rate should be > 0").when("testConditions", {
      is: TEST_CONDITIONS.AS_PER_STANDARD,
      then:(schema) => schema.required("Steady state erosion rate is required!"),
    }),
    testResults: yup.array().of(yup.object().shape({
      specimen: yup.string().required("Specimen ID is required!"),
      hardness: yup.number().min(0, "Hardness should be > 0").test(
        "hardness",
        "Hardness is required",
        function (value) {
          const [, parent] = this.from;

          return value || !parent.value.hardnessMeasurementUnit;
        }),
      density: yup.number().min(0, "Density should be > 0").required("Density is required!"),
      massLoss: yup.number().min(0, "Mass loss should be > 0").required("Mass loss is required!"),

      elapsedTime: yup.number().min(0, "Elapsed time should be > 0").test(
        "elapsedTime",
        "Elapsed time is required",
        function (value) {
          const [, parent] = this.from;

          return value || parent.value.testConditions !== TEST_CONDITIONS.AS_PER_STANDARD;
        }),
      averageErosion: yup.number().min(0, "Average erosion should be > 0").test(
        "averageErosion",
        "Average erosion is required",
        function (value) {
          const [, parent] = this.from;

          return value || parent.value.testConditions !== TEST_CONDITIONS.AS_PER_STANDARD;
        }),

      volumeLoss: yup.number().min(0, "Volume loss should be > 0").test(
        "volumeLoss",
        "Volume loss is required",
        function (value) {
          const [, parent] = this.from;

          return value || parent.value.testConditions !== TEST_CONDITIONS.MODIFIED;
        }),

      erodingParticleMassLoss: yup.number().positive().moreThan(0, "Eroding particle mass loss should be > 0"),

      erosionValue: yup.number().min(0, "Erosion value should be > 0").test(
        "erosionValue",
        "Erosion value is required",
        function (value) {
          const [, parent] = this.from;

          return value || parent.value.testConditions !== TEST_CONDITIONS.MODIFIED;
        }),
    })).required("Test results is required!"),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const initialValues = {
    client: !isProducerTest ? client ?? owner?.name ?? "" : "",
    laboratory: !isProducerTest ? laboratory ?? user?.company.name ?? "" : "",
    testStandard: testStandard ?? TEST_STANDARDS[0],
    testConditions: testConditions ?? TEST_CONDITIONS.AS_PER_STANDARD,
    acceptanceCriteria: acceptanceCriteria ?? undefined,
    thickness: thickness ?? undefined,
    weight: weight ?? undefined,
    length: length ?? undefined,
    surface: surface ?? undefined,
    heatTreatment: heatTreatment ?? "",
    processingTreatment: processingTreatment ?? "",
    preparation: preparation ?? "",
    cleaning: cleaning ?? "",
    initialSurfaceRoughness: initialSurfaceRoughness ?? undefined,
    hardnessMeasurementUnit: hardnessMeasurementUnit ?? "",
    sizeDistribution: sizeDistribution ?? "",
    steadyStateErosionRate: steadyStateErosionRate ?? undefined,
    shape: shape ?? "",
    composition: composition ?? "",
    purity: purity ?? "",
    source: source ?? "",
    manufacturingMethod: manufacturingMethod ?? "",
    averageParticleVelocity: averageParticleVelocity ?? undefined,
    methodOfVelocityDetermination: methodOfVelocityDetermination ?? "",
    particleFlow: particleFlow ?? "",
    particleFlux: particleFlux ?? "",
    impingementAngle: impingementAngle ?? DEFAULT_VALUES_BY_TEST_CONDITION[TEST_CONDITIONS.AS_PER_STANDARD].impingementAngle,
    temperatureOfSpecimen: temperatureOfSpecimen ?? undefined,
    temperatureOfParticles: temperatureOfParticles ?? undefined,
    temperatureOfCarrierGas: temperatureOfCarrierGas ?? undefined,
    testDuration: testDuration ?? undefined,
    methodOfDeterminingSteadyStateErosionCondition: methodOfDeterminingSteadyStateErosionCondition ?? "",
    carrierGasComposition: carrierGasComposition ?? "",
    pressure: pressure ?? undefined,
    measurementMethod: measurementMethod ?? "",
    methodOfDeterminingTheMassOfAbrasiveUsed: methodOfDeterminingTheMassOfAbrasiveUsed ?? "",
    testResults: testResults ?? DEFAULT_TEST_RESULTS,
    witnesses: witnesses.map((witness) => witness.company) ?? [],
    inspectionDate: inspectionDate ?? "",
    inspectorJobNumber: inspectorJobNumber ?? "",
    tags: tags ?? [],
    testExecutionDate: testExecutionDate ?? ""
  };

  const getResult = ({acceptanceCriteria, testResults, testConditions}) => {
    const isAccepted = testResults.every((testResult) => {
      const value = testConditions === TEST_CONDITIONS.AS_PER_STANDARD ? testResult.averageErosion : testResult.erosionValue;

      return getIsWithinRequirements(value, null, acceptanceCriteria);
    });

    return isAccepted ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
  };

  const onSubmit = (values, actions) => {
    values.result = getResult(values);

    const formData = omit(["witnesses"], values);

    saveTest(formData, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    closeNewTest && closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({values, setValues}) => {
        const TestResults = TEST_RESULTS_COMPONENT[values.testConditions];

        return (
          <>
            <Grid container spacing={3} sx={{maxWidth: "1200px"}}>
              {isProducerTest && (
                <Grid item xs={12}>
                  <h1>Erosion test</h1>
                </Grid>
              )}
              {editable && (
                <Grid item container spacing={3}>
                  {!isProducerTest && (
                    <>
                      <Grid item xs={3}>
                        <ClientField
                          isFromProducer={!!owner.name}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          name="laboratory"
                          label="Laboratory"
                          required
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={3}>
                    <MuiSelect
                      required
                      name="testStandard"
                      defaultOptions={TEST_STANDARDS}
                      label="Test standard"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      name="testConditions"
                      label='Test conditions'
                      required
                      onChange={(value) => setValues(() => ({
                        ...values,
                        testConditions: value,
                        ...DEFAULT_VALUES_BY_TEST_CONDITION[value],
                      }))}
                    >
                      {getValues(TEST_CONDITIONS).map((value) => (
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" marginBottom={1}>Test Parameters</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Specimen
                          editable={editable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TestConditions
                          editable={editable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Dimensions
                          editable={editable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ErodingParticle
                          editable={editable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {values.testConditions && (
                <Grid item xs={12}>
                  <Typography variant="h6" marginBottom={1}>Test Results</Typography>
                  <TestResults
                    editable={editable}
                  />
                </Grid>
              )}
            </Grid>
            <Footer
              editable={editable}
              isProducerTest={isProducerTest}
              saveTest={saveTest}
              result={getResult(values)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ErosionTest;