import React, {useRef, useState} from "react";
import ResizableDrawer from "@core/components/ResizableDrawer";
import DrawerHeader from "@core/components/DrawerHeader";
import {Box, CircularProgress} from "@mui/material";
import MultiSignFlow from "../../../MultiSignFlow";

const ApproveMultipleTestsDrawer = ({close, open}) => {
  const [inView, setInView] = useState(false);
  const container = useRef();

  return (
    <>
      <ResizableDrawer
        open={open}
        close={close}
        keepMounted={false}
        SlideProps={{
          onEntered: () => setInView(true),
          onExited: () => setInView(false),
        }}
      >
        <DrawerHeader
          close={close}
          text={"Sign multiple tests"}
        />
        {inView ? (
          <Box
            ref={container}
            paddingLeft="10px"
          >
            <MultiSignFlow/>
          </Box>
        ) : (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}>
            <CircularProgress/>
          </Box>
        )}
      </ResizableDrawer>
    </>
  );
};

export default ApproveMultipleTestsDrawer;