import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import React, {useCallback, useMemo, useState} from "react";
import moment from "moment";
import {observer} from "mobx-react-lite";
import {Tooltip, ClickAwayListener, Button, IconButton, Table, TableHead, TableBody, TableRow, TableCell, Grid, OutlinedInput} from "@mui/material";
import TableSkeleton from "@core/components/TableSkeleton";
import NewUpload from "./components/NewUpload";
import useStores from "../../../useStores";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import axios from "axios";
import TableFooter from "@core/components/TableFooter";
import {downloadFile, filterElements, getFileData, sort} from "@core/helpers";
import CheckIcon from "@mui/icons-material/Check";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import SortableCell from "@core/components/SortableCell";
import FilterableSortableHeaderCell from "@core/components/FilterableSortableHeaderCell";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const Uploads = ({label, attachments, isLoaded, onUpdateFile, onRemoveFile, onCreateFile}) => {
  const {NotificationStore} = useStores();

  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filter, setFilter] = useState({});
  const [open, setOpen] = useState(false);
  const [uploadLabel, setUploadLabel] = useState("");
  const [editableId, setEditableId] = useState("");
  const [uploadToDeleteId, setUploadToDeleteId] = useState(null);

  const uploads = useMemo(() => {
    const uploads = attachments || [];

    return uploads.map((upload) => {
      const {name, extension} = getFileData(upload.file.name);
      const uploadedBy = upload.createdBy ? `${upload.createdBy.firstname} ${upload.createdBy.lastname}` : upload.owner.name;

      return {
        ...upload,
        name: name + extension,
        extension,
        uploadedBy,
      };
    });
  }, [attachments]);

  const filteredUploads = useMemo(() => filterElements(filter, uploads), [filter, uploads]);
  const sortedUploads = useMemo(() => sort(order, orderBy, filteredUploads), [filteredUploads, order, orderBy, filter]);
  const uploadsPaginated = useMemo(() => sortedUploads.slice(offset, offset + limit), [sortedUploads, offset, limit]);

  const download = useCallback( (e, file) => {
    e.stopPropagation();

    downloadFile(file);
  }, []);

  const makeEditable = useCallback((e, upload) => {
    e.stopPropagation();

    setEditableId(upload._id);
    setUploadLabel(upload.label || "");
  }, []);

  const hideEdit = useCallback(() => {
    setEditableId("");
    setUploadLabel("");
  }, []);

  const removeFile = useCallback(async () => {
    await axios.delete(ROUTES.UPLOAD[ACTIONS.REMOVE](uploadToDeleteId));
    const uploadsIds = attachments.map((upload) => upload._id);
    const newUploads = uploadsIds.filter((uploadId) => uploadId !== uploadToDeleteId);
    await onRemoveFile(newUploads);
    setUploadToDeleteId(null);
  }, [uploadToDeleteId]);

  const updateFile = useCallback(async (e) => {
    e.stopPropagation();

    const response = await axios.patch(ROUTES.UPLOAD[ACTIONS.UPDATE](editableId), {label: uploadLabel});

    const newUploads = attachments.map((upload) => upload._id === editableId ? response.data : upload);
    await onUpdateFile(newUploads);

    NotificationStore.showSuccess("Successfully updated!");

    hideEdit();
  }, [uploadLabel, editableId]);

  const updateFilter = useCallback((filter) => {
    setFilter(filter);
    setOffset(0);
  }, []);

  return (
    <>
      <Grid container spacing={2} alignItems="center" mb={1}>
        <Grid item>
          {label}
        </Grid>
        <Grid item>
          <Button
            size="small"
            color='primary'
            variant="contained"
            onClick={() => setOpen(true)}
          >
            <IconistsIcon icon={ICONISTS_ICONS.plusSmall} />&nbsp;Add new
          </Button>
        </Grid>
      </Grid>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <FilterableSortableHeaderCell
              id="name"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={uploads}
            >
              File Name
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="extension"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={uploads}
            >
              File Extension
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="label"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={uploads}
            >
              Description
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="uploadedBy"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={uploads}
            >
              Uploaded By
            </FilterableSortableHeaderCell>
            <SortableCell
              id="date_created"
              setOrder={setOrder}
              order={order}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            >
              Uploaded At
            </SortableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            uploads.length ? (
              uploadsPaginated.map((upload) => {
                return (
                  <ClickAwayListener
                    onClickAway={hideEdit}
                  >
                    <TableRow key={upload._id}>
                      <TableCell>{upload.name}</TableCell>
                      <TableCell>{upload.extension}</TableCell>
                      <TableCell>
                        {editableId === upload._id ? (
                          <OutlinedInput
                            onClick={(e) => e.stopPropagation()}
                            sx={{width: "100%"}}
                            margin="none"
                            value={uploadLabel}
                            onChange={(e) => setUploadLabel(e.target.value)}
                            placeholder="File description"
                          />
                        ) : upload.label || "-"}
                      </TableCell>
                      <TableCell>{upload.uploadedBy}</TableCell>
                      <TableCell>{moment(upload.date_created).format(DATE_TIME_FORMAT)}</TableCell>
                      <TableCell>
                        {editableId === upload._id ? (
                          <Tooltip title="Save">
                            <IconButton
                              disabled={uploadLabel === upload.label}
                              onClick={updateFile}
                            >
                              <CheckIcon size={20} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={(e) => makeEditable(e, upload)}
                            >
                              <IconistsIcon icon={ICONISTS_ICONS.editList} />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Download">
                          <IconButton
                            onClick={(e) => download(e, upload.file)}
                          >
                            <IconistsIcon icon={ICONISTS_ICONS.fileDownload} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove">
                          <IconButton
                            onClick={() => setUploadToDeleteId(upload._id)}
                          >
                            <IconistsIcon icon={ICONISTS_ICONS.circleX} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </ClickAwayListener>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No data</TableCell>
              </TableRow>
            )
          ) : (
            <TableSkeleton columns={6} />
          )}
        </TableBody>
        <TableFooter
          isLoaded
          items={uploadsPaginated}
          total={uploads.length}
          limit={limit}
          offset={offset}
          onOffsetChange={setOffset}
          onLimitChange={setLimit}
        />
      </Table>
      <NewUpload
        open={open}
        close={() => setOpen(false)}
        onCreateFile={onCreateFile}
        attachments={attachments}
      />
      <Confirmation
        open={!!uploadToDeleteId}
        onCancel={() => setUploadToDeleteId(null)}
        onConfirm={removeFile}
        alertText="Are you sure you want to remove the file?"
      />
    </>
  );
};

export default observer(Uploads);