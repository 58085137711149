import React from "react";
import {observer} from "mobx-react-lite";
import UploadsPage from "@core/components/Uploads";
import {useParams} from "react-router-dom";
import useStores from "../../../../../../useStores";

const Uploads = ({label}) => {
  const {CampaignStore} = useStores();
  
  const campaign = CampaignStore.campaign;

  const {id: campaignId} = useParams();
  const isLoaded = campaignId === campaign._id;
  
  return (
    <UploadsPage
      label={<h3>{label}</h3>}
      attachments={campaign.attachments || []}
      isLoaded={isLoaded}
      onCreateFile={(attachments) => CampaignStore.updateCampaignById(campaign._id, {attachments})}
      onUpdateFile={(attachments) => CampaignStore.updateCampaign({attachments})}
      onRemoveFile={(attachments) => CampaignStore.updateCampaignById(campaign._id, {attachments})}
    />
  );
};

export default observer(Uploads);