import React, {useState} from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {Button, Popover} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const AdvancedSettings = ({classes, children, isFullScreen, plot}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Button
        className={classes.container}
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <SettingsIcon />
        Chart Settings
      </Button>
      <Popover
        container={isFullScreen ? plot.current : document.body}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.popoverContent,
        }}
        open={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        {children}
      </Popover>
    </>
  );
};

export default withStyles(AdvancedSettings, styles);