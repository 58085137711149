import React from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {Button, Grid} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import ProducerTestFooter from "@core/components/ProducerTestFooter";

const validationSchema = yup.object().shape({
  note: yup.string().required("Release note is required!"),
  witnesses: yup.array().of(yup.object()).required("Witnesses are required!"),
  inspectionDate: yup.string(),
  inspectorJobNumber: yup.string(),
  tags: yup.array().of(yup.string()),
  testExecutionDate: yup.string(),
});

const initialValues = {
  note: "",
  witnesses: [],
  inspectionDate: "",
  inspectorJobNumber: "",
  tags: [],
  testExecutionDate: "",
};

const InspectionReleaseNote = ({saveTest, closeNewTest, formRef}) => {
  const onSubmit = (values) => {
    const data = {
      note: values.note,
      inspectionDate: values.inspectionDate,
      inspectorJobNumber: values.inspectorJobNumber,
      tags: values.tags,
      testExecutionDate: values.testExecutionDate,
    };

    saveTest(data, {witnesses: values.witnesses});

    if (closeNewTest) closeNewTest();
  };

  const askEndorsement = async (values) => {
    const data = {note: values.note};
    await saveTest(data, values.witnesses, true);
  };

  return (
    <Grid item container spacing={3}>
      <Grid item xs={8}>
        <h1>Inspection release note</h1>
      </Grid>
      <Formik
        innerRef={formRef}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <Grid item xs={8}>
              <TextField
                required
                multiline
                rows={3}
                label='Release note'
                name='note'
              />
            </Grid>
            <ProducerTestFooter>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={!props.isValid}
                  onClick={() => askEndorsement(props.values)}
                >
                  Sign
                </Button>
              </Grid>
            </ProducerTestFooter>
          </>
        )}
      </Formik>
    </Grid>
  );
};

export default InspectionReleaseNote;
