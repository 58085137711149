import Field from "@core/components/CollapsibleTable/components/Field";
import RemoveButtonCell from "@core/components/DynamicTestResultsTable/RemoveButtonCell";
import TestResultTableCell from "@core/components/TestResultTableCell";
import {getErosionValue, getVolumeLoss} from "@core/components/TestForms/ErosionTest/services";
import React from "react";
import {FieldArray, useFormikContext} from "formik";
import {Table, TableBody, TableCell, TableHead, TableRow, Box} from "@mui/material";
import AddRowButton from "@core/components/DynamicTestResultsTable/AddRowButton";
import {TEST_RESULT} from "../../constants";

const ModiefiedTestResults = ({editable}) => {

  const {values, setFieldValue} = useFormikContext();

  return (
    <FieldArray name="testResults">
      {({push, remove}) => {

        return (
          <>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Specimen ID</TableCell>
                  {values.hardnessMeasurementUnit && <TableCell>Hardness</TableCell>}
                  <TableCell>Density</TableCell>
                  <TableCell>Specimen Mass Loss</TableCell>
                  <TableCell>Specimen Volume Loss</TableCell>
                  <TableCell>Eroding Particle Mass Loss</TableCell>
                  <TableCell>
                    Erosion Value
                    {values.acceptanceCriteria && (
                      <span>&nbsp;&#8804;&nbsp;{values.acceptanceCriteria}&nbsp;mm^3/kg</span>
                    )}
                  </TableCell>
                  {editable && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.testResults.map((item, index) => (
                  <TableRow  key={index}>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.specimen`}
                        errorMessage={null}
                        editable={editable}
                      />
                    </TableCell>
                    {values.hardnessMeasurementUnit && (
                      <TableCell>
                        <Field
                          type="number"
                          name={`testResults.${index}.hardness`}
                          required
                          errorMessage={null}
                          endAdornment={values.hardnessMeasurementUnit}
                          inputProps={{min: 0}}
                          editable={editable}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Field
                        type="number"
                        required
                        name={`testResults.${index}.density`}
                        onChange={(value) => setFieldValue(`testResults.${index}.volumeLoss`, getVolumeLoss(value, item.massLoss))}
                        errorMessage={null}
                        endAdornment="g/cm^3"
                        inputProps={{min: 0}}
                        editable={editable}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        type="number"
                        required
                        name={`testResults.${index}.massLoss`}
                        onChange={(value) => {
                          const volumeLoss = getVolumeLoss(item.density, value);
                          setFieldValue(`testResults.${index}.volumeLoss`, volumeLoss);

                          if(item.erodingParticleMassLoss) {
                            setFieldValue(`testResults.${index}.erosionValue`, getErosionValue(volumeLoss, item.erodingParticleMassLoss));
                          }
                        }}
                        errorMessage={null}
                        endAdornment="g"
                        inputProps={{min: 0}}
                        editable={editable}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        type="number"
                        required
                        disabled
                        name={`testResults.${index}.volumeLoss`}
                        errorMessage={null}
                        endAdornment="mm^3"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        type="number"
                        name={`testResults.${index}.erodingParticleMassLoss`}
                        onChange={(value) => setFieldValue(`testResults.${index}.erosionValue`, getErosionValue(item.volumeLoss, value))}
                        errorMessage={null}
                        endAdornment="g"
                        inputProps={{min: 0}}
                        editable={editable}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{display: "flex", alignItems: "center"}}>
                        <TestResultTableCell
                          value={item.erosionValue}
                          max={values.acceptanceCriteria}
                        >
                          {!item.erodingParticleMassLoss ? (
                            <Field
                              type="number"
                              required
                              name={`testResults.${index}.erosionValue`}
                              errorMessage={null}
                              inputProps={{min: 0}}
                              editable={editable}
                            />
                          ) : null}
                        </TestResultTableCell>
                        <span>&nbsp;mm^3/kg</span>
                      </Box>
                    </TableCell>
                    {editable && (
                      <RemoveButtonCell
                        disabled={values.testResults?.length === 1}
                        onClick={() => remove(index)}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {editable && <AddRowButton push={push} rowCellsValue={TEST_RESULT}/>}
          </>
        );
      }}
    </FieldArray>
  );
};

export default ModiefiedTestResults;