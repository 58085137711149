import React, {useCallback} from "react";
import {TableFooter, TableRow, TablePagination, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import classNames from "classnames";
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";
import {TABLE_PAGE} from "@core/constants/tablePage";

const DEFAULT_ROWS_PER_PAGE = [5, 10, 15, 20, 25, 30, 50, 100];

const Footer = ({
  classes,
  isLoaded,
  items,
  total,
  limit,
  offset,
  selectedItems = [],
  onOffsetChange,
  usesUrlQueryParams = true,
  onLimitChange,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE,
  saveTablePageToQueryParams,
  className
}) => {
  const location = useLocation();
  const history = useHistory();
  const getOffset = (page) => page < 0 ? 0 : page * limit;

  const handlePageChangeBasedByUrlQueryParams = useCallback((_, page) => {
    const offset = getOffset(page);
    onOffsetChange(offset);

    if (!saveTablePageToQueryParams) {
      return;
    }

    const queryParams = qs.parse(location.search.slice(1));
    history.push({
      location: location.pathname,
      search: qs.stringify({...queryParams, [TABLE_PAGE]: page})
    });

  }, [limit, location.pathname, location.search]);

  const handlePageChange = (_, page) => {
    const offset = getOffset(page);
    onOffsetChange(offset);
  };

  const handleRowsPerPageChange = useCallback((event) => {
    const limit = event.target.value;
    onLimitChange(limit);
  }, []);

  if (!isLoaded || !items.length) return null;

  return (
    <TableFooter className={classNames(classes.footer, className)}>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={total}
          rowsPerPage={limit}
          page={Math.ceil(offset / limit)}
          onPageChange={usesUrlQueryParams ? handlePageChangeBasedByUrlQueryParams : handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          classes={{
            spacer: classes.pagination,
            root: classes.pagination
          }}
        />
        {selectedItems.length ? (
          <Typography className={classes.selected}>Selected: {selectedItems.length}</Typography>
        ) : null}
      </TableRow>
    </TableFooter>
  );
};

export default withStyles(Footer, styles);
