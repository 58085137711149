import React, {useMemo} from "react";
import {Box} from "@mui/material";
import {MultipleSelect} from "@core/components/Form";
import {TAGS} from "@core/constants/tags";

const Tags = ({test, updateTests, groupedTests}) => {
  const testIds = useMemo(() => groupedTests.map((test) => test._id), [groupedTests.length]);

  return (
    <Box
      sx={{
        "& div [role=combobox]": {
          padding: 0.5,
        }
      }}
    >
      <MultipleSelect
        value={test.properties?.tags || []}
        elements={TAGS}
        onChange={(value) => updateTests({properties: {tags: value}}, testIds)}
      />
    </Box>
  );
};

export default Tags;