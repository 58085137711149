import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import React from "react";
import {Box, Table, TableBody, TableCell, TableRow, TableHead} from "@mui/material";
import Formula from "../Formula";
import ElementsResults from "../ElementsResults";

const ElementsTable = ({test, updateTest, editable}) => {
  const {elements = [], specimenId, secondSpecimenId} = test.properties;

  const secondValueRow = secondSpecimenId || elements.some((el) => el.secondValue);

  const withMinRequirement = elements.some((el) => el.min);
  const withMaxRequirement = elements.some((el) => el.max);

  return (
    <Box
      sx={{
        overflowX: "auto",
      }}
    >
      <Table
        className="styled-table"
        sx={{
          whiteSpace: "nowrap",
          "& td": {
            minWidth: editable ? "110px" : "auto",
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell rowSpan={3}>
              Spec. ID
            </TableCell>
            {(withMinRequirement || withMaxRequirement) && (
              <TableCell>Element</TableCell>
            )}
            {elements.map((el) => (
              <TableCell>
                {el.formula ? (
                  <Formula
                    element={el}
                  />
                ) : el.bm}
              </TableCell>
            ))}
          </TableRow>
          {withMinRequirement && (
            <TableRow>
              <TableCell>
                <AcceptanceCriteriaLabel min/>
              </TableCell>
              {elements.map((el) => (
                <TableCell>
                  {el.min || "-"}
                </TableCell>
              ))}
            </TableRow>
          )}
          {withMaxRequirement && (
            <TableRow>
              <TableCell>
                <AcceptanceCriteriaLabel max/>
              </TableCell>
              {elements.map((el) => (
                <TableCell>
                  {el.max || "-"}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          <TableRow>
            <ElementsResults
              editable={editable}
              specimenId={specimenId}
              elements={elements}
              propertyName="value"
              updateTest={updateTest}
            />
          </TableRow>
          {secondValueRow && (
            <TableRow>
              <ElementsResults
                editable={editable}
                specimenId={secondSpecimenId}
                elements={elements}
                propertyName="secondValue"
                updateTest={updateTest}
              />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ElementsTable;
