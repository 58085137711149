import {useMemo} from "react";
import {STATUSES, TYPES} from "@core/constants/test";

function useAvailableNotAvailableToSignTests({tests}) {
  const {availableToSign, notAvailableToSign} = useMemo(() => {
    return tests.reduce((acc, test) => {
      const isFilledByManufacturerWithNoWitness = test.status === STATUSES.FILLED && !test.witnesses.length && !test.assignee;
      const isDone = [STATUSES.SUBMITTED, STATUSES.INSPECTED].includes(test.status);
      const isSplitTest = test.type === TYPES.SPLIT && test.status !== STATUSES.APPROVED;

      if (isFilledByManufacturerWithNoWitness || isDone || isSplitTest) {
        acc.availableToSign.push(test);
      } else {
        acc.notAvailableToSign.push(test);
      }

      return acc;
    }, {availableToSign: [], notAvailableToSign: []});
  }, [tests]);

  return {availableToSign, notAvailableToSign};
}

export default useAvailableNotAvailableToSignTests;