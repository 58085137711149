import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {withStyles} from "tss-react/mui";
import {Divider, Grid, IconButton, Box, useTheme, Tooltip} from "@mui/material";
import {pdfjs} from "react-pdf";
import {MdOutlineRotateLeft, MdKeyboardArrowRight, MdKeyboardArrowLeft, MdOutlineRotateRight} from "react-icons/md";
import {AiOutlinePlus, AiOutlineMinus} from "react-icons/ai";
import {api} from "Config";
import ResizableDrawer from "@core/components/ResizableDrawer";
import DrawerHeader from "@core/components/DrawerHeader";
import PdfRenderer from "./components/PdfRenderer";
import styles from "./styles";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const DELTA_ROTATE = 90;

const SCALE_STATES = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.90, 1, 1.1, 1.25, 1.50, 1.75, 2, 2.5, 3, 4, 5];

const PdfDrawer = ({classes, open, close, file}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [pdfString, setPdfString] = useState("");

  const pages = useRef({});

  const theme = useTheme();
  
  useEffect(() => {
    if(open) return;

    setPageNumber(1);
    setNumPages(null);
    setPdfString("");
    setRotate(0);
    setScale(1);
  }, [open]);

  const fetchFile = useCallback(async () => {
    const res = await axios.get(`${api}/uploads/download${file}`);
    setPdfString(res.data);
  }, [file]);

  useEffect(() => {
    if(!open) return;

    fetchFile();
  }, [open]);

  const onDocumentLoadSuccess = useCallback(({numPages}) => {
    setNumPages(numPages);
  }, []);

  const changePage = useCallback((offset) => {
    setPageNumber(pageNumber + offset);
  }, [pageNumber]);

  const onRotate = useCallback((delta) => {
    setRotate(rotate + delta);
  }, [rotate]);

  const onScale = useCallback((delta) => {
    const index = SCALE_STATES.findIndex((sc) => sc === scale);
    
    setScale(SCALE_STATES[index + delta]);
  }, [scale]);

  const fileName = useMemo(() => file.split(/_(.*)/s)[1], [file]);

  const intersectionCallback = (entries) => {
    const intersectingEntry = entries.find((entry) => entry.isIntersecting);

    if(!intersectingEntry) return;

    setPageNumber(intersectingEntry.target.page);
  };

  const instersectionObserver = useRef(new IntersectionObserver(intersectionCallback, {threshold: 0.8}));

  useEffect(() => {
    if(!pages.current || !pages.current[pageNumber]) return;

    pages.current[pageNumber].scrollIntoView({
      behavior: "instant",
      block: "start"
    });
  }, [pageNumber]);

  return (
    <ResizableDrawer
      open={open}
      close={close}
      keepMounted={false}
    >
      <DrawerHeader
        close={close}
        text={fileName}
      >
        <Grid item xs container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Tooltip title="Rotate counterclockwise">
              <IconButton
                onClick={() => onRotate(-DELTA_ROTATE)}
                color="primary"
                size="small"
              >
                <MdOutlineRotateRight size={16} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rotate clockwise">
              <IconButton
                onClick={() => onRotate(DELTA_ROTATE)}
                color="primary"
                size="small"
              >
                <MdOutlineRotateLeft size={16}/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Tooltip title="Zoom out">
                  <IconButton
                    disabled={scale === SCALE_STATES[0]}
                    onClick={() => onScale(-1)}
                    color="primary"
                    size="small"
                  >
                    <AiOutlineMinus size={16} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                {Math.floor(scale * 100)}%
              </Grid>
              <Grid item>
                <Tooltip title="Zoom in">
                  <IconButton
                    disabled={scale === SCALE_STATES[SCALE_STATES.length - 1]}
                    onClick={() => onScale(1)}
                    color="primary"
                    size="small"
                  >
                    <AiOutlinePlus size={16} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Tooltip title="Previous page">
                  <IconButton
                    disabled={pageNumber <= 1}
                    onClick={() => changePage(-1)}
                    color="primary"
                    size="small"
                  >
                    <MdKeyboardArrowLeft size={18} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    margin: `0 ${theme.spacing()}`
                  }}
                >
              Page {pageNumber} out of {numPages}
                </Box>
              </Grid>
              <Grid item>
                <Tooltip title="Next page">
                  <IconButton
                    disabled={pageNumber >= numPages}
                    onClick={() => changePage(1)}
                    color="primary"
                    size="small"
                  >
                    <MdKeyboardArrowRight size={18} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DrawerHeader>
      <Box
        sx={{
          overflow: "hidden",
          marginBottom: theme.spacing(),
          display: "flex",
          justifyContent: "center",
          "& .react-pdf__Document": {
            width: "100%",
            overflowX: "scroll",
            textAlign: "center",
          },
          "& .react-pdf__Page": {
            display: "inline-block",
            margin: "0 30%",
          },
        }}
      >
        <PdfRenderer
          pdfString={pdfString}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          numPages={numPages}
          pages={pages}
          instersectionObserver={instersectionObserver}
          rotate={rotate}
          scale={scale}
          className={classes.outline}
        />
      </Box>
    </ResizableDrawer>
  );
};

export default withStyles(PdfDrawer, styles);