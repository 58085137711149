export const PAGE_TABS = {
  GENERAL_INFO: "GENERAL_INFO",
  BASE_MATERIALS: "BASE_MATERIALS",
  TEST_REPORTS: "TEST_REPORTS",
};

export const PAGE_TABS_LABELS = {
  [PAGE_TABS.GENERAL_INFO]: "General Info",
  [PAGE_TABS.BASE_MATERIALS]: "Base Materials",
  [PAGE_TABS.TEST_REPORTS]: "Coating Test Reports",
};

export const SUB_TABS = {
  BASE_PRODUCTS: "BASE_PRODUCTS",
  RAW_MATERIALS: "RAW_MATERIALS",
  CAMPAIGN_PROGRESS: "CAMPAIGN_PROGRESS",
};

export const SUB_TABS_LABELS = {
  [SUB_TABS.BASE_PRODUCTS]: "Base Products",
  [SUB_TABS.RAW_MATERIALS]: "Coating Raw Materials",
  [SUB_TABS.CAMPAIGN_PROGRESS]: "Campaign Progress",
};