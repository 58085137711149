import {ROUTES} from "@core/api/routes";
import {Select} from "@core/components/Form";
import {ACTIONS} from "@core/constants/api";
import {Grid, MenuItem} from "@mui/material";
import axios from "axios";
import {keys, equals, uniqBy, prop} from "ramda";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import ImportWrapper from "@core/components/ImportWrapper";
import {SOURCES} from "@core/constants/importTest";
import {useParams} from "react-router-dom";
import useStores from "../../../../../../../../useStores";

const ImportData = ({touched, ...props}) => {
  const initialFormData = {source: SOURCES.SPTS, qcpId: "", qcpName: "", file: ""};

  const [formData, setFormData] = useState(initialFormData);

  const {SingleMaterialCampaignStore, QcpStore} = useStores();

  const {id} = useParams();
  const {qcp = {}} = SingleMaterialCampaignStore.campaign.properties.product;

  const qcps = QcpStore.qcps.data;

  const uniqQcps = uniqBy(prop("name"), qcps);
  const qcpVersions = qcps.filter((qcp) => qcp.name === formData.qcpName);

  useEffect(() => {
    QcpStore.qcps.load({limit: 100, offset: 0});
  }, []);

  useEffect(() => {
    const [qcp = {}] = qcpVersions;
    setFormData({...formData, qcpId: qcp._id || ""});
  }, [formData.qcpName]);

  const parseFile = async (data) => {
    data.append("source", formData.source);
    data.append("qcpId", qcp._id || formData.qcpId);

    const {data: tests} = await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.PARSE](id), data);

    return {data: {tests}};
  };

  touched.current = !equals(formData, initialFormData);

  return (
    <ImportWrapper
      parseFile={parseFile}
      formData={formData}
      setFormData={setFormData}
      disabled={!formData.source}
      defaultData={{tests: []}}
      {...props}
    >
      <Grid item xs={2}>
        <Select
          label="Source"
          required
          value={formData.source}
          onChange={(e) => setFormData({...formData, source: e.target.value})}
        >
          {keys(SOURCES).map((source) => (
            <MenuItem key={source} value={SOURCES[source]}>{source}</MenuItem>
          ))}
        </Select>
      </Grid>
      {!qcp._id && (
        <>
          <Grid item xs={2}>
            <Select
              label="QCP/ITP"
              required
              value={formData.qcpName}
              onChange={(e) => setFormData({...formData, qcpName: e.target.value})}
            >
              {uniqQcps.map((qcp) => (
                <MenuItem key={qcp.name} value={qcp.name}>{qcp.name}</MenuItem>
              ))}
            </Select>
          </Grid>
          {!!qcpVersions.length && (
            <Grid item xs={2}>
              <Select
                label="QCP/ITP Item"
                required
                value={formData.qcpId}
                onChange={(e) => setFormData({...formData, qcpId: e.target.value})}
              >
                {qcpVersions.map((qcp) => (
                  <MenuItem key={qcp._id} value={qcp._id}>{qcp.qcpItem}</MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </>
      )}
    </ImportWrapper>
  );
};

export default observer(ImportData);