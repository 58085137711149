import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Grid from "@mui/material/Grid";
import LinkedAccount from "./LinkedAccount";
import React, {useCallback, useState} from "react";
import {GoogleLogoIcon, MicrosoftLogoIcon} from "@core/components/icons";
import useStores from "../../../../useStores";
import {observer} from "mobx-react-lite";

const styles = (theme) => ({
  linkedAccountsContainer: {
    padding: `${theme.spacing(1.5)} 0`,
  },
  socialIcon: {
    width: theme.spacing(1.75),
    lineHeight: theme.spacing(1.75),
    fill: theme.palette.common.white,
    marginLeft: theme.spacing(1)
  },
  actionButton:{
    minWidth: "64px"
  }
});

const LinkedAccounts = ({classes}) => {
  const {UserStore, NotificationStore} = useStores();
  const [unlinkConfirmOpen, setUnlinkConfirmOpen] = useState(false);
  const [providerData, setProviderData] = useState({});
  const [processing, setProcessing] = useState(false);

  const linkAccount = useCallback((provider) => {
    UserStore.linkAccount(provider);
  }, [UserStore]);

  const unlinkAccount = useCallback(() => {
    setProcessing(true);
    UserStore.unlinkAccount(providerData.provider)
      .then(() => {
        NotificationStore.showSuccess("Account unlinked successfully");
        setUnlinkConfirmOpen(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [UserStore, providerData]);

  const confirmUnlinkAccount = useCallback((provider, title) => {
    setProviderData({provider, title});
    setUnlinkConfirmOpen(true);
  }, []);

  const user = UserStore.user.data;

  return (
    <>
      <Grid item xs={12}>
        <FormLabel component="legend">Linked Accounts</FormLabel>
        <Grid container spacing={2} direction="column"
          classes={{container: classes.linkedAccountsContainer}}>
          <Grid item>
            <LinkedAccount title="Microsoft" id={user?.linkedAccounts?.ms}
              provider="ms"
              onLink={linkAccount}
              onUnlink={confirmUnlinkAccount}
              icon={<MicrosoftLogoIcon className={classes.socialIcon}/>}
            />
          </Grid>
          <Grid item>
            <LinkedAccount title="Google"
              provider="google"
              onLink={linkAccount}
              onUnlink={confirmUnlinkAccount}
              id={user?.linkedAccounts?.google}
              icon={<GoogleLogoIcon className={classes.socialIcon}/>}
            />
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={unlinkConfirmOpen} fullWidth maxWidth="sm">
        <DialogTitle>Unlink account</DialogTitle>
        <DialogContent>{`Are you sure you want to unlink ${providerData.title} account?`}</DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setUnlinkConfirmOpen(false)} className={classes.actionButton}>Cancel</Button>
          <Button color="primary" onClick={unlinkAccount} className={classes.actionButton}>
            {processing ? <CircularProgress size={24.5} /> : "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(observer(LinkedAccounts), styles);
