import Table from "@core/components/Table";
import {observer} from "mobx-react-lite";
import TableFooter from "@core/components/TableFooter";
import {Grid, Button, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import {useParams} from "react-router-dom";
import useStores from "../../../../useStores";
import {columns} from "./columns";
import {TABS, TABS_TITLES} from "../../constants";
import SelectBaseMaterialsForm from "../../../components/SelectBaseMaterialsForm";

const BaseMaterialsPage = () => {
  const [baseMaterialFormOpen, setBaseMaterialFormOpen] = useState(false);

  const {SingleMaterialCampaignStore} = useStores();

  const {id} = useParams();

  const {status, data: products, total, fetch} = SingleMaterialCampaignStore.singleProducts;
  const {singleProducts} = SingleMaterialCampaignStore.campaign;

  useEffect(() => {
    return () => SingleMaterialCampaignStore.singleProducts.reset();
  }, []);

  useEffect(() => {
    SingleMaterialCampaignStore.singleProducts.load({singleCampaign: id});
  }, [singleProducts.length]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6">
                {TABS_TITLES[TABS.BASE_MATERIALS]}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setBaseMaterialFormOpen(true)}
              >
                <IconistsIcon icon={ICONISTS_ICONS.plusSmall} />&nbsp;Modify
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table
            items={products}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => SingleMaterialCampaignStore.singleProducts.load({sort})}
            total={total}
            noDataText="No available products."
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={products}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => SingleMaterialCampaignStore.singleProducts.load({offset})}
              onLimitChange={(limit) => SingleMaterialCampaignStore.singleProducts.load({limit, offset: 0})}
            />
          </Table>
        </Grid>
      </Grid>
      <SelectBaseMaterialsForm
        selectedIds={singleProducts}
        baseMaterialFormOpen={baseMaterialFormOpen}
        setBaseMaterialFormOpen={setBaseMaterialFormOpen}
        setSelectedIds={(ids) => SingleMaterialCampaignStore.updateSingleProducts(id, ids)}
        campaignId={id}
      />
    </>
  );
};

export default observer(BaseMaterialsPage);