import TestFormResult from "@core/components/TestFormResult";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import modules from "@core/constants/modules";
import {TAGS} from "@core/constants/tags";
import {useFormikContext} from "formik";
import {observer} from "mobx-react";
import {any, isEmpty, propEq} from "ramda";
import React from "react";
import {Grid, Button} from "@mui/material";
import {Date, Input, MultipleSelect} from "@core/components/Form";
import useStores from "../../../useStores";

const TestFooter = ({children, result}) => {
  const {CompanyStore} = useStores();

  const {setFieldValue, values, isValid, handleSubmit} = useFormikContext();

  const witnessCompanies = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.WITNESS, "name"), company.modules);
  });

  return (
    <Grid container item spacing={3} alignItems="flex-end">
      <Grid item xs={4}>
        <MultipleSelect
          label="Witnesses"
          value={values.witnesses.map((c) => c.name)}
          elements={witnessCompanies.map((c) => c.name)}
          onChange={(values) => {
            const witnesses = witnessCompanies.filter((c) => values.includes(c.name));
            setFieldValue("witnesses", witnesses);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MultipleSelect
          label="Tags"
          value={values.tags}
          elements={TAGS}
          onChange={(values) => setFieldValue("tags", values)}
        />
      </Grid>
      <Grid item xs={4}>
        <Date
          format={DATE_FORMAT}
          type="date"
          label="Test Execution Date"
          value={values.testExecutionDate}
          onChange={(event) => setFieldValue("testExecutionDate", event.target.value)}
        />
      </Grid>
      {!isEmpty(values.witnesses) && (
        <Grid item xs={12}>
          <Grid container item spacing={3} alignItems="flex-end">
            <Grid item xs={4}>
              <Date
                format={DATE_FORMAT}
                type="date"
                label="Inspection Date"
                value={values.inspectionDate}
                onChange={(event) => setFieldValue("inspectionDate", event.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="inspectorJobNumber"
                value={values.inspectorJobNumber}
                label="Inspector Job Number"
                onChange={(event) => setFieldValue("inspectorJobNumber", event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <TestFormResult
            isValid={isValid}
            result={values.result || result}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            {children}
            <Grid item>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
               Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default observer(TestFooter);
