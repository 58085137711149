import {TEST_RESULTS} from "@core/constants/testResults";
import React, {useState} from "react";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Grid,
  MenuItem,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import {Formik, FieldArray} from "formik";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import {FilesUploader} from "@core/components/Uploaders";
import {Input, Select} from "@core/components/Form";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import styles from "./styles";

const getResult = (elements) => {
  const isNotAcceptable = elements.some((element) => element.result === "Unsatisfactory");

  return isNotAcceptable ? TEST_RESULTS.NOT_ACCEPTABLE : TEST_RESULTS.ACCEPTABLE;
};

const ELEMENT = {
  test: "",
  description: "",
  notes: "",
  result: "",
};

const validationSchema = yup.object().shape({
  elements: yup.array().of(yup.object().shape({
    test: yup.string().required("Test is required!"),
    description: yup.string().required("Description is required!"),
    notes: yup.string(),
    result: yup.string().required("Result is required!"),
  })).required(),
  witnesses: yup.array().of(yup.object()),
  inspectionDate: yup.string(),
  inspectorJobNumber: yup.string(),
  files: yup.array().of(yup.string()),
  tags: yup.array().of(yup.string()),
  testExecutionDate: yup.string(),
});

const Element = ({classes, index, element, elements, setFieldValue, change, remove}) => {
  const [notesShown, setNotesShown] = useState(false);

  return (
    <TableRow key={index}>
      <TableCell padding="checkbox" classes={{paddingCheckbox: classes.paddingCheckbox}}>
        <Input
          name={`elements.${index}.test`}
          value={element.test}
          onChange={(e) => change(`elements.${index}.test`, e)}
          onBlur={() => setFieldValue(`elements.${index}.test`, element.test.trim())}
        />
      </TableCell>
      <TableCell padding="checkbox" classes={{paddingCheckbox: classes.paddingCheckbox}}>
        <Input
          name={`elements.${index}.description`}
          value={element.description}
          onChange={(e) => change(`elements.${index}.description`, e)}
          onBlur={() => setFieldValue(`elements.${index}.description`, element.description.trim())}
        />
      </TableCell>
      <TableCell padding="checkbox" width="150" classes={{paddingCheckbox: classes.paddingCheckbox}}>
        <Select
          value={element.result}
          name={`elements.${index}.result`}
          onChange={(e) => change(`elements.${index}.result`, e)}
        >
          <MenuItem value={"Guaranteed"}>Guaranteed</MenuItem>
          <MenuItem value={"Satisfactory"}>Satisfactory</MenuItem>
          <MenuItem value={"Unsatisfactory"}>Unsatisfactory</MenuItem>
        </Select>
      </TableCell>
      <TableCell width={notesShown ? "50%" : "5%"} padding="checkbox" classes={{paddingCheckbox: classes.paddingCheckbox}}>
        {notesShown ? (
          <Input
            name={`elements.${index}.notes`}
            value={element.notes}
            onChange={(e) => change(`elements.${index}.notes`, e)}
            onBlur={() => setFieldValue(`elements.${index}.notes`, element.notes.trim())}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  edge="end"
                  onClick={() => setNotesShown(false)}
                  size="large">
                  <DoneIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        ) : (
          <Tooltip title={element.notes}>
            <IconButton onClick={() => setNotesShown(true)} color="primary" size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell padding="checkbox" classes={{paddingCheckbox: classes.paddingCheckbox}}>
        <Button
          disabled={elements.length === 1}
          variant="contained"
          color="secondary"
          className={classes.btnAdd}
          onClick={() => remove(index)}
        >
          Remove
        </Button>
      </TableCell>
    </TableRow>
  );
};

const OtherTest = ({classes, closeNewTest, isProducerTest, test, saveTest, formRef}) => {
  const initialValues = {
    elements: test?.properties.elements || [ELEMENT],
    result: test?.properties.result || "",
    witnesses: test?.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    files: test?.properties.files || (test?.properties.file ? [test?.properties.file] : []),
    tags: test?.properties.tags || [],
    testExecutionDate: test?.properties.testExecutionDate || ""
  };

  const onSubmit = (values) => {
    const result = getResult(values.elements);
    const data = {
      ...values,
      result,
      elements: values.elements,
      inspectionDate: values.inspectionDate,
      inspectorJobNumber: values.inspectorJobNumber,
    };
    saveTest(data, {witnesses: values.witnesses});

    if (!closeNewTest) return;

    closeNewTest();
  };

  return (
    <div>
      <Formik
        validateOnMount
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={(props) => {
          const {
            values: {elements, files},
            handleChange,
            setFieldTouched,
            setFieldValue,
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          return <>
            <Grid container spacing={5}>
              {isProducerTest && (
                <Grid item xs={12}>
                  <h1>Other tests and Guarantees</h1>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              className={classNames({
                [classes.gridRoot]: !isProducerTest,
              })}
              spacing={5}
            >
              <Grid item xs={!isProducerTest ? 7 : 12}>
                <FieldArray
                  name="elements"
                >
                  {({remove, push}) => (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">Test</TableCell>
                          <TableCell padding="checkbox">Description</TableCell>
                          <TableCell padding="checkbox" width="150">Result</TableCell>
                          <TableCell padding="checkbox">Notes</TableCell>
                          <TableCell padding="checkbox">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      {elements && elements.length > 0 ? (
                        <TableBody>
                          {elements.map((element, index) => (
                            <Element
                              index={index}
                              element={element}
                              classes={classes}
                              elements={elements}
                              setFieldValue={setFieldValue}
                              change={change}
                              remove={remove}
                            />
                          ))}
                        </TableBody>
                      ) : null}
                      {!test && (
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={4} className={classes.btnAddCell}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.btnAdd}
                                onClick={() => push(ELEMENT)}
                              >
                                Add
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      )}
                    </Table>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" spacing={3}>
              <Grid item xs={12}>
                <FilesUploader
                  name="files"
                  files={files}
                  onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                  changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                />
              </Grid>
            </Grid>
            <ProducerTestFooter />
          </>;
        }}
      />
    </div>
  );
};

export default withStyles(OtherTest, styles);
