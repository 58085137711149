import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import CollapsibleTable from "@core/components/CollapsibleTable";
import TestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {Formik} from "formik";
import MuiSelect from "@core/components/MuiSelect";
import * as yup from "yup";
import TestResults from "./TestResults";
import {getDeviation, getMean} from "@core/helpers";
import {TEST_STANDARDS, TEST_METHODS, STRESS_AT_BREAK_DEFAULTS} from "./constants";
import {FIELD_NAMES, DISPLAY_NAME_BY_FIELD} from "@core/constants/transverseRuptureStrength";
import {getAcceptanceResult} from "./helpers";

const TransverseRuptureStrength = ({test, closeNewTest, saveTest, formRef, isProducerTest}) => {
  const {witnesses, inspectorJobNumber, inspectionDate, properties} = test ?? {};
  const {acceptanceCriteria, testStandard, method, stressAtBreak, tags, testExecutionDate} = properties ?? {};

  const initialValues = {
    acceptanceCriteria: acceptanceCriteria ?? undefined,
    testStandard: testStandard ?? TEST_STANDARDS[0],
    method: method ?? TEST_METHODS[0],
    stressAtBreak: stressAtBreak ?? STRESS_AT_BREAK_DEFAULTS,
    witnesses: witnesses.map((witness) => witness.company) ?? [],
    inspectionDate: inspectionDate ?? "",
    inspectorJobNumber: inspectorJobNumber ?? "",
    tags: tags ?? [],
    testExecutionDate: testExecutionDate ?? "",
  };

  const validationSchema = yup.object().shape({
    acceptanceCriteria: yup.number().min(0, "Acceptance criteria Should be > 0").required("Acceptance criteria is required!"),
    testStandard: yup.string().required("Test standard is required!"),
    method: yup.string().required("Method is required!"),
    stressAtBreak: yup.array().of(yup.object().shape({
      specimenId: yup.string().required("Specimen is required"),
      x: yup.number().min(0, "X should be > 0").required("X is required!"),
      y: yup.number().min(0, "Y should be > 0").required("Y is required!"),
      z: yup.number().min(0, "Z should be > 0").required("Z is required!"),
    })).required("At least one specimen is required!"),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });
  
  const onSubmit = (values, actions) => {
    const stressAtBreak = values.stressAtBreak;
    const standardDeviation ={
      x: Math.round(getDeviation(stressAtBreak?.map(({x}) => x))),
      y: Math.round(getDeviation(stressAtBreak?.map(({y}) => y))),
      z: Math.round(getDeviation(stressAtBreak?.map(({z}) => z))),
    };

    const meanValues = {
      x: Math.round(getMean(stressAtBreak?.map(({x}) => x))),
      y: Math.round(getMean(stressAtBreak?.map(({y}) => y))),
      z: Math.round(getMean(stressAtBreak?.map(({z}) => z))),
    };

    const formData = {...values, result: getAcceptanceResult(values), standardDeviation, meanValues};

    saveTest(formData, {witnesses: []});
    actions.setSubmitting(false);

    closeNewTest && closeNewTest();
  };

  return (
    <Box>
      <Typography marginBottom={4} variant="h5">Transverse Rupture Strength</Typography>
      <Formik
        validateOnMount
        innerRef={formRef}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}>
        {({values}) => {

          const result = getAcceptanceResult(values);
          
          return (
            <>
              <Grid container gap={4}>
                <Grid xs={3} item>
                  <MuiSelect
                    required
                    name={FIELD_NAMES.testStandard}
                    defaultOptions={TEST_STANDARDS}
                    label={DISPLAY_NAME_BY_FIELD[FIELD_NAMES.testStandard]}
                  />
                </Grid>
                <Grid xs={4} item>
                  <MuiSelect
                    required
                    name={FIELD_NAMES.method}
                    defaultOptions={TEST_METHODS}
                    label={DISPLAY_NAME_BY_FIELD[FIELD_NAMES.method]}
                  />
                </Grid>
              </Grid>
              <Grid marginTop={4} container>
                <Grid item xs={12}>
                  <Box sx={{maxWidth: "700px"}}>
                    <CollapsibleTable
                      editable
                      title="Specimen"
                      fields={[{
                        name: FIELD_NAMES.acceptanceCriteria,
                        required: true,
                        label: <AcceptanceCriteriaLabel min />,
                        type: "number",
                        endAdornment: "ksi",
                        inputProps: {min: 0},
                      }]}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box marginTop={3}>
                <TestResults />
              </Box>

              {isProducerTest ? (
                <ProducerTestFooter
                  result={result}
                />
              ) : (
                <TestFooter
                  onSubmit={saveTest}
                  result={result}
                />
              )}
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default TransverseRuptureStrength;