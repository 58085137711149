import {getIsWithinRequirements} from "@core/helpers";
import {Box, useTheme} from "@mui/material";
import {isEmpty, isNil} from "ramda";
import React from "react";

const TestResultTableCell = ({value, min, max, children}) => {
  const theme = useTheme();

  const isAcceptable = getIsWithinRequirements(value, min, max);

  const withRequirements = (!isNil(min) && !isEmpty(min)) || (!isNil(max) && !isEmpty(max));

  const testColor = withRequirements ?
    isAcceptable ? theme.palette.success.main : theme.palette.error.main :
    theme.palette.primary.main;

  const valueFilled = !isNil(value) && !isEmpty(value);
  const cellValue = valueFilled ? value : "-";

  return (
    <Box
      sx={valueFilled ? {color: testColor} : {}}
    >
      {children || cellValue}
    </Box>
  );
};

export default TestResultTableCell;