import React from "react";
import {withStyles} from "tss-react/mui";
import CampaignPageSkeleton from "../CampaignPageSkeleton";
import GeneralInfo from "./components/GeneralInfo";
import BaseMaterials from "../BaseMaterials";
import TestReports from "../TestReports";
import {PAGE_TABS, PAGE_TABS_LABELS} from "./constants";
import styles from "./styles";

const COMPONENT_BY_TAB = {
  [PAGE_TABS.GENERAL_INFO]: GeneralInfo,
  [PAGE_TABS.BASE_MATERIALS]: BaseMaterials,
  [PAGE_TABS.TEST_REPORTS]: TestReports,
};

const Coating = (props) => {
  return (
    <CampaignPageSkeleton
      pageTabs={PAGE_TABS}
      pageTabsLabels={PAGE_TABS_LABELS}
      componentByTab={COMPONENT_BY_TAB}
      {...props}
    />
  );
};

export default withStyles(Coating, styles);