import jStat from "jstat";
import {useMemo} from "react";
import {getSignificantNumbers} from "@core/helpers";

const useHistogram = (values, autoBinning, binCountWanted, binWidthWanted) => {
  if(values.length === 0) {
    return {
      labels: [], frequencies: [], pdf: [], mean: undefined, stdev: undefined,
    };
  }

  const nValues = useMemo(() => values ? values.map((v) => +v) : [], [values]);
  const mean = useMemo(() => jStat.mean(nValues), [nValues]);
  const stdev = useMemo(() => jStat.stdev(nValues), [nValues]);

  const bins = useMemo(() => {
    const significantDecimals = Math.max(...nValues.map((value) => getSignificantNumbers(value)));

    const first = jStat.min(nValues);
    const last = jStat.max(nValues);
    let binCnt;
    let binWidth;
    let freq;

    if (first === last) {
      binCnt = 1;
      binWidth = 1;
    } else {
      binCnt = Number(binCountWanted) || calculateBinCount2(nValues) || 1;
      // rounding to the nearest possible number - the binary numeral system issue in JS
      binWidth = Number(binWidthWanted) || Number(((last - first) / binCnt).toFixed(significantDecimals));
    }

    let mids = new Array(binCnt).fill(null)
      .map((_, binIdx) => Number((first + (binIdx * binWidth) + binWidth / 2).toFixed(significantDecimals)));
    const additionalBinsCount = calculateAdditionalBins(binCnt);
    const additionalMidsLeft = new Array(additionalBinsCount / 2).fill(null)
      .map((_, binIdx) => Number((first - (binIdx * binWidth) - binWidth / 2).toFixed(significantDecimals)));
    additionalMidsLeft.reverse();
    const additionalMidsRight = new Array(additionalBinsCount / 2).fill(null)
      .map((_, binIdx) => Number((last + (binIdx * binWidth) + binWidth / 2).toFixed(significantDecimals)));
    mids = [...additionalMidsLeft, ...mids, ...additionalMidsRight];

    freq = first === last ? new Array(binCnt).fill(nValues.length): jStat.histogram(nValues, binCnt);
    freq = [...new Array(additionalBinsCount / 2).fill(0), ...freq, ...new Array(additionalBinsCount / 2).fill(0)];

    return {freq, mids};
  }, [nValues, binCountWanted, binWidthWanted]);

  /**
   * Values for PDF (probability density function)
   */
  const pdf = useMemo(() => {
    return bins.mids.map((x) => jStat.normal.pdf(+x, mean, stdev));
  }, [bins, mean, stdev]);

  return {
    labels: bins.mids, frequencies: bins.freq, pdf, mean, stdev,
  };
};

// eslint-disable-next-line
const calculateBinCount = (values) => {
  let cnt = Math.floor((values.length || 0) / 5);
  cnt = nearestWholeNumber(cnt);

  if (cnt < 6) {
    cnt = 6;
  } else if (cnt > 20) {
    cnt = 20;
  }

  return cnt;
};

const calculateIQR = (values) => {
  const quantiles = jStat.quantiles(values, [0.25, 0.75]);

  // returns 0 in quantiles = [0.2, 0.2]
  return quantiles[1] - quantiles[0];
};

const calculateBinWidth = (values) => {
  return 2 * calculateIQR(values) * Math.pow(values.length, -1/3);
};

// eslint-disable-next-line
const calculateBinCount2 = (values) => {
  // return Infinity if calculateBinWidth return 0
  const result = Math.max(1, Math.ceil((Math.max(...values) - Math.min(...values)) / calculateBinWidth(values, calculateIQR(values))));

  return isFinite(result) ? result : false;
};

const calculateAdditionalBins = (binsCnt) => nearestWholeNumber(Math.ceil(binsCnt / 2));

const nearestWholeNumber = (n) => n % 2 ? n + 1 : n;

export default useHistogram;
