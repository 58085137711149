import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {equals} from "ramda";
import {Button, Grid} from "@mui/material";
import {useFormikContext} from "formik";
import {STATUSES} from "@core/constants/test";
import {TestService} from "@core/services";
import TestFormResult from "@core/components/TestFormResult";
import WitnessAttest from "../../../Tests/Test/components/WitnessAttest";
import PublishToPortal from "../../../Tests/Test/components/PublishToPortal";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import useStores from "../../../useStores";

const TestFooter = ({onSubmit, result}) => {
  const [isPublishToPortalOpen, setIsPublishToPortalOpen] = useState(false);

  const {TestStore, UserStore} = useStores();
  const {values: data, validateForm, setErrors, setTouched, setStatus, isValid, touched, status} = useFormikContext();

  const test = TestStore.test.data;
  const user = UserStore.user.data;

  const isEditable = [STATUSES.ASSIGNED, STATUSES.EMPTY, STATUSES.FILLED].includes(test.status);
  const isFilled = test.status === STATUSES.FILLED;
  const isSubmitted = test.status === STATUSES.SUBMITTED;
  const isInspecting = test.status === STATUSES.INSPECTING;
  const isInspected = test.status === STATUSES.INSPECTED;

  const isWitnessed = test.witnesses.length;
  const isNotAccepted = isWitnessed && test.witnesses.find((witness) => witness.status === WITNESS_STATUSES.NOT_ACCEPTED);

  const saveDisabled = isSubmitted || !isEditable || isInspected;
  const signDisabled = saveDisabled || !isValid;

  useEffect(() => {
    if (!isFilled) return;

    validateForm().then((errors) => {
      setErrors(errors);
      setTouched(errors);
      setStatus(errors);
    });
  }, []);

  const submitTest = async () => {
    const formHasChanges = !equals(status, touched);

    if(formHasChanges) await onSubmit(data);

    const companyId = test.company._id || test.company;
    const isFromProducer = test.assignee._id !== companyId;

    if (isFromProducer) TestService.submit([test], user._id);
    else TestService.create([test], user._id, user.company._id);
  };

  if (isInspecting || (isInspected && isNotAccepted)) {
    return (
      <WitnessAttest
        setIsPublishToPortalOpen={setIsPublishToPortalOpen}
      />
    );
  }

  return (
    <>
      <Grid container spacing={5} alignItems="flex-end" justifyContent="flex-end" sx={{marginTop: 1}}>
        <Grid xs item>
          <TestFormResult
            isValid={isValid}
            result={result}
          />
        </Grid>
        {/* {user.role === roles.ADMIN && ( */}
        {/*   <Grid item> */}
        {/*     <Button */}
        {/*       fullWidth */}
        {/*       disabled={signDisabled} */}
        {/*       variant="contained" */}
        {/*       size="large" */}
        {/*       color="primary" */}
        {/*       onClick={() => setIsPublishToPortalOpen(true)} */}
        {/*     > */}
        {/*       Publish to portal */}
        {/*     </Button> */}
        {/*   </Grid> */}
        {/* )} */}
        <Grid item>
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onSubmit(data)}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={signDisabled}
            onClick={submitTest}
          >
            Sign
          </Button>
        </Grid>
      </Grid>
      <PublishToPortal
        testId={test._id}
        open={isPublishToPortalOpen}
        onClose={() => setIsPublishToPortalOpen(false)}
      />
    </>
  );
};

export default observer(TestFooter);