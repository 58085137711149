import {ROUTES} from "@core/api/routes";
import {Select} from "@core/components/Form";
import {ACTIONS} from "@core/constants/api";
import {Grid, MenuItem} from "@mui/material";
import {observer} from "mobx-react-lite";
import axios from "axios";
import {keys, prop, uniqBy, equals} from "ramda";
import React, {useEffect, useState} from "react";
import ImportWrapper from "@core/components/ImportWrapper";
import useStores from "../../../../useStores";
import {SOURCES} from "@core/constants/importTest";

const ImportData = ({touched, ...props}) => {
  const initialFormData = {source: SOURCES.SPTS, qcp: "", qcpItem: "", file: ""};

  const [formData, setFormData] = useState(initialFormData);

  const {QcpStore} = useStores();

  const qcps = QcpStore.qcps.data;

  const uniqQcps = uniqBy(prop("name"), qcps);
  const qcpVersions = qcps.filter((qcp) => qcp.name === formData.qcp);
  const qcpItems = qcpVersions.map((qcp) => qcp.qcpItem).sort();

  touched.current = !equals(formData, initialFormData);

  useEffect(() => {
    QcpStore.qcps.load({limit: 100, offset: 0});
  }, []);

  useEffect(() => {
    setFormData({...formData, qcpItem: qcpItems[0] || ""});
  }, [formData.qcp]);

  const parseFile = (data) => {
    data.append("source", formData.source);
    data.append("qcp", formData.qcp);

    if(formData.qcpItem) {
      data.append("qcpItem", formData.qcpItem);
    }

    return axios.post(ROUTES.CERTIFICATE[ACTIONS.PARSE], data);
  };

  return (
    <ImportWrapper
      parseFile={parseFile}
      formData={formData}
      setFormData={setFormData}
      disabled={!formData.source || !formData.qcp || (qcpItems.length && !formData.qcpItem)}
      defaultData={{certificates: []}}
      {...props}
    >
      <Grid item xs={2}>
        <Select
          label="Source"
          required
          value={formData.source}
          onChange={(e) => setFormData({...formData, source: e.target.value})}
        >
          {keys(SOURCES).map((source) => (
            <MenuItem key={source} value={SOURCES[source]}>{source}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Select
          label="QCP/ITP"
          required
          value={formData.qcp}
          onChange={(e) => setFormData({...formData, qcp: e.target.value})}
        >
          {uniqQcps.map((qcp) => (
            <MenuItem key={qcp.name} value={qcp.name}>{qcp.name}</MenuItem>
          ))}
        </Select>
      </Grid>
      {!!qcpItems.length && (
        <Grid item xs={2}>
          <Select
            label="QCP/ITP Item"
            required
            value={formData.qcpItem}
            onChange={(e) => setFormData({...formData, qcpItem: e.target.value})}
          >
            {qcpItems.map((qcpItem) => (
              <MenuItem key={qcpItem} value={qcpItem}>{qcpItem}</MenuItem>
            ))}
          </Select>
        </Grid>
      )}
    </ImportWrapper>
  );
};

export default observer(ImportData);