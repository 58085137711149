import ProducerTestFooter from "@core/components/ProducerTestFooter";
import React, {useEffect} from "react";
import {MenuItem, Grid, Divider, Checkbox, FormControlLabel, Button} from "@mui/material";
import {FieldArray, Formik} from "formik";
import * as yup from "yup";
import {filter, keys, omit} from "ramda";
import {TEST_RESULTS} from "@core/constants/testResults";
import {MAGNIFICATIONS, ETCHING_STANDARDS, POSITIONS, ORIENTATIONS} from "@core/constants/test";
import {ACCEPTANCE_CRITERIA, TEST_STANDARDS, ELEMENT} from "./data";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import EtchantAutocomplete from "../../../../Tests/Test/components/EtchantAutocomplete";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import {FilesUploader} from "@core/components/Uploaders";
import MuiSelect from "@core/components/MuiSelect";

const Micrographic = ({test, saveTest, user, client, formRef, isProducerTest, closeNewTest}) => {
  const {witnesses, inspectorJobNumber, inspectionDate, properties} = test ?? {};

  const filteredAcceptances = filter((ec) => !ec.company || ec.company.includes(user.company.name), ACCEPTANCE_CRITERIA);

  const validationSchema = yup.object().shape({
    norm: yup.string().required("Test standard is required!"),
    client: !isProducerTest ? yup.string().required("Client is required!") : yup.string(),
    lab: !isProducerTest ? yup.string().required("Laboratory is required!") : yup.string(),
    acceptanceCriteria: filteredAcceptances.length ? yup.string().required("Acceptance criteria is required!") : yup.string(),
    elements: yup.array().of(yup.object().shape({
      etchingStandard: yup.string().required("Etching standard is required!"),
      etchingSolution: yup.string(),
      position: yup.string(),
      orientation: yup.string(),
      specimenId: yup.string().required("Specimen ID is required!"),
      magnification: yup.string().required("Magnification is required!"),
      electrolytic: yup.bool(),
      files: yup.array(),
      result: yup.string().required("Result is required!")
    })),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const initialValues = {
    client: !isProducerTest ? properties.client ?? client?.name ?? "" : "",
    lab: !isProducerTest ? properties.lab ?? user?.company.name ?? "" : "",
    norm: properties.norm || "",
    acceptanceCriteria: properties.acceptanceCriteria || "",
    elements: properties.elements || [ELEMENT],
    notes: properties.notes || "",
    witnesses: witnesses.map((witness) => witness.company) ?? [],
    inspectionDate: inspectionDate ?? "",
    inspectorJobNumber: inspectorJobNumber ?? "",
    tags: properties.tags ?? [],
    testExecutionDate: properties.testExecutionDate ?? "",
  };

  const getResult = ({elements}) => {
    const isAcceptable = elements.every((element) => element.result === TEST_RESULTS.ACCEPTABLE);

    return isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
  };
  
  const onSubmit = (values, actions) => {
    values.result = getResult(values);

    const formData = omit(["witnesses"], values);

    saveTest(formData, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    closeNewTest && closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {(props) => {
        const result = getResult(props.values);

        useEffect(() => {
          if(!props.values.acceptanceCriteria || !props.touched.acceptanceCriteria) return;

          const {elements = [ELEMENT], notes = ""} = filteredAcceptances[props.values.acceptanceCriteria] || {};

          props.setFieldValue("elements", elements);
          props.setFieldValue("notes", notes);
        }, [props.values.acceptanceCriteria]);

        return (
          <Grid container spacing={2}>
            {isProducerTest && (
              <Grid item xs={12}>
                <h1>Micrographic Test</h1>
              </Grid>
            )}
            {!isProducerTest && (
              <Grid item container spacing={5}>
                <Grid item xs={3}>
                  <ClientField
                    isFromProducer={!!client.name}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="lab"
                    label="Laboratory"
                    required
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="norm"
                  defaultOptions={TEST_STANDARDS}
                />
              </Grid>
              {filteredAcceptances.length && (
                <Grid item xs={3}>
                  <MuiSelect
                    required
                    label="Acceptance criteria"
                    name="acceptanceCriteria"
                    defaultOptions={keys(filteredAcceptances)}
                  />
                </Grid>
              )}
            </Grid>
            <FieldArray name="elements">
              {({remove, push}) => props.values.elements.map((element, index) => (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <SelectField
                        name={`elements.${index}.etchingStandard`}
                        label="Etching standard"
                        required
                        onChange={() => props.setFieldValue(`elements.${index}.etchingSolution`, "")}
                      >
                        {ETCHING_STANDARDS.map((standard) => (
                          <MenuItem key={standard} value={standard}>{standard}</MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                    <Grid item xs={3}>
                      <EtchantAutocomplete
                        etchingStandard={element.etchingStandard}
                        name={`elements.${index}.etchingSolution`}
                        setFieldValue={props.setFieldValue}
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={element.electrolytic}
                            onClick={() => props.setFieldValue(`elements.${index}.electrolytic`, !element.electrolytic)}
                            name={`elements.${index}.electrolytic`}
                            color="primary"
                          />
                        )}
                        label="Electrolytic"
                      />
                    </Grid>
                    {props.values.elements.length > 1 && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                    {props.values.elements.length - 1 === index && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          onClick={() => push(ELEMENT)}
                        >
                          Add
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={5}>
                    <Grid item xs={3}>
                      <SelectField
                        label="Position"
                        name={`elements.${index}.position`}
                      >
                        {POSITIONS.map((position) => (
                          <MenuItem key={position} value={position}>{position}</MenuItem>)
                        )}
                        <MenuItem value={undefined}>N/A</MenuItem>
                      </SelectField>
                    </Grid>
                    <Grid item xs={3}>
                      <SelectField
                        label="Orientation"
                        name={`elements.${index}.orientation`}
                      >
                        {ORIENTATIONS.map((orientation) => (
                          <MenuItem key={orientation} value={orientation}>{orientation}</MenuItem>)
                        )}
                        <MenuItem value={undefined}>N/A</MenuItem>
                      </SelectField>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label='Specimen ID'
                        name={`elements.${index}.specimenId`}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <SelectField
                        required
                        label="Magnification"
                        name={`elements.${index}.magnification`}
                      >
                        {MAGNIFICATIONS.map((magnification) => (
                          <MenuItem key={magnification} value={magnification}>{magnification}</MenuItem>)
                        )}
                      </SelectField>
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="flex-end" spacing={1}>
                    <Grid item xs={12}>
                      <FilesUploader
                        name={`elements.${index}.files`}
                        files={element.files || []}
                        onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                        changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <SelectField
                        required
                        label="Result"
                        name={`elements.${index}.result`}
                      >
                        <MenuItem value={TEST_RESULTS.ACCEPTABLE}>{TEST_RESULTS.ACCEPTABLE}</MenuItem>
                        <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE}>{TEST_RESULTS.NOT_ACCEPTABLE}</MenuItem>
                      </SelectField>
                    </Grid>
                  </Grid>
                </>
              ))}
            </FieldArray>
            <Grid item container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  rows={4}
                  multiline
                  name="notes"
                  label="Additional remarks"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {isProducerTest ? (
                <ProducerTestFooter
                  result={result}
                />
              ) : (
                <TestFooter
                  onSubmit={() => saveTest({...props.values, result})}
                  result={result}
                />
              )}
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default Micrographic;

