import {times} from "ramda";

export const TEST_STANDARDS = ["ASTM G76 (2018)"];

export const TEST_CONDITIONS = {
  AS_PER_STANDARD: "As per standard",
  MODIFIED: "Modified",
};

export const DEFAULT_VALUES_BY_TEST_CONDITION = {
  [TEST_CONDITIONS.AS_PER_STANDARD]: {
    impingementAngle: 90,
  }
};

export const DEFAULT_TEST_RESULTS_AMOUNT = 4;

export const TEST_RESULT = {
  specimen: "",
  hardness: undefined,
  density: undefined,
  massLoss: undefined,
  volumeLoss: 0,
  erodingParticleMassLoss: undefined,
  erosionValue: undefined,
};

export const DEFAULT_TEST_RESULTS = times(() => TEST_RESULT, DEFAULT_TEST_RESULTS_AMOUNT);
