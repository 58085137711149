import {Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import CampaignProducts from "./components/CampaignProducts";
import StatusChipItem from "./components/StatusChipItem";
import CollapsibleSection from "../../../components/CollapsibleSection";

function DashboardPage() {
  return (
    <>
      <Grid container gap={4} justifyContent="space-between" sx={{width: "100%"}}>
        <Grid item flex={1}>
          <Typography variant="h6" marginBottom={1}>Pending actions / Needs attention</Typography>
          
          <CollapsibleSection title={<Typography>Approvals (5)</Typography>}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Approve dimensional test</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approve notes test</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approve crack tip opening displacement test (CTOD)</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approve hot tensile test</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approve Chemical composition test</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CollapsibleSection>
          
          <CollapsibleSection title={<Typography>Request concession (2)</Typography>}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Request concession for pipes X1234, LF213, GHC1235</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Request
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Request concession for fittings LPQE223, R1234, GHF233</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Request
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CollapsibleSection>
          
          <CollapsibleSection title={<Typography>Pending campaign actions (1)</Typography>}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Request witness approval</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Request
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CollapsibleSection>
          
          <CollapsibleSection title={<Typography>Confirm (Sign) QCP / ITP</Typography>}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Confirm QCP 8262 Rev. 6 MPQT/FDPT</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Sign
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Confirm BLFB1-SSGN-QAC-ITP-SS7-00000-00001-00</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Confirm BLFB1-SSGN-QAC-ITP-SS7-00000-00001-00</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Confirm ITP-BPM-22.20 Rev.6</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      Sign
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CollapsibleSection>
        </Grid>
        <Grid item flex={1}>
          <Typography variant="h6" marginBottom={1}>Technical Queries</Typography>
          
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Request info</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Pipe length concession</TableCell>
                <TableCell>
                  <StatusChipItem text={"Approved"} status={"success"}/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Impact slightly out of bound</TableCell>
                <TableCell>
                  <StatusChipItem text={"Pending"} status={"warning"}/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fitting scratch corrosion</TableCell>
                <TableCell>
                  <StatusChipItem text={"Failed"} status={"error"}/>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      
      <Typography variant="h6" marginTop={4} marginBottom={1}>Product Types</Typography>
      <CampaignProducts />
    </>
  );
}

export default DashboardPage;