import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {TableStore} from "@core/stores";
import axios from "axios";
import {makeObservable, observable} from "mobx";
import {isEmpty} from "ramda";

class SingleProductStore {
  constructor() {
    makeObservable(this, {
      singleProducts: observable,
    });
  }

  singleProducts = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.singleProducts.fetch, ...queryParams};
      const {offset, limit, search, sort, status, singleCampaign} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
        status,
        singleCampaign,
      };

      this.singleProducts.setFetch(newQueryParams);

      return axios.get(ROUTES.SINGLE_PRODUCT[ACTIONS.ALL], {params});
    }
  });
}

export default new SingleProductStore();