import {FEATURES} from "@core/constants/features";
import {observer} from "mobx-react-lite";
import {filter} from "ramda";
import React from "react";
import useStores from "../../../../useStores";
import GeneralInfo from "./components/GeneralInfo";
import TestReports from "../TestReports";
import CampaignPageSkeleton from "../CampaignPageSkeleton";
import WeldsCanvas from "./components/WeldsCanvas";
import AssemblyTestReports from "./components/AssemblyTestReports";
import Uploads from "./components/Uploads";
import Welds from "./components/Welds";
import BaseMaterials from "../BaseMaterials";
import Coating from "./components/Coating";
import BundleInformation from "./components/BundleInformation";
import {PAGE_TABS, PAGE_TABS_LABELS, SUB_NAVIGATION_BY_TAB} from "./constants";

const COMPONENT_BY_TAB = {
  [PAGE_TABS.GENERAL_INFO]: GeneralInfo,
  [PAGE_TABS.BASE_MATERIALS]: BaseMaterials,
  [PAGE_TABS.WELDS]: Welds,
  [PAGE_TABS.COATING]: Coating,
  [PAGE_TABS.PIPELINE]: WeldsCanvas,
  [PAGE_TABS.TEST_REPORTS]: TestReports,
  [PAGE_TABS.ASSEMBLY_TEST_REPORTS]: AssemblyTestReports,
  [PAGE_TABS.UPLOADS]: Uploads,
  [PAGE_TABS.BUNDLE_INFORMATION]: BundleInformation,
};

const Welding = (props) => {
  const {UserStore} = useStores();

  const bundleCampaignAllowed = UserStore.features[FEATURES.BUNDLE_CAMPAIGNS];
  const pageTabs = bundleCampaignAllowed ? PAGE_TABS : filter((tab) => tab !== PAGE_TABS.BUNDLE_INFORMATION, PAGE_TABS);

  return (
    <CampaignPageSkeleton
      pageTabs={pageTabs}
      pageTabsLabels={PAGE_TABS_LABELS}
      componentByTab={COMPONENT_BY_TAB}
      subtabsByTab={SUB_NAVIGATION_BY_TAB}
      {...props}
    />
  );
};

export default observer(Welding);