import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import moment from "moment";
import {Box, Grid, MenuItem, useTheme} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import MuiSelect from "@core/components/MuiSelect";
import SelectField from "@core/components/FormikSelect";
import {Input, Date} from "@core/components/Form";
import {commonValidationSchema, TEST_STANDARDS, SPECIMEN_PREPARATIONS} from "./data";
import {indexBy, prop, keys} from "ramda";
import {POSITIONS, ORIENTATIONS} from "@core/constants/test";
import ClientField from "../../../../Tests/Test/components/ClientField";
import {getSurface} from "@core/helpers";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import TestFooter from "../../LabTestFooter";
import * as yup from "yup";

const CorrosionTest = ({
  test,
  user,
  saveTest,
  client,
  formRef,
}) => {
  
  const theme = useTheme();
  
  const [validationSchema, setValidationSchema] = useState(yup.object().shape(commonValidationSchema));
  
  const initialValues = {
    client: test.properties.client || client.name || "",
    laboratory: test.properties.laboratory || user.company.name || "",
    testStandard: "",
    method: "",
    acceptance: "",
    specimenId: "",
    position: "",
    orientation: "",
    diameter: "", // T
    weight: "", // W
    size: "",// L
    exposedSurface: "",
    preparation: "",
    heatTreatment: "",
    testStart: "",
    testingTime: "",
    testEnd: "",
    testTemperature: "",
    zone: "",
    testReactant: "",

    // manual form
    bendingAngle: "",
    mandrelDiameter: "",
    magnification: "",

    // automatic
    materialDensity: "",
    initialWeight: "",
    finalWeight: "",
    massLoss: "",
    examinedArea: "",
    maxCorrosionRate: {
      value: "",
      unit: "",
    },
    corrosionRate: "",

    // automatic with pits
    maxPits: "",
    maxWeightLoss: {
      value: "",
      unit: "",
    },
    numberOfPits: {
      sideA: "",
      sideB: ""
    },
    pitsPerCmSquared: "",
    pitsDepth: {
      avg: "",
      max: ""
    },
    result: "",
  };

  const methodsByStandard = indexBy(prop("value"), TEST_STANDARDS);
  
  return (
    <Formik
      validateOnMount
      onSubmit={saveTest}
      innerRef={formRef}
      initialValues={{...initialValues, ...test.properties}}
      validationSchema={validationSchema}
    >
      {(props) => {

        const {methods = {}, zones = [], component: Component, validationSchema} = methodsByStandard[props.values.testStandard] || {};

        useEffect(() => {
          setValidationSchema(validationSchema);
          props.validateForm();
        }, [props.values.testStandard]);
        
        useEffect(() => {
          props.setFieldValue("exposedSurface", getSurface(props.values.diameter, props.values.weight, props.values.size));
        }, [props.values.diameter, props.values.weight, props.values.size]);

        useEffect(() => {
          if(!props.values.testStart || !props.values.testingTime) return;

          props.setFieldValue("testEnd", moment(props.values.testStart).add(props.values.testingTime, "hours").format());
        }, [props.values.testStart, props.values.testingTime]);

        useEffect(() => {
          if(!props.values.method) return;

          props.setValues(() => ({
            ...props.values,
            ...methods[props.values.method],
          }));
        }, [props.values.method]);

        return (
          <>
            <Grid container spacing={3}>
              <Grid item container spacing={3}>
                <Grid item xs={3}>
                  <ClientField
                    isFromProducer={!!client.name}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="laboratory"
                    label="Laboratory"
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={3}>
                  <SelectField
                    name="testStandard"
                    label='Test standard'
                    required
                  >
                    {TEST_STANDARDS.map(({value}) => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name="method"
                    label='Method/Practice'
                    required
                  >
                    {keys(methods).map((methods) => (
                      <MenuItem key={methods} value={methods}>{methods}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="acceptance"
                    label="Acceptance criteria"
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    name="specimenId"
                    label="Specimen ID"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name="position"
                    label='Position'
                    required
                  >
                    {POSITIONS.map((position) => (
                      <MenuItem key={position} value={position}>{position}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name="orientation"
                    label='Orientation'
                    required
                  >
                    {ORIENTATIONS.map((orientation) => (
                      <MenuItem key={orientation} value={orientation}>{orientation}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name="zone"
                    label='Zone'
                  >
                    {zones.map((zone) => (
                      <MenuItem key={zone} value={zone}>{zone}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h3>Dimensions</h3>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={3} sx={{position: "relative"}}>
                  <TextField
                    name="diameter"
                    label="T"
                    required
                    endAdornment="MM"
                    type="number"
                  />
                  <Box
                    sx={{position: "absolute", right: theme.spacing(-2), bottom: 10}}
                  >
                    X
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{position: "relative"}}>
                  <TextField
                    name="weight"
                    label="W"
                    required
                    endAdornment="MM"
                    type="number"
                  />
                  <Box
                    sx={{position: "absolute", right: theme.spacing(-2), bottom: 10}}
                  >
                    X
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="size"
                    label="L"
                    required
                    endAdornment="MM"
                    type="number"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="exposedSurface"
                    label="Surface"
                    required
                    endAdornment={<span>CM<sup>2</sup></span>}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={6}>
                  <MuiSelect
                    required
                    label="Specimen preparation"
                    name="preparation"
                    defaultOptions={SPECIMEN_PREPARATIONS}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="heatTreatment"
                    label="Heat treatment"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    name="testTemperature"
                    label="Test temperature"
                    type="number"
                    endAdornment="°C"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={3}>
                  <Date
                    format={DATE_TIME_FORMAT}
                    type="datetime-local"
                    label="Test start"
                    value={props.values.testStart}
                    onChange={(event) => props.setFieldValue("testStart", event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    name="testingTime"
                    label="Test duration"
                    type="number"
                    endAdornment='H'
                    inputProps={{min: 1}}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    disabled
                    name="testEnd"
                    label="Test end"
                    value={props.values.testEnd ? moment(props.values.testEnd).format(DATE_TIME_FORMAT) : ""}
                  />
                </Grid>
              </Grid>
              {Component && <Component />}
            </Grid>
            <TestFooter
              onSubmit={saveTest}
              result={props.values.result}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default CorrosionTest;