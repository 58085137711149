import {Link, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const ItemsList = ({items, activeItem, setActiveItem, onItemClick}) => {
  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2}>Item</TableCell>
          <TableCell rowSpan={2}>Description</TableCell>
          <TableCell>Bundle</TableCell>
          <TableCell colSpan={3}>Main components</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Nominal size</TableCell>
          <TableCell>Dimensions</TableCell>
          <TableCell>Material</TableCell>
          <TableCell>Production data (MTR)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, index) => (
          <TableRow
            key={index}
            onClick={onItemClick}
            onMouseOver={() => setActiveItem(index)}
            onMouseLeave={() => setActiveItem(null)}
            sx={{
              cursor: "pointer",
              outline: activeItem === index ? `${items[activeItem].color} solid 2px` : "none",
              "& td": {
                borderColor: activeItem === index ? "transparent" : "inherit"
              }
            }}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>{item.nominalSize || "-"}</TableCell>
            <TableCell>{item.dimensions}</TableCell>
            <TableCell>{item.material}</TableCell>
            <TableCell>
              {item.order ? (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  underline="hover"
                  target="_blank"
                  href={`/transfers/${item.order._id}`}
                >
                  {item.order.orderNumber}
                </Link>
              ) : "-"}
            </TableCell>
          </TableRow>
        )
        )}
      </TableBody>
    </Table>
  );
};

export default ItemsList;