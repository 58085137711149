import {CHEMICAL_ELEMENTS, TYPES} from "@core/constants/test";

export const PLOT_TYPES = {
  HISTOGRAM: "histogram",
  BOX_PLOT: "boxplot",
  VIOLIN: "violin",
  SCATTER: "scatter",
  RADAR: "radar",
};

export const PLOT_TYPES_TITLES = {
  [PLOT_TYPES.HISTOGRAM]: "Histogram",
  [PLOT_TYPES.BOX_PLOT]: "Box Plot",
  [PLOT_TYPES.VIOLIN]: "Violin",
  [PLOT_TYPES.SCATTER]: "Scatter",
  [PLOT_TYPES.RADAR]: "Radar",
};

export const PARAM_TYPES_BY_TEST_TYPE = {
  [TYPES.TENSILE]: {
    yieldStrength: "Yield Strength",
    tensileStrength: "Tensile Strength",
    elongation: "Elongation",
    reductionOfArea: "Reduction of Area",
  },
  [TYPES.IMPACT]: {
    absorbedEnergy: "Absorbed Energy",
    shearArea: "Shear Area",
    lateralExpansion: "Lateral Expansion",
  },
  [TYPES.CUSTOM]: {
    mroMils: "MRO MILS",
    fbeMils: "FBE MILS",
    totalMils: "TOTAL MILS",
  },
  [TYPES.DIMENSIONAL]: {
    thickness: "Thickness",
    internalDiameter: "Internal Diameter",
    externalDiameter: "External Diameter",
    outOfRoundness: "Out of Roundness",
    straightness: "Straightness",
    eccentricity: "Eccentricity"
  },
  [TYPES.HARDNESS]: {
    value: "Value",
  },
  [TYPES.CHEMICAL]: CHEMICAL_ELEMENTS
};

export const COLORS = {
  lightBlue: {
    backgroundColor: "#64cee3",
    borderColor: "#23bada",
  },
  orange: {
    backgroundColor: "#ffb691",
    borderColor: "#ff924c",
  },
  violet: {
    backgroundColor: "#d994e5",
    borderColor: "#7b3596",
  },
  red: {
    backgroundColor: "#EF3340",
  },
  blue: {
    backgroundColor: "#9bc3e9",
    borderColor: "#077de1",
  }
};