import * as yup from "yup";

export const FERRITE_TEST_TYPES = {
  BY_FERRITE_SCOPE: "by Ferritescope",
  MANUAL_COUNT: "Manual count",
  AUTOMATIC_COUNT: "Automatic count",
};

export const TEST_STANDARDS_BY_TEST_TYPE = {
  [FERRITE_TEST_TYPES.BY_FERRITE_SCOPE]: "",
  [FERRITE_TEST_TYPES.MANUAL_COUNT]: "ASTM E562 (2019e1)",
  [FERRITE_TEST_TYPES.AUTOMATIC_COUNT]: "ASTM E1245-03 (2016)",
};

export const ferriteScopeSchema = {
  min: yup.number().min(0),
  max: yup.number(),
  elements: yup.array().of(yup.array().of(yup.number().required("Value is required!"))),
  witnesses: yup.array().of(yup.object()),
  notes: yup.string(),
};

export const manualCountSchema = {
  min: yup.number().min(0).required("Min is required!"),
  max: yup.number().required("Max is required!"),
  maxRelativeAccuracy: yup.number().max(100),
  etchingStandard: yup.string().required("Etching standard is required!"),
  etchingSolution: yup.string().required("Etching solution is required!"),
  electrolytic: yup.boolean(),
  position: yup.string(),
  orientation: yup.string(),
  specimenId: yup.string().required("Specimen ID is required!"),
  magnification: yup.string().required("Magnification is required!"),
  pointsPerField: yup.number().min(1).required("Points per field is required!"),
  elements: yup.array().of(yup.number().required("Value is required!")),
  meanFerrite: yup.number().required(),
  deviation: yup.number().required(),
  ci: yup.number().required(),
  accuracy: yup.number().required(),
};

export const automaticCountSchema = {
  min: yup.number().min(0).required("Min is required!"),
  max: yup.number().required("Max is required!"),
  maxRelativeAccuracy: yup.number().max(100),
  etchingStandard: yup.string().required("Etching standard is required!"),
  etchingSolution: yup.string().required("Etching solution is required!"),
  electrolytic: yup.boolean(),
  position: yup.string(),
  orientation: yup.string(),
  specimenId: yup.string().required("Specimen ID is required!"),
  magnification: yup.string().required("Magnification is required!"),
  totalAreaAnalyzed: yup.number().required("Total area analyzed is required!"),
  singleFieldArea: yup.number().required("Single field area is required!"),
  elements: yup.array().of(yup.number().required("Value is required!")),
  meanFerrite:  yup.number().required(),
  meanFerriteMicron: yup.number().required(),
  deviation: yup.number().required(),
  ci: yup.number().required(),
  accuracy: yup.number().required(),
};