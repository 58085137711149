import Test from "@core/components/Test";
import TestSummaryTable from "@core/components/TestSummaryTable";
import {indexBy, prop} from "ramda";
import React, {useMemo, useState} from "react";
import {Grid, Box, Button} from "@mui/material";

const SelectedTests = ({tests, onSignAllInProgress, goToPreviousStep, onSignAll}) => {
  const [filteredTestIds, setFilteredTestIds] = useState([]);

  const testsById = useMemo(() => indexBy(prop("_id"), tests), [tests]);
  const filteredTests = useMemo(() => filteredTestIds.length ? filteredTestIds.map((id) => testsById[id]) : tests, [filteredTestIds, testsById]);

  return (
    <Box sx={{padding: 2}}>
      <Grid container spacing={2}>
        {tests.map((test, index) => (
          <Grid item xs={12}  key={test._id}>
            <Test
              test={test}
              isLast={index === tests.length - 1}
              isFirst={!index}
              disabled
              shareLink={false}
              hideSign={true}
              timeline={false}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <TestSummaryTable
            tests={filteredTests}
            setFilteredTestIds={setFilteredTestIds}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="secondaryNewDesign"
              size="small"
              disabled={onSignAllInProgress}
              onClick={goToPreviousStep}
            >
              Go back
            </Button>
          </Grid>
          <Grid item marginLeft={2}>
            <Button
              variant="secondaryNewDesign"
              size="small"
              disabled={onSignAllInProgress}
              onClick={onSignAll}
            >
              Sign all
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectedTests;