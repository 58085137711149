import React, {useMemo} from "react";
import {Input, MenuItem, Select} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {any, propEq} from "ramda";
import modules from "@core/constants/modules";
import testsConfig from "@core/configs/test";
import {INTERNAL_LAB} from "@core/constants/test";
import {observer} from "mobx-react";
import useStores from "../../../../../useStores";

const LabEdit = observer(({classes, test, updateTests, groupedTests, qcp}) => {
  const {CompanyStore} = useStores();

  const labCompanies = useMemo(() => CompanyStore.meta.filter((company) => {
    return any(propEq(modules.LAB_INTERNAL, "name"), company.modules);
  }), [CompanyStore.meta]);

  const testIds = useMemo(() => groupedTests.map((test) => test._id), [groupedTests]);
  
  if(!testsConfig[test.type].responsible.includes(modules.LAB_INTERNAL)) return "N/A";
  
  return (
    <Select
      classes={{select: classes.select}}
      fullWidth
      value={test.assignee}
      onChange={(event) => {
        const value = event.target.value === test.assignee ? "" : event.target.value;
        updateTests({assignee: value}, testIds);
      }}
      input={<Input />}
    >
      {labCompanies.map((company) => (
        <MenuItem key={company._id} value={company}>
          {company.name}
        </MenuItem>
      ))}
      {qcp && (
        <MenuItem value={INTERNAL_LAB}>{INTERNAL_LAB}</MenuItem>
      )}
    </Select>
  );
});

export default withStyles(LabEdit, styles);