import React, {useMemo, memo, useCallback} from "react";
import {observer} from "mobx-react-lite";
import {last} from "ramda";
import {getStalkEndWeldNumber} from "../../../../../../helpers";
import {getPipeLength} from "../../../../helpers";
import {MARGIN} from "../../constants";
import Weld from "../Weld";
import Pipe from "../Pipe";
import useStores from "../../../../../../../../../../useStores";

const StalkItem = observer(({state, scale, totalLength, weld, index, indexInRow, settings, stalk, chunk, chunkIndex, pipes, welds, animateShape, setOpenPipe, setOpenWeld, pipeConfig, weldConfig, defectPositionsByPipeId, defectPositionsByWeldId, tieInWeldsByStalkNumber, stalksNumbers}) => {
  const {CampaignStore} = useStores();

  const pipeLengthByWeldId = CampaignStore.pipeLengthByWeldId;
  
  const stalkLength = useMemo(() => last(stalk).position + 1, [chunkIndex]);
  const isLast = useMemo(() => index === stalkLength - 1, [index, chunkIndex]);
  const isLastInChunk = useMemo(() => indexInRow === chunk.length - 1, [index, chunk.length]);

  const pipeALength = useMemo(() => {
    return getPipeLength(state, weld, pipeLengthByWeldId, stalksNumbers, tieInWeldsByStalkNumber);
  }, [weld._id, state]);

  const pipeBLength = useMemo(() => {
    if(isLast) {
      const weldNumber = getStalkEndWeldNumber(weld.stalkNumber);

      return getPipeLength(state, {weldNumber, _id: weldNumber}, pipeLengthByWeldId, stalksNumbers, tieInWeldsByStalkNumber);
    }

    return getPipeLength(state, weld, pipeLengthByWeldId, stalksNumbers, tieInWeldsByStalkNumber);
  }, [weld._id, state]);

  const getPipeCoordinates = useCallback((indexInRow, welds) => {
    let baseX;

    if(!welds) baseX = pipeConfig.WIDTH + MARGIN;
    else baseX = (pipeConfig.WIDTH + weldConfig.WIDTH + 2 * MARGIN);

    return {
      x: baseX * indexInRow,
      y: 0,
    };
  }, []);

  const pipeCoordinates = useMemo(() => {
    const pipeCoordinates = getPipeCoordinates(indexInRow, settings.welds);

    return pipeCoordinates;
  }, [indexInRow, settings.welds]);

  const nextPipeCoordinates = useMemo(() => {
    const pipeCoordinates = getPipeCoordinates(indexInRow + 1, settings.welds);

    return pipeCoordinates;
  }, [indexInRow, settings.welds]);

  const weldCoordinates = useMemo(() => {
    const weldCoordinates = {
      x: pipeCoordinates.x + pipeConfig.WIDTH + MARGIN,
      y: (pipeConfig.HEIGHT - weldConfig.HEIGHT) / 2,
    };

    return weldCoordinates;
  }, [pipeCoordinates.x]);

  return (
    <>
      <Pipe
        scale={scale}
        stalkLength={stalkLength}
        pipeConfig={pipeConfig}
        weldConfig={weldConfig}
        id={weld.pipeA}
        jointNumber={weld.jointNumberA}
        pipeLength={pipeALength}
        totalLength={totalLength}
        index={index}
        settings={settings}
        pipes={pipes}
        welds={welds}
        pipeCoordinates={pipeCoordinates}
        weldCoordinates={weldCoordinates}
        animateShape={animateShape}
        setOpenPipe={setOpenPipe}
        defects={defectPositionsByPipeId[weld.pipeA]}
      />
      <Weld
        index={index}
        pipes={pipes}
        welds={welds}
        settings={settings}
        weldNumber={weld.weldNumber}
        pipeLength={pipeALength}
        totalLength={totalLength}
        animateShape={animateShape}
        pipeCoordinates={pipeCoordinates}
        weldCoordinates={weldCoordinates}
        setOpenWeld={setOpenWeld}
        isLast={isLast}
        isLastInChunk={isLastInChunk}
        scale={scale}
        chunk={chunk}
        weldConfig={weldConfig}
        pipeConfig={pipeConfig}
        defects={defectPositionsByWeldId[weld._id]}
      />
      <Pipe
        scale={scale}
        stalkLength={stalkLength}
        totalLength={totalLength}
        pipeConfig={pipeConfig}
        weldConfig={weldConfig}
        id={weld.pipeB}
        jointNumber={weld.jointNumberB}
        pipeLength={pipeBLength}
        index={index + 1}
        settings={settings}
        pipes={pipes}
        welds={welds}
        pipeCoordinates={nextPipeCoordinates}
        weldCoordinates={weldCoordinates}
        animateShape={animateShape}
        setOpenPipe={setOpenPipe}
        defects={defectPositionsByPipeId[weld.pipeB]}
      />
    </>
  );
});

export default memo(StalkItem);