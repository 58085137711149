import {Box} from "@mui/material";
import React from "react";

const Content = ({children}) => {
  return (
    <Box sx={{
      flexGrow: 1,
      maxWidth: 700,
      backgroundColor: "#FFFFFF",
      border: "1px solid #efefef",
      borderRadius: 2,
      paddingX: 3,
      paddingY: 4,
    }}>
      {children}
    </Box>
  );
};

export default Content;