import Content from "@core/components/UptimeLikeFormLayout/components/Content";
import {Box, Typography} from "@mui/material";
import React from "react";

const FormErrors = ({children}) => {
  return (
    <Box
      marginY={6}
      sx={{display: "flex", justifyContent: "flex-end"}}
    >
      <Content>
        <Typography variant="h6" color="error" gutterBottom>
          Review errors in this form
        </Typography>
        {children}
      </Content>
    </Box>
  );
};

export default FormErrors;