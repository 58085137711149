import React from "react";
import {ThemeProvider} from "@mui/material/styles";
import {Box, CssBaseline, Typography} from "@mui/material";
import Content from "./components/Content";
import Description from "./components/Description";
import AsteriskNotification from "./components/AsteriskNotification";
import FormErrors from "./components/FormErrors";
import ValidationErrors from "@core/components/TestForms/ValidationErrors";
import {theme} from "./constant";

function UptimeLikeFormLayout({title, formRows = [], formFooter, displayValidationErrors}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Box sx={{
        paddingY: 4,
        maxWidth: "1248px",
        paddingX: 3,
        marginX: "auto"
      }}>
        <Typography variant="h4" fontSize="1.8rem" gutterBottom>
          {title}
        </Typography>
        <Box marginTop={6}>
          <AsteriskNotification />
          {formRows.map(({sectionDescription, sectionForm}) => (
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 6
            }}>
              <Description
                title={sectionDescription?.title}
                text={sectionDescription?.text}
              />
              <Content>
                {sectionForm}
              </Content>
            </Box>
          ))}
        </Box>
        {displayValidationErrors && (
          <ValidationErrors
            container={FormErrors}
          />
        )}
        <Box marginY={6}>
          {formFooter}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default UptimeLikeFormLayout;