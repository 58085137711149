// TODO upgrade ethers package to fix the "ws" package vulnerability workaround. see package.json comments!
import { ethers } from 'ethers';
import CompaniesABI from './abis/companiesABI';

const ContractInstance = new ethers.Contract(process.env.REACT_APP_COMPANIES_CONTRACT_ADDRESS, CompaniesABI, null);

class CompaniesService {

  /**
     * @name generateAddUserTX
     * @param {string} companyId - ID of the company for which the user will be added
     * @param {string} userAddress - Public Address of the User 
     * @param {uint} permissions - the permissions of the user build using the Permissions builder
     * @summary Generates the transaction object that is adding user to company. Should be passed as QR information to the Signing App.
     */
  static addUserTx(companyId, userAddress, permissions) {
    const companyBytes32ID = ethers.utils.formatBytes32String(companyId);

    const data = ContractInstance.interface.encodeFunctionData("addEntity", [companyBytes32ID, userAddress, permissions]);
    return { to: process.env.REACT_APP_COMPANIES_CONTRACT_ADDRESS, data };
  }

  /**
     * @name generateRemoveUserTX
     * @param {string} companyId - ID of the company for which the user will be added
     * @param {string} userAddress - Public Address of the User that will be removed
     * @summary Generates the transaction object that is removing user from company. Should be passed as QR information to the Signing App. 
     */
  static removeUserTX(companyId, userAddress) {
    const companyBytes32ID = ethers.utils.formatBytes32String(companyId);

    const data = ContractInstance.interface.encodeFunctionData("removeEntity", [companyBytes32ID, userAddress]);
    return { to: process.env.REACT_APP_COMPANIES_CONTRACT_ADDRESS, data };
  }

  /**
     * @name changeUserPermissionsTX
     * @param {string} companyId - ID of the company
     * @param {string} userAddress - Public address of the user for which the permissions will be changed
     * @param {number} newPermissions - The number that defines the new user permissions
     * @summary Generates the transaction object that is changing user's permissions. Should be passed as QR information to the Signing App.
     */
  static changeUserPermissionsTX(companyId, userAddress, newPermissions) {
    const companyBytes32ID = ethers.utils.formatBytes32String(companyId);

    const data = ContractInstance.interface.encodeFunctionData("modifyEntityPermissions", [companyBytes32ID, userAddress, newPermissions]);
    return { to: process.env.REACT_APP_COMPANIES_CONTRACT_ADDRESS, data };
  }

}

export default CompaniesService;