import React, {useCallback, useState} from "react";
import {Button, CircularProgress, Grid, Typography} from "@mui/material";

import {withStyles} from "tss-react/mui";

const styles = (theme) => ({
  loader: {
    color: "white",
    marginLeft: theme.spacing(1)
  },
});

const LinkedAccount = ({
  title,
  id,
  provider,
  email,
  onLink,
  onUnlink,
  classes,
  icon
}) => {

  const [processing, setProcessing] = useState(false);

  const handleClick = useCallback(() => {
    setProcessing(true);
    try {
      id ? onUnlink(provider, title) : onLink(provider);
    } finally {
      setProcessing(false);
    }
  }, [onLink, onUnlink, id, provider, title]);

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item>
        <div>{email}</div>
      </Grid>
      <Grid item>
        <Button
          margin="normal"
          variant="contained"
          color="primary"
          disabled={processing}
          onClick={handleClick}
        >
          {id ? "Unlink" : "Link"}
          {!processing ? icon : <CircularProgress color="inherit" size={14} classes={{root: classes.loader}}/>}
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(withStyles(LinkedAccount, styles));
