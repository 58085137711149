import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import {MdAdd} from "react-icons/md";
import ElementName from "../ElementName";
import TextField from "@core/components/FormikTextField";
import FormulaBuilder from "@core/components/FormulaBuilder";
import TestResultTableCell from "@core/components/TestResultTableCell";
import {getIsWithinRequirements} from "@core/helpers";
import useResize from "@core/hooks/useResize";
import {useFormikContext} from "formik";
import {splitEvery} from "ramda";
import React, {useRef, useState} from "react";
import {Grid, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {COLUMN_MIN_WIDTH, TEST_RESULT} from "../../constants";
import {calculateElementValue} from "../../services";

const TestResults = () => {
  const [formulaBuilderOpen, setFormulaBuilderOpen] = useState(false);
  const {values, setFieldValue} = useFormikContext();

  const containerRef = useRef({});

  const {width: availableWidth} = useResize(containerRef);

  const requiredMinWidth = (values.testResults.length + 1) * COLUMN_MIN_WIDTH;
  const countColumns = requiredMinWidth > availableWidth ? Math.ceil(values.testResults.length / 2) : values.testResults.length;
  const columnWidth = availableWidth / (countColumns + 1);

  const tables = splitEvery(Number(countColumns), values.testResults);

  const selectedElements = values.testResults.filter(({element}) => element);

  const valuesBySpecimen = values.specimenIds.map((specimen, index) => values.testResults.map((result) => ({element: result.element, value: result.values[index]})));

  const recalculateValues = () => {
    const testResults = values.testResults.map((result) => {
      if(!result.formula) return result;

      return {
        ...result,
        values: result.values.map((value, index) => calculateElementValue(valuesBySpecimen[index], result.formula))
      };
    });

    setFieldValue("testResults", testResults);
  };

  const addFormula = ({bm: element, formula, min, max}) => {
    const newElement = {...TEST_RESULT, element, formula, values: values.specimenIds.map((specimen, index) => calculateElementValue(valuesBySpecimen[index], formula))};
    setFieldValue("testResults", values.testResults.concat(newElement));
    setFieldValue(`acceptanceCriteria.${element}`, {min, max});
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h6">Test results</Typography>
          </Grid>
          <Grid item>
            {!!selectedElements.length && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setFormulaBuilderOpen(true)}
              >
                Add custom formula
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} ref={containerRef}>
        {tables.map((batch, index) => (
          <Box
            sx={{marginBottom: 3}}
            key={index}
          >
            <Table
              className="styled-table"
              sx={{
                "& th, td": {
                  width: columnWidth || COLUMN_MIN_WIDTH,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={3}>Spec. ID</TableCell>
                  <TableCell>Element</TableCell>
                  {batch.map(({element, formula}, indexInBatch) => (
                    <TableCell key={`element-name-${indexInBatch}`}>
                      <ElementName
                        index={index * countColumns + indexInBatch}
                        element={element}
                        formula={formula}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <AcceptanceCriteriaLabel min/>
                  </TableCell>
                  {batch.map(({element}, indexInBatch) => (
                    <TableCell key={`element-min-${indexInBatch}`}>
                      <TextField
                        disabled={!element}
                        type="number"
                        name={`acceptanceCriteria.${element}.min`}
                        inputProps={{min: 0}}
                        errorMessage={null}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <AcceptanceCriteriaLabel max/>
                  </TableCell>
                  {batch.map(({element}, indexInBatch) => (
                    <TableCell key={`element-max-${indexInBatch}`}>
                      <TextField
                        disabled={!element}
                        type="number"
                        name={`acceptanceCriteria.${element}.max`}
                        inputProps={{min: values.acceptanceCriteria[element]?.min || 0}}
                        errorMessage={null}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.specimenIds.map((specimenId, specimenIndex) => (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TextField
                        name={`specimenIds.${specimenIndex}`}
                        required
                        errorMessage={null}
                      />
                    </TableCell>
                    {batch.map(({values: results, element, formula}, indexInBatch) => {
                      const {min = null, max = null} = values.acceptanceCriteria[element] || {};

                      return (
                        <TableCell key={`element-value-${indexInBatch}`}>
                          {formula ? (
                            <TestResultTableCell
                              value={results[specimenIndex]}
                              min={min}
                              max={max}
                            />
                          ) : (
                            <TextField
                              type="number"
                              name={`testResults.${index * countColumns + indexInBatch}.values.${specimenIndex}`}
                              required
                              inputProps={{min: 0}}
                              error={!getIsWithinRequirements(results[specimenIndex], min, max)}
                              errorMessage={null}
                              onChange={recalculateValues}
                            />
                          )}
                        </TableCell>
                      );}
                    )}
                  </TableRow>
                ))}
                <TableRow colSpan={batch.length + 1}>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setFieldValue("specimenIds", values.specimenIds.concat(""));
                        setFieldValue("testResults", values.testResults.map((result) => ({...result, values: result.values.concat(undefined)})));
                      }}
                    >
                      <MdAdd size={80} />Add specimen
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ))}
        {formulaBuilderOpen && (
          <FormulaBuilder
            elements={values.testResults.map(({element, values}) => ({bm: element, value: values[0]}))}
            chemical={{}}
            formula=""
            close={() => setFormulaBuilderOpen(false)}
            onSubmit={addFormula}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TestResults;