import AwesomeDebouncePromise from "awesome-debounce-promise";
import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, CircularProgress, Box} from "@mui/material";
import {Input} from "@core/components/Form";
import DrawerHeader from "@core/components/DrawerHeader";
import ResizableDrawer from "@core/components/ResizableDrawer";
import TableNavigation from "@core/components/TableNavigation";
import {values} from "ramda";
import useStores from "../../../useStores";
import BaseMaterials from "./BaseMaterials";
import {TABS} from "./constants";

const SelectBaseMaterialsForm = ({setSelectedIds, baseMaterialFormOpen, setBaseMaterialFormOpen, campaignId, selectedIds}) => {
  const [selected, setSelected] = useState(selectedIds);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(TABS.AVAILABLE_BASE_MATERIALS);

  const {SingleProductStore} = useStores();
  
  const {status} = SingleProductStore.singleProducts;

  useEffect(() => {
    if(!baseMaterialFormOpen) return;

    setSelected(selectedIds);
    setTab(TABS.AVAILABLE_BASE_MATERIALS);
  }, [baseMaterialFormOpen]);
  
  const onSave = useCallback(async () => {
    setLoading(true);
    await setSelectedIds(selected);
    setLoading(false);
    setBaseMaterialFormOpen(false);
  }, [selected.length]);

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    SingleProductStore.singleProducts.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  const tabs = campaignId ? values(TABS) : [TABS.AVAILABLE_BASE_MATERIALS];

  return (
    <ResizableDrawer
      open={baseMaterialFormOpen}
      close={() => setBaseMaterialFormOpen(false)}
      keepMounted={false}
    >
      <DrawerHeader
        close={() => setBaseMaterialFormOpen(false)}
        text="Modify base material(s)"
      />
      <Box sx={{padding: 2}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              type="text"
              endAdornment={status.loading && <CircularProgress size={20} />}
              placeholder="Search product(s)"
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12}>
            <TableNavigation
              views={tabs}
              currentView={tab}
              onClick={setTab}
            />
            <BaseMaterials
              setSelected={setSelected}
              selected={selected}
              campaignId={campaignId}
              tab={tab}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                onClick={() => setBaseMaterialFormOpen(false)}
                color="secondary"
                variant="contained"
              >
                  Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={onSave}
              >
                Save
                {loading && <CircularProgress size={20} sx={{ml: 1}}/>}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ResizableDrawer>
  );
};

export default observer(SelectBaseMaterialsForm);