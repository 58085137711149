import utils from "../../Blockchain/utils";
import {ethers} from "ethers";
import {TRANSACTION_ACTIONS_BY_TYPE, TRANSACTION_TYPES} from "@core/constants/transactions";
import {STATUSES, TYPES} from "@core/constants/test";
import ContractInstance from "../../Blockchain/contract-instance";
import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {NotificationStore} from "@core/components/Modal/stores";

function getTransactionPayload({transactionObject, test}) {
  return {
    transactionObject: transactionObject,
    payload: {
      testId: test._id,
      type: TRANSACTION_TYPES.TEST,
      action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].SUBMIT,
      historyData: {
        testType: test.type,
        ...(test.type === TYPES.CUSTOM && {testDisplayName: test.displayName}),
      },
      status: STATUSES.APPROVED,
    },
  };
}

export async function submitTestsHashAndDataAsync(hashesToSubmit) {
  const requests = hashesToSubmit.map(({testId, requestPayload}) => {
    return () => axios.patch(ROUTES.TRANSACTION_HASH[ACTIONS.UPDATE](testId, "tests"), requestPayload);
  });

  try {
    await Promise.any(requests.map((request) => request()));
  } catch (error) {
    NotificationStore.showError("Error while updating test hash. Please try again.");
  }
}

export function getTestsTxPayloadAndHash(tests, userId, companyId) {
  const data = tests.map((test) => {
    const dataHash = utils.computeHash(test.properties).hash;

    const testBytes32ID = ethers.utils.formatBytes32String(test._id);
    const labBytes32ID = ethers.utils.formatBytes32String(companyId);

    const data = ContractInstance.interface.encodeFunctionData("createTest", [testBytes32ID, dataHash, labBytes32ID]);

    const transactionObject = {to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data};

    return {
      transactionsPayload: getTransactionPayload({transactionObject, test}),
      hashToSubmit: {
        testId: test._id,
        requestPayload: {testHash: dataHash, stringified: JSON.stringify(test.properties)}
      }
    };
  });

  return {
    transactionsPayload: data.map(({transactionsPayload}) => transactionsPayload),
    hashesToSubmit: data.map(({hashToSubmit}) => hashToSubmit)
  };
}