import {Select} from "@core/components/Form";
import Loader from "@core/components/Loader";
import {Grid, MenuItem} from "@mui/material";
import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {isEmpty} from "ramda";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {withStyles} from "tss-react/mui";
import usePlot from "../../hooks/usePlot";
import usePlotFilter from "../../hooks/usePlotFilter";
import {PlotContextProvider} from "../../plotContext";
import {PLOT_TYPES, PLOT_TYPES_TITLES} from "../../сonstants";
import BoxPlot from "../BoxPlot";
import HistogramPlot from "../HistogramPlot";
import InfoPlot from "../InfoPlot";
import PlotSettings from "../PlotSettings";
import RadarPlot from "../RadarPlot";
import ScatterPlot from "../ScatterPlot";
import styles from "./styles";

const PLOT_COMPONENT_BY_PLOT_TYPE = {
  [PLOT_TYPES.BOX_PLOT]: BoxPlot,
  [PLOT_TYPES.VIOLIN]: BoxPlot,
  [PLOT_TYPES.HISTOGRAM]: HistogramPlot,
  [PLOT_TYPES.SCATTER]: ScatterPlot,
  [PLOT_TYPES.RADAR]: RadarPlot,
};

const TestPlot = observer(({classes, testConfig, tests}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const plot = useRef(null);
  const displayName = tests[0]?.displayName;
  const plots = testConfig.plots || testConfig.plotsByDisplayName[displayName] || [];

  const {setPlotDataLoading, plotType, changePlotType, plotData, plotDataLoading, testType} = usePlot(testConfig, tests, plots);
  const {onNewParamType} = usePlotFilter();

  const plotName = useMemo(() => PLOT_TYPES_TITLES[plotType]?.replace(/([^ Plot])$/, "$1 Plot"), [plotType]);

  const PlotComponent = PLOT_COMPONENT_BY_PLOT_TYPE[plotType] || null;

  useEffect(() => {
    const instance = plot.current;

    instance.addEventListener("fullscreenchange", onFullScreen);

    return () => instance.removeEventListener("fullscreenchange", onFullScreen);
  }, [isFullScreen]);

  const onFullScreen = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  const testIds = tests.map((test) => test._id);

  return (
    <div ref={plot}>
      <Grid
        container
        wrap="wrap"
        className={classNames({
          [classes.fullScreenContainer]: isFullScreen
        })}
        alignItems="flex-end"
        spacing={3}
      >
        <Grid item>
          <Select
            menuProps={{
              container: isFullScreen ? plot.current : document.body,
            }}
            label="Chart type"
            value={plotType}
            onChange={(e) => {
              if(plotType === e.target.value) return;

              changePlotType(e.target.value);
              onNewParamType(undefined);
            }}
          >
            {plots.map((plotType) => (
              <MenuItem key={plotType} value={plotType}>
                {PLOT_TYPES_TITLES[plotType]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <PlotSettings
          testIds={testIds}
          testType={testType}
          plotType={plotType}
          plotData={plotData}
          setPlotDataLoading={setPlotDataLoading}
          plot={plot}
          isFullScreen={isFullScreen}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className={classNames(classes.commonPlotContainer, {
          [classes.plotContainer]: !isFullScreen,
          [classes.fullScreenPlotContainer]: isFullScreen
        })}
      >
        {plotDataLoading ? (
          <Loader />
        ) : (
          <>
            {(!isEmpty(plotData) && PlotComponent) ? (
              <PlotComponent
                plotData={plotData}
                testType={testType}
                testConfig={testConfig}
                isFullScreen={isFullScreen}
              />
            ) : (
              <InfoPlot
                text={`Not enough data to generate an accurate ${plotName}`}
              />
            )}
          </>
        )}
      </Grid>
      {isFullScreen && (
        <img
          src="/images/logo-symbol-blue.svg"
          alt="Steeltrace"
          className={classes.logo}
        />
      )}
    </div>
  );
});

const TestPlotWithContext = (props) => (
  <PlotContextProvider>
    <TestPlot {...props} />
  </PlotContextProvider>
);

export default withStyles(TestPlotWithContext, styles);
