export const ACTIONS = {
  ALL: "ALL",
  SINGLE: "SINGLE",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CREATE_FROM_DRAFT: "CREATE_FROM_DRAFT",
  UPLOAD: "UPLOAD",
  CONVERT: "CONVERT",
  REGISTER: "REGISTER",
  CONVERT_MULTIPLE: "CONVERT_MULTIPLE",
  SPLIT: "SPLIT",
  SINGLE_BY_TEST_ID: "SINGLE_BY_TEST_ID",
  SINGLE_BY_CERTIFICATE_ID: "SINGLE_BY_CERTIFICATE_ID",
  REMOVE_TEST: "REMOVE_TEST",
  REMOVE_CONDITION: "REMOVE_CONDITION",
  META: "META",
  SINGLE_USERS: "SINGLE_USERS",
  SINGLE_TESTS: "SINGLE_TESTS",
  SINGLE_CERTIFICATES: "SINGLE_CERTIFICATES",
  PRODUCERS: "PRODUCERS",
  SINGLE_BY_TYPE: "SINGLE_BY_TYPE",
  TRANSFER: "TRANSFER",
  CLOSE: "CLOSE",
  OPEN: "OPEN",
  LINK: "LINK",
  UNLINK: "UNLINK",
  BULK_LINK: "BULK_LINK",
  BULK_UNLINK: "BULK_UNLINK",
  TYPES: "TYPES",
  IMPORT: "IMPORT",
  DOWNLOAD: "DOWNLOAD",
  CREATE_BULK: "CREATE_BULK",
  ALL_BY_QUERY: "ALL_BY_QUERY",
  VALIDATE: "VALIDATE",
  PARSE: "PARSE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  RESET_BC_ADDRESS: "RESET_BC_ADDRESS",
  GENERATE_TOTP: "GENERATE_TOTP",
  VALIDATE_TOTP: "VALIDATE_TOTP",
  VERIFY_TOTP: "VERIFY_TOTP",
  GET_RECOVERY_TOKEN: "GET_RECOVERY_TOKEN",
  RECOVER_PASSWORD: "RECOVER_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  INVITE_USERS: "INVITE_USERS",
  CREATE_FROM_INVITE: "CREATE_FROM_INVITE",
  RESEND_INVITE: "RESEND_INVITE",
  SELECT_PROVIDER: "SELECT_PROVIDER",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  VALIDATE_TOKEN: "VALIDATE_TOKEN",
  UPDATE_WITH_ORDER: "UPDATE_WITH_ORDER"
};