import qs from "qs";
import {toLower} from "ramda";
import React, {useState} from "react";
import {Grid} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router-dom";
import useStores from "../../../../../../useStores";
import BlockHeader from "../../../BlockHeader";
import {PAGE_TABS} from "../../constants";
import ItemsCanvas from "./components/ItemsCanvas";
import ItemsList from "./components/ItemsList";

const BundleInformation = () => {
  const [activeItem, setActiveItem] = useState(null);

  const {CampaignStore} = useStores();
  const history = useHistory();

  const campaign = CampaignStore.campaign;

  const MOCK_DATA = [{
    description: "52.6\" CARRIER PIPE",
    nominalSize: "52.6\"",
    dimensions: "1336.6 OD x 16.8 WT",
    material: "API 5L L360 MO",
    color: "#ffff00",
    radius: 0.4,
    strokeWidth: 4,
    x: 0,
    y: 0,
    shadow: true,
  },
  {
    description: "27\" SLEEVE PIPE",
    nominalSize: "27\"",
    dimensions: "686.0 OD x 13.0 WT",
    material: "API 5L L360 MO",
    color: "#fe7f00",
    radius: 0.2,
    strokeWidth: 3,
    x: 0,
    y: 0.33,
    shadow: true,
    order: campaign.purchaseOrder
  },
  {
    description: "INSULATION",
    nominalSize: "",
    dimensions: "640 OD x 35.0 WT",
    material: "35mm LDPUF INSULATION (ID OF INSULATION LINED WITH PUREFOIL, 50um)",
    color: "#a1caa8",
    radius: 0.185,
    strokeWidth: 9,
    x: 0,
    y: 0.345,
    shadow: false,
  },
  {
    description: "16\" PRODUCTION",
    nominalSize: "16.0\"",
    dimensions: "406.4 OD x 30.18 WT+2.5mm CRA LINER 316L",
    material: "DNV SMLS 485 DF-L-UNS S31603 S",
    color: "#008400",
    radius: 0.10,
    strokeWidth: 6,
    x: 0,
    y: 0.37,
    shadow: true,
  },
  {
    description: "4\" HEATING MEDIUM",
    nominalSize: "4.0\"",
    dimensions: "114.3 OD x 6.0 WT",
    material: "DNV SMLS 450-DU",
    color: "#ca02c9",
    radius: 0.03,
    strokeWidth: 2,
    x: -0.032,
    y: 0.63,
    shadow: true,
  },
  {
    description: "4\" HEATING MEDIUM",
    nominalSize: "4.0\"",
    dimensions: "114.3 OD x 6.0 WT",
    material: "DNV SMLS 450-DU",
    color: "#ca02c9",
    radius: 0.03,
    strokeWidth: 2,
    x: 0.032,
    y: 0.63,
    shadow: true,
  },
  {
    description: "10\" WATER INJECTION",
    nominalSize: "10.0\"",
    dimensions: "273.1 OD x 16.8 WT+12.0mm HDPE LINER",
    material: "DNV SMLS 485-D + HDPE (LINER)",
    color: "#027fff",
    radius: 0.085,
    strokeWidth: 4,
    x: -0.05,
    y: 0.1,
    shadow: true,
    order: campaign.purchaseOrder
  },
  {
    description: "7.6\" GAS LIFT",
    nominalSize: "7.6\"",
    dimensions: "193.7 OD x 14.7 WT",
    material: "DNV SMLS 450-SDF",
    color: "#894301",
    radius: 0.06,
    strokeWidth: 4,
    x: 0.15,
    y: 0.18,
    shadow: true,
  },
  {
    description: "3\" MEG",
    nominalSize: "3\"",
    dimensions: "88.9 OD x 5.49 WT",
    material: "DNV SMLS 25CR-SD",
    color: "#06b0ae",
    radius: 0.02,
    strokeWidth: 2,
    x: -0.2,
    y: 0.19,
    shadow: true,
  }];

  const onItemClick = () => {
    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        tab: toLower(PAGE_TABS.PIPELINE),
        stalkNumber: `0${activeItem + 1}`
      }),
    });
  };

  return (
    <Grid container>
      <Grid item xs={5}>
        <Grid container>
          <Grid item xs={12} container justifyContent="center">
            <BlockHeader>
              North Bundle Design
            </BlockHeader>
          </Grid>
          <Grid item xs={12}>
            <ItemsCanvas
              items={MOCK_DATA}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              onItemClick={onItemClick}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <ItemsList
          items={MOCK_DATA}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          onItemClick={onItemClick}
        />
      </Grid>
    </Grid>
  );
};

export default observer(BundleInformation);