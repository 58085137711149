import {useFormikContext} from "formik";
import {keys, path, uniq} from "ramda";
import React from "react";
import {Typography, Box} from "@mui/material";
import {flattenObject} from "@core/helpers";

const ValidationErrors = ({container: Container}) => {
  const {errors, touched} = useFormikContext();

  const validationErrorsByKey = flattenObject(errors);
  const touchedValidationKeys = keys(validationErrorsByKey).filter((errorKey) => path(errorKey.split("."), touched));
  const validationErrors = uniq(touchedValidationKeys.map((key) => validationErrorsByKey[key])).filter((error) => error);

  if(!validationErrors.length) return null;

  return (
    <Container>
      <Box sx={{marginLeft: 2}}>
        <ul>
          {validationErrors.map((errorMessage) => (
            <li key={errorMessage}>
              <Typography variant="subtitle1">{errorMessage}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Container>
  );
};

export default ValidationErrors;