import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import MuiSelect from "@core/components/MuiSelect";
import {FilesUploader} from "@core/components/Uploaders";
import {TEST_RESULTS} from "@core/constants/testResults";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import {Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem} from "@mui/material";
import {FieldArray, Formik} from "formik";
import {keys} from "ramda";
import React, {useEffect, useState} from "react";
import {withStyles} from "tss-react/mui";
import * as yup from "yup";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import {
  ACCEPTANCE_CRITERIA,
  EVALUATION_OF_CRAKS,
  STRESS_METHODS,
  TEST_STANDARDS,
  YEILD_STRENGTH_TYPES
} from "./data";
import styles from "./styles";

const validationSchema = yup.object().shape({
  client: yup.string().required("Client is required!"),
  lab: yup.string().required("Laboratory is required!"),
  testStandard: yup.string().required("Test standard is required!"),
  acceptance: yup.string().required("Acceptance criteria is required!"),
  solution: yup.string().required("Test solution is required!"),
  duration: yup.string().required("Test duration is required!"),
  temperature: yup.string().required("Test temperature is required!"),
  temperatureTolerance: yup.string().required("Temperature tolerance is required!"),
  pressure: yup.string().required("Test pressure is required!"),
  gasMixture: yup.string(),
  solutionPH: yup.object().shape({
    before: yup.string().required("Before H2S introduction solution PH is required!"),
    after: yup.string().required("After H2S introduction solution PH is required!"),
    final: yup.string().required("Final solution PH is required!")
  }),
  h2sConcentration: yup.object().shape({
    initial: yup.string().required("Initial H2S concentration is required!"),
    final: yup.string().required("Final H2S concentration is required!")
  }),
  stressMethod: yup.string().required("Stress method is required!"),
  yeildStrengthType: yup.string().required("Yield strength type is required!"),
  yeildStrengthValue: yup.string().required("Yield strength value is required!"),
  yeildStrengthPercentage: yup.number().min(0, "Should be \u2265 0").max(100, "Should be \u2266 100").required("Yield strength percentage is required!"),
  appliedLoad: yup.string(),
  elements: yup.array().of(yup.object().shape({
    controlSample: yup.boolean(),
    position: yup.string().required("Position is required!"),
    orientation: yup.string().required("Orientation is required!"),
    specimenId: yup.string().required("Specimen ID is required!"),
    specimenLength: yup.string().required("Specimen length is required!"),
    specimenWidth: yup.string().required("Specimen width is required!"),
    specimenThickness: yup.string().required("Specimen thickness is required!"),
    magnification: yup.string(),
    evaluationOfCracks: yup.string().required("Evaluation of cracks is required!")
  })),
  notes: yup.string()
});

const ELEMENT = {
  position: "",
  orientation: "",
  specimenId: "",
  specimenLength: "",
  specimenWidth: "",
  specimenThickness: "",
  magnification: "",
  evaluationOfCracks: "",
  files: [],
  controlSample: false,
};

const isTestAcceptable = (elements) => elements.every((element) => {
  return element.controlSample || element.evaluationOfCracks === "Absent (PASS)";
});

const getResult = (isAcceptable) => {
  if (isAcceptable) return TEST_RESULTS.ACCEPTABLE;

  return TEST_RESULTS.NOT_ACCEPTABLE;
};

const getAppliedLoad = (yeildStrength, yeildStrengthPercentage) => (yeildStrength * (yeildStrengthPercentage / 100)).toFixed(1);

const SulphideStressCorrosionCracking = ({
  test,
  user,
  saveTest,
  client,
  certificate,
  formRef,
}) => {
  const [tensileNorms, setTensileNorms] = useState({});

  const testStandard = certificate?.properties?.testStandard;
  const grade = certificate?.properties?.grade;

  useFetchTestNorms("tensile_test", testStandard, setTensileNorms);

  const materialSpecifications = tensileNorms[grade] || {};

  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name || "",
    testStandard: "",
    acceptance: "",
    solution: "",
    duration: "",
    temperature: "",
    temperatureTolerance: "",
    pressure: "",
    gasMixture: "",
    solutionPH: {
      before: "",
      after: "",
      final: ""
    },
    h2sConcentration: {
      initial: "",
      final: ""
    },
    stressMethod: "",
    yeildStrengthType: "",
    yeildStrengthValue: "",
    yeildStrengthPercentage: "",
    appliedLoad: "",
    elements: [ELEMENT],
    notes: "",
    result: ""
  };

  const filteredAcceptances= Object.keys(ACCEPTANCE_CRITERIA).reduce(function(r, e) {
    if (!ACCEPTANCE_CRITERIA[e].hasOwnProperty("company") || ACCEPTANCE_CRITERIA[e].company.includes(user.company.name)) r[e] = ACCEPTANCE_CRITERIA[e];

    return r;
  }, {});

  const onSubmit = (values) => {
    const isAcceptable = isTestAcceptable(values.elements);
    const result = getResult(isAcceptable);
    saveTest({...values, result});
  };

  return (
    <Formik
      validateOnMount
      onSubmit={onSubmit}
      innerRef={formRef}
      initialValues={{...initialValues, ...test.properties}}
      validationSchema={validationSchema}
      enableReinitialize
      render={(props) => {

        const isAcceptable = props.isValid && isTestAcceptable(props.values.elements);
        const result = getResult(isAcceptable);

        useEffect(() => {
          if (props.values.yeildStrengthValue) return;

          if (props.values.yeildStrengthType === "Specified") {
            props.setFieldValue("yeildStrengthValue", materialSpecifications.YELDmin);
          }
        }, [props.values.yeildStrengthType]);

        useEffect(() => {
          props.setFieldValue("appliedLoad", getAppliedLoad(props.values.yeildStrengthValue, props.values.yeildStrengthPercentage));
        }, [props.values.yeildStrengthPercentage, props.values.yeildStrengthValue]);

        useEffect(() => {
          if (!props.touched.acceptance) return;

          const values = ACCEPTANCE_CRITERIA[props.values.acceptance] || {};
          const {magnification, stressMethod, solution, duration, yeildStrengthType, yeildStrengthPercentage} = values;

          const newElements = props.values.elements.map((element) => ({
            ...element,
            magnification: magnification || "",
          }));

          props.setValues(() => ({
            ...props.values,
            stressMethod: stressMethod || "",
            solution: solution || "",
            duration: duration || "",
            yeildStrengthType: yeildStrengthType || "",
            yeildStrengthPercentage: yeildStrengthPercentage || "",
            elements: newElements
          }));
        }, [props.values.acceptance]);

        return (
          <Grid container spacing={2}>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <ClientField isFromProducer={!!client.name} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  name="lab"
                  label="Laboratory"
                  required
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={TEST_STANDARDS}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiSelect
                  required
                  label="Acceptance criteria"
                  name="acceptance"
                  defaultOptions={keys(filteredAcceptances)}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  name="solution"
                  label="Test solution"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="duration"
                  label="Test duration"
                  required
                  endAdornment="hrs"
                />
              </Grid>
              <Grid item container xs={4} alignItems="flex-end" spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    name="temperature"
                    label="Test temperature"
                    required
                    endAdornment="°C"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    startAdornment="+/-"
                    name="temperatureTolerance"
                    required
                    endAdornment="°C"
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="pressure"
                  label="Test pressure"
                  required
                  endAdornment="bar"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="gasMixture"
                  label="Gas Mixture"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item>
                <h3>Solution pH</h3>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="solutionPH.before"
                  label="Before H2S introduction"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="solutionPH.after"
                  label="After H2S introduction"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="solutionPH.final"
                  label="Final"
                  required
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item>
                <h3>H2S concentration</h3>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="h2sConcentration.initial"
                  label="Initial"
                  required
                  endAdornment="ppm"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="h2sConcentration.final"
                  label="Final"
                  required
                  endAdornment="ppm"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={6}>
                <SelectField
                  name="stressMethod"
                  label="Stress method"
                  required
                >
                  {STRESS_METHODS.map((sm) => (
                    <MenuItem key={sm} value={sm}>{sm}</MenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <SelectField
                  name="yeildStrengthType"
                  label="Yield strength type"
                  required
                >
                  {YEILD_STRENGTH_TYPES.map((ys) => (
                    <MenuItem key={ys} value={ys}>{ys}</MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="yeildStrengthValue"
                  label="Yield strength value"
                  required
                  endAdornment="MPa"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="yeildStrengthPercentage"
                  label="Yield strength percentage"
                  required
                  endAdornment="%"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  type="number"
                  name="appliedLoad"
                  label="Applied stress"
                  endAdornment="MPa"
                />
              </Grid>
            </Grid>
            <FieldArray name="elements">
              {({remove, push}) => props.values.elements.map((element, index) => (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.position`}
                        label="Position"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.orientation`}
                        label="Orientation"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenId`}
                        label="Specimen ID"
                        required
                      />
                    </Grid>
                    {props.values.elements.length > 1 && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                    {props.values.elements.length - 1 === index && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          onClick={() => {
                            const values = ACCEPTANCE_CRITERIA[props.values.acceptance] || {};
                            push({...ELEMENT, magnification: values.magnification});
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenLength`}
                        label="Specimen Length"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenWidth`}
                        label="Specimen Width"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenThickness`}
                        label="Specimen Thickness"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={element.controlSample}
                            onClick={(event) => props.setFieldValue(`elements[${index}].controlSample`, event.target.checked)}
                            name={`elements.${index}.controlSample`}
                            color="primary"
                          />
                        )}
                        label="Control Sample"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={12}>
                      <FilesUploader
                        name={`elements.${index}.files`}
                        files={element.files || []}
                        onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                        changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5}>
                    <Grid item>
                      <h3>Test results</h3>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3} container alignItems="flex-end">
                      <Grid item xs>
                        <TextField
                          type="number"
                          name={`elements.${index}.magnification`}
                          label="Magnification"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <SelectField
                        name={`elements.${index}.evaluationOfCracks`}
                        label="Evaluation of cracks"
                        required
                      >
                        {EVALUATION_OF_CRAKS.map((ec) => (
                          <MenuItem key={ec} value={ec}>{ec}</MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>
                </>
              ))}
            </FieldArray>
            <Grid item container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  rows={4}
                  multiline
                  name="notes"
                  label="Additional remarks"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TestFooter
                onSubmit={onSubmit}
                result={result}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default withStyles(SulphideStressCorrosionCracking, styles);
