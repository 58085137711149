import {Typography} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import UploadsPage from "@core/components/Uploads";
import {useParams} from "react-router-dom";
import useStores from "../../../../useStores";
import {TABS, TABS_TITLES} from "../../constants";

const Uploads = ({isLoaded}) => {
  const {SingleMaterialCampaignStore} = useStores();

  const {id} = useParams();

  const {attachments = []} = SingleMaterialCampaignStore.campaign;

  return (
    <UploadsPage
      label={(
        <Typography variant="h6">
          {TABS_TITLES[TABS.UPLOADS]}
        </Typography>
      )}
      attachments={attachments}
      isLoaded={isLoaded}
      onCreateFile={(attachments) => SingleMaterialCampaignStore.updateCampaignById(id, {attachments})}
      onUpdateFile={(attachments) => SingleMaterialCampaignStore.updateCampaign({attachments})}
      onRemoveFile={(attachments) => SingleMaterialCampaignStore.updateCampaignById(id, {attachments})}
    />
  );
};

export default observer(Uploads);