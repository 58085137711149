// TODO upgrade ethers package to fix the "ws" package vulnerability workaround. see package.json comments!
import { ethers } from 'ethers';
import CertificateStore from "../Certificates/stores/certificate";
import TestStore from "../Tests/stores/test";
import {TRANSACTION_TYPES} from "@core/constants/transactions";
import ContractInstance from "./contract-instance";


class CertificatesService {

  /**
     * @name addCertificateTX
     * @param {object} certificate - certificate that will be added
     * @param {string} dataHash data hash
     * @param {array} testIds - Optional parameter. When creating a certificate, users might add already existing tests that are approved
     * @summary Generates the transaction object that is adding new certificate. Should be passed as QR information to the Signing App.
     */
  static addCertificateTX(certificate, dataHash, testIds = [], type = TRANSACTION_TYPES.CERTIFICATE) {
    const certificateBytes32ID = ethers.utils.formatBytes32String(certificate._id);
    for (let i = 0; i < testIds.length; i++) {
      testIds[i] = ethers.utils.formatBytes32String(testIds[i]);
    }

    const data = ContractInstance.interface.encodeFunctionData("addCertificate", [certificateBytes32ID, dataHash, testIds]);

    if(certificate.transactions && type === TRANSACTION_TYPES.CERTIFICATE) {
      CertificateStore.updateHash(certificate._id, dataHash, certificate);
    }

    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
   * @name updateCertificateTX
   * @param {object} certificate - certificate that will be added
   * @param {string} dataHash data hash
   * @summary Generates the transaction object that is adding new certificate. Should be passed as QR information to the Signing App.
   */
  static updateCertificateTX(certificate, dataHash, type = TRANSACTION_TYPES.CERTIFICATE) {
    const certificateBytes32ID = ethers.utils.formatBytes32String(certificate._id);

    const data = ContractInstance.interface.encodeFunctionData("updateCertificate", [certificateBytes32ID, dataHash]);

    if(certificate.transactions && type === TRANSACTION_TYPES.CERTIFICATE) {
      CertificateStore.updateHash(certificate._id, dataHash, certificate);
    }

    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @name addTestToCertificate
     * @param {string} certificateId - ID of the certificate that will be added
     * @param {string} testId - ID of already approved test that will be linked to the already existing certificate
     * @summary Generates the transaction object that is adding the test to already existing certificate. Should be passed as QR information to the Signing App.
     */
  static addTestToCertificateTX(certificateId, testId) {
    const certificateBytes32ID = ethers.utils.formatBytes32String(certificateId);
    const testBytes32ID = ethers.utils.formatBytes32String(testId);

    const data = ContractInstance.interface.encodeFunctionData("addTestToCertificate", [certificateBytes32ID, testBytes32ID]);
    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @name createNewTestTX
     * @param {string} certificateId - ID of the certificate
     * @param {object} test - test that will be created
     * @param {string} dataHash - Hash of the document/data for the test. The hash is computed beforehand
     * @param {string} assignedCompany - ID of the company that will be assigned to perform the test.
     * If the assigned company is the same as the company that the user is from the test will be automatically approved.
     */
  static createNewTestTX(certificateId, test, dataHash, assignedCompany) {
    const certificateBytes32ID = ethers.utils.formatBytes32String(certificateId);
    const testBytes32ID = ethers.utils.formatBytes32String(test._id);
    const assignedCompanyBytes32 = ethers.utils.formatBytes32String(assignedCompany);

    const data = ContractInstance.interface.encodeFunctionData("assignTest", [certificateBytes32ID, testBytes32ID, dataHash, assignedCompanyBytes32]);

    TestStore.updateHash(test._id, dataHash, test.properties);
    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
   * @name createNewTestFromLabTX
   * @param {object} test - the test that will be created
   * @param {string} dataHash - Hash of the document/data for the test. The hash is computed beforehand
   * @param {string} labId - ID of the lab creating the test
   * @returns {{data: string, to: *}}
   */
  static createNewTestFromLabTX(test, dataHash, labId) {
    const testBytes32ID = ethers.utils.formatBytes32String(test._id);
    const labBytes32ID = ethers.utils.formatBytes32String(labId);

    const data = ContractInstance.interface.encodeFunctionData("createTest", [testBytes32ID, dataHash, labBytes32ID]);

    TestStore.updateHash(test._id, dataHash, test.properties);

    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @name submitTestResultsTX
     * @param {object} test - test for which results will be submitted
     * @param {string} dataHash - Hash of the document/data that will be set. The hash is computed beforehand.
     */
  static submitTestResultsTX(test, dataHash) {
    const testBytes32ID = ethers.utils.formatBytes32String(test._id);
    const data = ContractInstance.interface.encodeFunctionData("submitTest", [testBytes32ID, dataHash]);

    TestStore.updateHash(test._id, dataHash, test.properties);

    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @name approveTestResultsTX
     * @param {object} test - test for which results will be approved
     * @param {string} dataHash - Hash of the document/data that will be set. The hash is computed beforehand.
     */
  static approveTestResultsTX(test, dataHash) {
    const testBytes32ID = ethers.utils.formatBytes32String(test._id);
    const data = ContractInstance.interface.encodeFunctionData("approveTest", [testBytes32ID]);

   TestStore.updateHash(test._id, dataHash, test.properties);

    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @param {string} certificateId - ID of the certificate that will be transferred
     * @param {string} newCompanyOwner - ID of the company to which the certificate will be transferred
     */
  static transferCertificate(certificateId, newCompanyOwner) {
    const certificateBytes32ID = ethers.utils.formatBytes32String(certificateId);
    const companyBytes32ID = ethers.utils.formatBytes32String(newCompanyOwner);

    const data = ContractInstance.interface.encodeFunctionData("transferCertificate", [certificateBytes32ID, companyBytes32ID]);
    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @param {string} testId - ID of the test for which results will be reviewed
     * @param {array} inspectingCompanies - array of companyIds that would be added to review the test result
     */
  static addInspectingCompanies(testId, inspectingCompanies) {
    const testBytes32ID = ethers.utils.formatBytes32String(testId);
    const testBytes32Companies = inspectingCompanies.map(comp => ethers.utils.formatBytes32String(comp));
    const data = ContractInstance.interface.encodeFunctionData("addInspectingCompanies", [testBytes32ID, testBytes32Companies]);
    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @param {object} test - test for which results will be reviewed
     * @param {number} status - status of the attestation. Possible values: 1 - Approved; 2 - Declined; 3 - Require changes
     * @param {string} dataHash - hash of the attestation from the inspector
     */
  static attest(test, status, dataHash) {
    const testBytes32ID = ethers.utils.formatBytes32String(test._id);
    const data = ContractInstance.interface.encodeFunctionData("attest", [testBytes32ID, status, dataHash]);

    TestStore.updateHash(test._id, dataHash, test.properties);

    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

  /**
     * @param {string} testId - ID of the test for which results will be reviewed
     * @param {string} dataHash - hash of the attestation from the inspector
     */
  static approveAttestation(testId, dataHash) {
    const testBytes32ID = ethers.utils.formatBytes32String(testId);
    const approvedStatus = 1;
    const data = ContractInstance.interface.encodeFunctionData("attest", [testBytes32ID, approvedStatus, dataHash]);
    return { to: process.env.REACT_APP_CERTIFICATES_CONTRACT_ADDRESS, data };
  }

}

export default CertificatesService;
