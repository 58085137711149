import TextField from "@core/components/FormikTextField";
import Footer from "@core/components/TestForms/ErosionTest/components/Footer";
import {TEST_RESULTS} from "@core/constants/testResults";
import {getIsWithinRequirements} from "@core/helpers";
import {omit} from "ramda";
import React from "react";
import {Grid, Typography} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import ClientField from "../../../../Tests/Test/components/ClientField";
import {TEST_STANDARDS, DEFAULT_TEST_RESULTS} from "./constants";
import MuiSelect from "@core/components/MuiSelect";
import TestParameters from "./components/TestParameters";
import TestResults from "./components/TestResults";

const PalmqvistTest = ({test, closeNewTest, saveTest, formRef, isProducerTest, client: owner, user, editable = true}) => {

  const {witnesses, inspectorJobNumber, inspectionDate, properties} = test ?? {};

  const {
    client,
    laboratory,
    testStandard,
    acceptanceCriteria,
    temperature,
    indentationLoad,
    palmqvist,
    palmqvistUnit,
    stressIntensityFactorUnit,
    surfacePreparation,
    testResults,
    tags,
    testExecutionDate
  } = properties ?? {};

  const validationSchema = yup.object().shape({
    client: isProducerTest ? yup.string() : yup.string().required("Client is required!"),
    laboratory: isProducerTest ? yup.string() : yup.string().required("Laboratory is required!"),
    testStandard: yup.string().required("Test standard is required!"),
    acceptanceCriteria: yup.object().shape({
      min: yup.number().min(0, "Min should be > 0"),
      max: yup.number().test("max", "max", function (value) {
        return !value || value >= this.parent.min || 0;
      }),
    }),
    temperature: yup.number().min(0, "Temperature should be > 0").required("Temperature is required!"),
    indentationLoad: yup.number().min(0, "Indentation load should be > 0").required("Indentation load is required!"),
    palmqvist: yup.string().required("Palmqvist is required!"),
    palmqvistUnit: yup.string().required("Palmqvist unit is required!"),
    stressIntensityFactorUnit: yup.string().required("Stress intensity factor unit is required!"),
    surfacePreparation: yup.string(),
    testResults: yup.array().of(yup.object().shape({
      specimen: yup.string().required("Specimen ID is required!"),
      location: yup.string(),
      position: yup.string(),
      orientation: yup.string(),
      direction: yup.string().required("Direction is required!"),
      palmqvist: yup.number().min(0, "Palmqvist should be > 0").required("Palmqvist is required!"),
      stressIntensityFactor: yup.number().min(0, "Stress intensity factor should be > 0").required("Stress intensity factor is required!"),
    })),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const initialValues = {
    client: !isProducerTest ? client ?? owner?.name ?? "" : "",
    laboratory: !isProducerTest ? laboratory ?? user?.company.name ?? "" : "",
    testStandard: testStandard ?? TEST_STANDARDS[0],
    acceptanceCriteria: acceptanceCriteria ?? {
      min: undefined,
      max: undefined,
    },
    temperature: temperature ?? undefined,
    indentationLoad: indentationLoad ?? undefined,
    palmqvist: palmqvist ?? "",
    palmqvistUnit: palmqvistUnit ?? "",
    stressIntensityFactorUnit: stressIntensityFactorUnit ?? "",
    surfacePreparation: surfacePreparation ?? "",
    testResults: testResults ?? DEFAULT_TEST_RESULTS,
    witnesses: witnesses.map((witness) => witness.company) ?? [],
    inspectionDate: inspectionDate ?? "",
    inspectorJobNumber: inspectorJobNumber ?? "",
    tags: tags ?? [],
    testExecutionDate: testExecutionDate ?? ""
  };

  const getResult = ({acceptanceCriteria, testResults}) => {
    const isAcceptable = testResults.every((result) => getIsWithinRequirements(result.stressIntensityFactor, acceptanceCriteria.min, acceptanceCriteria.max));

    return isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
  };

  const onSubmit = (values, actions) => {
    values.result = getResult(values);

    const formData = omit(["witnesses"], values);

    saveTest(formData, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    closeNewTest && closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({values}) => {
        return (
          <>
            <Grid container spacing={3} sx={{maxWidth: "1200px"}}>
              {isProducerTest && (
                <Grid item xs={12}>
                  <h1>Palmqvist Test</h1>
                </Grid>
              )}
              {editable && (
                <Grid item container spacing={3}>
                  {!isProducerTest && (
                    <>
                      <Grid item xs={3}>
                        <ClientField
                          isFromProducer={!!owner.name}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          name="laboratory"
                          label="Laboratory"
                          required
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={3}>
                    <MuiSelect
                      required
                      name="testStandard"
                      defaultOptions={TEST_STANDARDS}
                      label="Test standard"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={6}>
                <TestParameters
                  editable={editable}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" marginBottom={1}>Test results</Typography>
                <TestResults
                  editable={editable}
                />
              </Grid>
            </Grid>
            <Footer
              editable={editable}
              isProducerTest={isProducerTest}
              saveTest={saveTest}
              result={getResult(values)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default PalmqvistTest;