import ProducerTestFooter from "@core/components/ProducerTestFooter";
import React from "react";
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {FieldArray, Formik} from "formik";
import * as yup from "yup";
import {Input} from "@core/components/Form";
import styles from "./styles";

const TreatmentTest = ({classes, isRelatedToCondition, ...props}) => {
  const validationSchema = yup.object().shape({
    elements: yup.array().of(yup.object().shape({
      treatment: yup.string().required("Treatment is required"),
      description: yup.string().required("Description is required"),
    })).required(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const initialValues = {
    elements: props.test.properties.elements || [
      {
        treatment: "",
        description: ""
      }
    ],
    witnesses: props.test.witnesses.map((witness) => witness.company) || [],
    inspectionDate: props.test.inspectionDate || "",
    inspectorJobNumber: props.test.inspectorJobNumber || "",
    tags: props.test.properties.tags || [],
    testExecutionDate: props.test.properties.testExecutionDate || "",
  };

  const onSubmit = (values, actions) => {
    const data = {
      elements: values.elements,
      inspectionDate: values.inspectionDate,
      inspectorJobNumber: values.inspectorJobNumber,
      tags: values.tags,
      testExecutionDate: values.testExecutionDate,
    };

    props.saveTest(data, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    if (props.closeNewTest) {
      props.closeNewTest();
    }
  };

  return (
    <div>
      <Formik
        innerRef={props.formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}

        render={(props) => {
          const {
            values: {elements},
            isValid,
            handleSubmit,
            handleChange,
            setFieldTouched,
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          return (
            <>
              <h1>Treatment</h1>
              <Grid container className={classes.gridRoot} spacing={5}>
                <Grid item xs={12}>
                  <FieldArray
                    name="elements"
                  >
                    {({remove, push}) => {

                      return (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell size='small'>Treatment</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          {elements && elements.length > 0 ? (
                            <TableBody>
                              {elements.map((element, index) => (
                                <TableRow>
                                  <TableCell>
                                    <Input
                                      name={`elements.${index}.treatment`}
                                      value={element.treatment}
                                      onChange={(e) => change(`elements.${index}.treatment`, e)} />
                                  </TableCell>
                                  <TableCell>
                                    <Input
                                      name={`elements.${index}.description`}
                                      value={element.description}
                                      onChange={(e) => change(`elements.${index}.description`, e)} />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : null
                          }
                          <TableFooter>
                            <TableRow>
                              <TableCell colSpan={3} className={classes.btnAddCell}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.btnAdd}
                                  onClick={() => push({treatment: "", description: ""})}
                                >
                                  Add
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      );
                    }}
                  </FieldArray>
                </Grid>
              </Grid>
              {isRelatedToCondition ? (
                <Grid container className={classes.gridRoot} justifyContent="flex-end" alignItems="flex-end">
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={!isValid}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <ProducerTestFooter />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default withStyles(TreatmentTest, styles);
