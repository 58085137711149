import {Box, Typography} from "@mui/material";
import React from "react";

const Description = ({title, text}) => {
  return (
    <Box sx={{
      maxWidth: 500,
      paddingRight: (theme) => theme.spacing(9)
    }}>
      <Typography variant="h5" gutterBottom>{title}</Typography>
      <Typography variant="body2" color="textSecondary" marginTop={3} gutterBottom>
        {text}
      </Typography>
    </Box>
  );
};

export default Description;