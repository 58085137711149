import {Tooltip, Chip, Box} from "@mui/material";
import React from "react";
import {indexBy, keys, prop} from "ramda";

const DimensionsTags = ({dimensions, properties = []}) => {

  const propertyByName = indexBy(prop("name"), properties);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {keys(dimensions).map((name) => {
        if(!name) return null;

        const property = propertyByName[name] || {};
        const measurements = property.measurements?.toLowerCase() || "";

        const label = property.label || name;
        const labelAcronym = label.match(/\b\w/g).join("").toUpperCase();

        return (
          <Tooltip title={label}>
            <Chip
              sx={{cursor: "pointer", marginRight: 1}}
              label={`${labelAcronym}: ${dimensions[name]} ${measurements}`}
              size="small"
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default DimensionsTags;