import DimensionsTags from "@core/components/DimensionsTags";
import {Table, TableBody, TableCell, TableHead, TableRow, useTheme} from "@mui/material";
import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {omit} from "ramda";
import useStores from "../../../../../../useStores";

const CampaignProducts = () => {
  const {SingleMaterialCampaignStore} = useStores();

  const {nominalSizes = [], productType, materialSpecifications = []} = SingleMaterialCampaignStore.campaign.properties?.product || {};
  const properties = productType?.properties || [];

  const gradesNumber = materialSpecifications.reduce((number, materialSpecification) => number + materialSpecification.grades.length, 1);

  const theme = useTheme();

  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Product type</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Dimensions</TableCell>
          <TableCell>Material Specification</TableCell>
          <TableCell>Grade(s)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {nominalSizes.map((nominalSize, index) => {
          const isLast = index === nominalSizes.length - 1;
          const styles = isLast ? {borderBottom: "none"} : {};

          return (
            <Fragment key={index}>
              <TableRow>
                <TableCell
                  rowSpan={gradesNumber}
                  style={styles}
                >
                  {productType.name}
                </TableCell>
                <TableCell
                  rowSpan={gradesNumber}
                  style={styles}
                >
                  {nominalSize.quantity}
                </TableCell>
                <TableCell
                  rowSpan={gradesNumber}
                  style={{
                    borderRight: `1px solid ${theme.palette.divider}`,
                    ...styles,
                  }}
                >
                  <DimensionsTags
                    dimensions={omit(["quantity"], nominalSize)}
                    properties={properties}
                  />
                </TableCell>
              </TableRow>
              {materialSpecifications.map((materialSpecification) => materialSpecification.grades.map((grade, index) => (
                <TableRow key={grade}>
                  <TableCell>{!index ? materialSpecification.norm : null}</TableCell>
                  <TableCell>{grade}</TableCell>
                </TableRow>
              )))}
            </Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default observer(CampaignProducts);