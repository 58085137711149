import {TEST_RESULTS} from "@core/constants/testResults";
import React, {useEffect} from "react";
import {FieldArray, Formik} from "formik";
import * as yup from "yup";
import {keys} from "ramda";
import {Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem} from "@mui/material";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import ClientField from "../../../../Tests/Test/components/ClientField";
import {
  TEST_STANDARDS,
  WELD_POSITIONS,
  DEFORMATION_PER_UNIT,
  SPECIMEN_INPUT_LENGTH_REQUIREMENTS_BY_TEST_STANDARD,
} from "./data";
import TestFooter from "../../LabTestFooter";
import MuiSelect from "@core/components/MuiSelect";

const validationSchema = yup.object().shape({
  client: yup.string().required("Client is required!"),
  lab: yup.string().required("Laboratory is required!"),
  testStandard: yup.string().required("Test standard is required!"),
  acceptance: yup.string().required("Acceptance criteria is required!"),
  outsideDiameter: yup.string().required("Outside diameter is required!"),
  internalDiameter: yup.string(),
  thickness: yup.string().required("Thickness is required!"),
  reverseFlattening: yup.boolean(),
  deformationPerUnitLength: yup.string().when("testStandard", {
    is: (testStandard) => testStandard && testStandard.includes("ASTM A999"),
    then: yup.string().required("Deformation per unit length is required!")
  }),
  firstStep: yup.string().when("testStandard", {
    is: (testStandard) => testStandard && testStandard.includes("ASTM A999"),
    then: yup.string().required("First step is required!")
  }),
  secondStep: yup.string().when("testStandard", {
    is: (testStandard) => testStandard && testStandard.includes("ASTM A999"),
    then: yup.string().required("Second step is required!")
  }),
  elements: yup.array().of(yup.object().shape({
    specimenId: yup.string().required("Specimen ID is required!"),
    specimenLength: yup.string().test("specimenLength", "specimenLength", function(value) {
      const [, parent] = this.from;
      const inputedStandard = parent.value.testStandard || "";
      const testStandard = keys(SPECIMEN_INPUT_LENGTH_REQUIREMENTS_BY_TEST_STANDARD).find((ts) => inputedStandard.includes(ts)) || "";
      const {min, max} = SPECIMEN_INPUT_LENGTH_REQUIREMENTS_BY_TEST_STANDARD[testStandard] || {};
      const specimenLength = Number(value);
      let errorMessage = "";

      if (!value) errorMessage = "Specimen length is required!";

      if (min && specimenLength < min) errorMessage = `Specimen length input shall be >= ${min}`;

      if (max && specimenLength > max) errorMessage = `Specimen length input shall be <= ${max}`;

      if (errorMessage) return this.createError({message: errorMessage, path: this.path});

      return true;
    }),
    weldPosition: yup.string(),
    finalDistanceOfPlates: yup.string().required("Final distance of plates is required!")
  })).required(),
  result: yup.string().required("Result is required!")
});

const ELEMENT = {
  specimenId: "",
  specimenLength: "",
  weldPosition: "",
  finalDistanceOfPlates: ""
};

const FlatteningTest = ({formRef, test, client, user, certificate, saveTest}) => {
  const certificateItems = certificate.items || [];
  const [firstItem] = certificateItems;

  const predefinedOutsideDiameter = firstItem && firstItem.outerDiameter ? firstItem.outerDiameter.replace(/,/g, ".") : "";
  const predefinedThickness = firstItem && firstItem.wallThickness? firstItem.wallThickness.replace(/,/g, ".") : "";

  const initialValues = {
    client: client.name || "",
    lab: user.company.name || "",
    testStandard: "",
    acceptance: "",
    outsideDiameter: predefinedOutsideDiameter,
    internalDiameter: "",
    thickness: predefinedThickness,
    reverseFlattening: false,
    deformationPerUnitLength: "",
    firstStep: "",
    secondStep: "",
    elements: [ELEMENT],
    result: ""
  };

  return (
    <Formik
      validateOnMount
      innerRef={formRef}
      initialValues={{...initialValues, ...test.properties}}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props) => {

        useEffect(() => {
          if(!props.touched.internalDiameter && !props.touched.outsideDiameter) return;

          if (!props.values.internalDiameter || !props.values.outsideDiameter) {
            props.setFieldValue("thickness", "");
          } else {
            const outsideDiameter = Number(props.values.outsideDiameter);
            const internalDiameter = Number(props.values.internalDiameter);

            const thickness = (outsideDiameter - internalDiameter) / 2;
            props.setFieldValue("thickness", thickness.toFixed(2));
          }
        }, [props.values.outsideDiameter, props.values.internalDiameter]);

        useEffect(() => {
          const deformationPerUnitLength = props.values.deformationPerUnitLength || 0;
          const thickness = props.values.thickness ? Number(props.values.thickness) : 0;
          const outsideDiameter = props.values.outsideDiameter ? Number(props.values.outsideDiameter) : 0;

          const firstStep = (1 + deformationPerUnitLength) * thickness / (deformationPerUnitLength + thickness / outsideDiameter);
          props.setFieldValue("firstStep", firstStep.toFixed(2));
        }, [props.values.deformationPerUnitLength, props.values.thickness, props.values.outsideDiameter]);

        useEffect(() => {
          if(!props.touched.testStandard) return;

          props.setFieldValue("reverseFlattening", false);
          props.setFieldValue("deformationPerUnitLength", "");
          props.setFieldValue("firstStep", "");

          if(props.values.testStandard.includes("ASTM A999")) {
            props.setFieldValue("secondStep", "Until the opposite walls of the pipe meet");
          }
          else props.setFieldValue("secondStep", "");
        }, [props.values.testStandard]);

        return (
          <>
            <Grid container spacing={2}>
              <Grid item container spacing={5}>
                <Grid item xs={3}>
                  <ClientField isFromProducer={!!client.name} />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="lab"
                    label="Laboratory"
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={3}>
                  <MuiSelect
                    required
                    label="Test standard"
                    name="testStandard"
                    defaultOptions={TEST_STANDARDS}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Acceptance criteria"
                    name="acceptance"
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5} alignItems="flex-end">
                <Grid item xs={3}>
                  <TextField
                    label="Outside diameter"
                    name="outsideDiameter"
                    required
                    endAdornment="MM"
                    type="number"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Internal diameter"
                    name="internalDiameter"
                    endAdornment="MM"
                    type="number"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Thickness"
                    name="thickness"
                    required
                    disabled={props.values.internalDiameter && props.values.outsideDiameter}
                    endAdornment="MM"
                    type="number"
                  />
                </Grid>
                {props.values.testStandard.includes("ASTM A370") && (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={props.values.reverseFlattening}
                          onClick={(event) => props.setFieldValue("reverseFlattening", event.target.checked)}
                          name="reverseFlattening"
                          color="primary"
                        />
                      )}
                      label="Reverse flattening"
                    />
                  </Grid>
                )}
                {props.values.testStandard.includes("ASTM A999") && (
                  <>
                    <Grid item xs={3}>
                      <SelectField
                        required
                        label="Deformation per unit length"
                        name="deformationPerUnitLength"
                      >
                        {DEFORMATION_PER_UNIT.map((dpu) => (
                          <MenuItem key={dpu} value={dpu}>{dpu}</MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name="firstStep"
                        label="First step"
                        required
                        endAdornment="MM"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name="secondStep"
                        label="Second step"
                        required
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <FieldArray name="elements">
                {({remove, push}) => props.values.elements.map((element, index) => (
                  <>
                    <Grid item container spacing={5}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={5} alignItems="flex-end">
                      <Grid item xs={3}>
                        <TextField
                          name={`elements.${index}.specimenId`}
                          label="Specimen ID"
                          required
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name={`elements.${index}.specimenLength`}
                          label="Specimen length"
                          type="number"
                          required
                          endAdornment="MM"
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={5} alignItems="flex-end">
                      <Grid item xs={3}>
                        <SelectField
                          label="Weld position"
                          name={`elements.${index}.weldPosition`}
                        >
                          {WELD_POSITIONS.map((wp) => (
                            <MenuItem key={wp} value={wp}>{wp}</MenuItem>
                          ))}
                        </SelectField>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name={`elements.${index}.finalDistanceOfPlates`}
                          label="Final distance of plates"
                          type="number"
                          required
                          endAdornment="MM"
                        />
                      </Grid>
                      {props.values.elements.length > 1 && (
                        <Grid item>
                          <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            onClick={() => remove(index)}
                          >
                          Remove
                          </Button>
                        </Grid>
                      )}
                      {props.values.elements.length - 1 === index && (
                        <Grid item>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => push(ELEMENT)}
                          >
                          Add
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ))}
              </FieldArray>
              <Grid item container spacing={5}>
                <Grid item xs={3}>
                  <SelectField
                    required
                    label="Result"
                    name="result"
                  >
                    <MenuItem value={TEST_RESULTS.NO_DEFECTS}>{TEST_RESULTS.NO_DEFECTS}</MenuItem>
                    <MenuItem value={TEST_RESULTS.ACCEPTABLE_DEFECTS}>{TEST_RESULTS.ACCEPTABLE_DEFECTS}</MenuItem>
                    <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE_DEFECTS}>{TEST_RESULTS.NOT_ACCEPTABLE_DEFECTS}</MenuItem>
                    {props.values.testStandard.includes("ASTM A999") && (
                      <MenuItem value={TEST_RESULTS.ABSENCE_OF_CRACKS_AFTER_FIRST_STEP}>
                        {TEST_RESULTS.ABSENCE_OF_CRACKS_AFTER_FIRST_STEP}
                      </MenuItem>
                    )}
                  </SelectField>
                </Grid>
              </Grid>
            </Grid>
            <TestFooter
              onSubmit={() => saveTest(props.values)}
              result={props.values.result}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default FlatteningTest;