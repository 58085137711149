import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {SPECIFICATION} from "./constants";

export const getInitialValues = (campaign = {}) => {
  const {name, type, certificationType, purchaseOrder = {orderNumber: ""}, manufacturingLocation, properties = {}, singleProducts = []} = campaign;

  return {
    name: name || "",
    type: type || CAMPAIGN_TYPES.SINGLE_MATERIAL,
    certificationType: certificationType || "",

    // to be removed before saving
    connectToPO: !!purchaseOrder._id,
    // to be removed before saving
    existingMaterial: !!singleProducts.length,

    // to be removed before saving
    customer: purchaseOrder.receiver?._id || "",
    purchaseOrder,

    manufacturingLocation: manufacturingLocation || "",
    properties: {
      product: {
        qcp: properties.product?.qcp?._id || "",
        // to be removed before saving
        qcpName: properties.product?.qcp?.name || "",
        productType: {
          // to be removed before saving
          _id: "",
          name: properties.product?.productType.name || "",
          // to be renamed to internalId before saving
          internalName: properties.product?.productType.internalId || "",
          properties: properties.product?.productType.properties || [],
        },
        materialSpecifications: properties.product?.materialSpecifications || [SPECIFICATION],
        nominalSizes: properties.product?.nominalSizes || []
      },
      statistics: {
        estimatedHeats: properties.statistics?.estimatedHeats || "",
        estimatedHeatLots: properties.statistics?.estimatedHeatLots || "",
      },
    },

    singleProducts,
  };
};