export const getVolumeLoss = (density, massLoss) => {
  if(!density || !massLoss) return null;

  return ((massLoss / density) * 1000).toFixed(4);
};

export const getErosionValue = (volumeLoss, erodingParticleMassLoss) => {
  if(!volumeLoss || !erodingParticleMassLoss) return null;

  return ((volumeLoss / erodingParticleMassLoss) * 1000).toFixed(4);
};

export const getSteadyStateErosionRate = (testResults) => {
  const y = testResults.map((testResult) => Number(testResult.massLoss));
  const x = testResults.map((testResult) => Number(testResult.elapsedTime));
  
  const n = y.length;
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;

  for (let i = 0; i < y.length; i++) {
    sum_x += x[i];
    sum_y += y[i];
    sum_xy += (x[i]*y[i]);
    sum_xx += (x[i]*x[i]);
  }

  const result = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x) || 0;

  return result.toFixed(5);
};

export const getAverageErosion = (steadyStateErosion, density, particleFlowRate) => {
  if(!steadyStateErosion || !density || !particleFlowRate) return 0;

  return (Number(steadyStateErosion) / Number(particleFlowRate) / Number(density)).toFixed(4);
};