import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Box, Collapse} from "@mui/material";
import {useHistory} from "react-router-dom";
import useStores from "../../../../../../../../../../../useStores";
import MenuGroup from "../../../MenuGroup";
import {ACCOUNT_ITEM, COMPANY_ITEM} from "../../../../../../constants";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import {getMenuItemEnabled} from "../../../../services";

const EXIT_TIMEOUT = 500;
const ENTER_TIMEOUT = 250;

const CollapsibleSection = ({expanded}) => {
  const history = useHistory();

  const {UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;
  const role = UserStore.user.data.role;
  const features = UserStore.features;

  const items = [ACCOUNT_ITEM, COMPANY_ITEM].filter((item) => getMenuItemEnabled(item, features, role, module));

  return (
    <Collapse
      sx={{width: "100%"}}
      in={expanded}
      timeout={{
        exit: EXIT_TIMEOUT,
        enter: ENTER_TIMEOUT
      }}
      unmountOnExit
    >
      <Box
        sx={{
          width: "100%",
          padding: 1,
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <Box
          sx={{
            paddingBottom: 2,
          }}
        >
          <MenuGroup
            displayTitle
            items={items}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={() => history.push("/logout")}
            variant="secondaryNewDesign"
            size="small"
          >
            <IconistsIcon width="24px" icon={ICONISTS_ICONS.logout} color="#AFAFAF"/>
            &nbsp;
            Logout
          </Button>
        </Box>
      </Box>
    </Collapse>
  );
};

export default observer(CollapsibleSection);