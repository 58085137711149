import React, {useEffect, useState} from "react";
import {keys} from "ramda";
import {useParams} from "react-router-dom";
import useStores from "../../useStores";
import Header from "./components/Header";
import Content from "./components/Content";
import {SUB_TABS_BY_TAB, TABS} from "./constants";

const SingleMaterialCampaign = () => {
  const [activeTab, setActiveTab] = useState(TABS.DASHBOARD);
  const [activeSubTab, setActiveSubTab] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {SingleMaterialCampaignStore} = useStores();

  const {id} = useParams();

  const loadCampaign = async () => {
    await SingleMaterialCampaignStore.getCampaignById(id);
    setIsLoaded(true);
  };

  useEffect(() => {
    loadCampaign();
  }, [id]);

  const changeActiveTab = (tab) => {
    const newTab = tab || activeTab;

    setActiveTab(newTab);
    const subTabs = keys(SUB_TABS_BY_TAB[newTab]) || [];
    setActiveSubTab(subTabs[0] || null);
  };

  return (
    <>
      <Header
        activeTab={activeTab}
        setActiveTab={changeActiveTab}
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
        showFilters={showFilters}
        toggleFilters={() => setShowFilters((prevValue) => !prevValue)}
        isLoaded={isLoaded}
      />
      <Content
        showFilters={showFilters}
        activeTab={activeTab}
        activeSubTab={activeSubTab}
        isLoaded={isLoaded}
      />
    </>
  );
};

export default SingleMaterialCampaign;