import {Box, ToggleButton, ToggleButtonGroup, Tooltip, useTheme} from "@mui/material";
import {keys} from "ramda";
import {observer} from "mobx-react-lite";
import React, {useMemo} from "react";
import useStores from "../../../../../../useStores";
import {TABS, TABS_TITLES, TABS_TOOLTIPS} from "../../../../constants";

const ENTER_DELAY = 1500;

const Navigation = ({activeTab, setActiveTab, isLoaded}) => {
  const theme = useTheme();
  const {SingleMaterialCampaignStore} = useStores();

  const {singleProducts} = SingleMaterialCampaignStore.campaign;
  
  const filteredTabNames = useMemo(() => {
    const tabsNames = keys(TABS);

    return singleProducts?.length ? tabsNames : tabsNames.filter((tab) => tab !== TABS.BASE_MATERIALS);
  }, [isLoaded]);

  return (
    <ToggleButtonGroup
      variant="header"
      value={activeTab}
      exclusive
      onChange={(_, value) => setActiveTab(value)}
    >
      {filteredTabNames.map((tab) => (
        <ToggleButton
          key={tab}
          value={tab}
        >
          <Tooltip title={TABS_TOOLTIPS[tab]} enterDelay={ENTER_DELAY} enterTouchDelay={ENTER_DELAY} enterNextDelay={ENTER_DELAY}>
            <Box
              sx={{
                color: activeTab === tab ? theme.palette.primary.main : "inherit"
              }}
            >
              {TABS_TITLES[tab]}
            </Box>
          </Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default observer(Navigation);