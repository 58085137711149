import {is, isNil, map, mapObjIndexed} from "ramda";
import {MEASUREMENTS_CONFIG} from "./data";
import {CUSTOM_EXCEPTIONS} from "./exceptions";
import {getIsWithinRequirements, round} from "@core/helpers";

export const getAcceptanceCriteriaValues = ({standardUnits, element, poItem,  acceptanceCriteria, ...rest}) => {
  const nominal = is(Function, rest?.nominalFormula) ? rest.nominalFormula(element, poItem, acceptanceCriteria) : Number(rest?.nominal);
  let toleranceMinus = is(Function, rest?.toleranceMinusFormula) ? rest.toleranceMinusFormula(element, poItem, nominal, acceptanceCriteria) : Number(rest?.toleranceMinus);
  let tolerancePlus = is(Function, rest?.tolerancePlusFormula) ? rest.tolerancePlusFormula(element, poItem, nominal, acceptanceCriteria) : Number(rest?.tolerancePlus);

  const isPercentage = rest?.toleranceUnits === "%" && standardUnits !== rest?.toleranceUnits;

  if (isPercentage && !rest?.tolerancePlusFormula) {
    tolerancePlus = tolerancePlus * nominal / 100;
  }

  if (isPercentage && !rest?.toleranceMinusFormula) {
    toleranceMinus = toleranceMinus * nominal / 100;
  }

  const min = !isNil(nominal) ? nominal - toleranceMinus : null;
  const max = !isNil(nominal) ? nominal + tolerancePlus : null;

  return {min, max, nominal, toleranceMinus, tolerancePlus};
};

const isInRange = (value, min = 0, max) => !value || value === "OK" || getIsWithinRequirements(value, min, max);

export const isAcceptable = (params, min, max) => {
  const {valueMin = "", valueMax = "", value = ""} = params;
  const values = valueMin && valueMax ? [valueMin, valueMax] : value.toString().split(",");

  return values.every((value) => isInRange(value, min, max));
};

export const isValueAcceptable = (params) => {
  const {min, max, nominal} = getAcceptanceCriteriaValues(params);
  const acceptable = isAcceptable(params, min, max);

  const customAcceptable = params.getIsAcceptable && params.getIsAcceptable(params.elementsValues, min, max, nominal, params.poItem);

  return acceptable || customAcceptable;
};

export const isElementAcceptable = (element, measurements, {acceptanceCriteria, acceptance, units, elements}, poItemNumber) => measurements.every((measurement) => {
  const standardUnits = units[measurement];
  const locations = MEASUREMENTS_CONFIG[measurement].locations;
  const acceptanceConfig = acceptanceCriteria[measurement] || {};

  const elementsValues = elements.reduce((acc, element) => {
    if(!locations) acc.push({value: element[measurement]});
    else {
      acc.push(...locations.map((location) => ({
        value: element[measurement] && element[measurement][location],
        valueMin: element[measurement] && element[measurement][`${location}Min`],
        valueMax: element[measurement] && element[measurement][`${location}Max`],
      })));
    }

    return acc; 
  }, []);

  if (!locations) {
    const value = element[measurement];
    const exceptionsFormulas = CUSTOM_EXCEPTIONS[acceptance]?.data[measurement] || {};

    return isValueAcceptable({
      element,
      value,
      standardUnits,
      elementsValues,
      ...acceptanceConfig,
      ...exceptionsFormulas,
      poItem: poItemNumber,
      acceptanceCriteria,
    });
  }

  return locations.every((location) => {
    const name = location === "body" ? "body" : "ends";

    const locationAcceptanceConfig = acceptanceConfig[name] || {};
    const measurementException = CUSTOM_EXCEPTIONS[acceptance]?.data[measurement] || {};
    const locationExceptionsFormulas = measurementException[name] || {};
    const value = element[measurement] && element[measurement][location];
    const valueMin = element[measurement] && element[measurement][`${location}Min`];
    const valueMax = element[measurement] && element[measurement][`${location}Max`];

    return isValueAcceptable({
      element,
      value,
      valueMin,
      valueMax,
      location,
      standardUnits,
      elementsValues,
      ...locationAcceptanceConfig,
      ...locationExceptionsFormulas,
      poItem: poItemNumber,
      acceptanceCriteria,
    });
  });
});

export const getIsAcceptable = ({elements, acceptanceCriteria, acceptance, units}, measurements, poItemNumber) => {
  return elements.every((element) => isElementAcceptable(element, measurements, {
    acceptanceCriteria,
    acceptance,
    units,
    elements
  }, poItemNumber));
};

export const getUnitsFromMeasurements = (unitType, measurements) => {
  return measurements.reduce((acc, measurement) => {
    acc[measurement] = MEASUREMENTS_CONFIG[measurement].units[unitType];

    return acc;
  }, {});
};

const dispatchAcceptanceObject = (value, poItemNumber, acceptanceCriteria) => {
  const nominal = value.nominalFormula ? round(value.nominalFormula({}, poItemNumber), 2) || value.nominal : value.nominal;
  const tolerancePlus = value.tolerancePlusFormula ? round(value.tolerancePlusFormula({}, poItemNumber, nominal, acceptanceCriteria), 2) || value.tolerancePlus : value.tolerancePlus;
  const toleranceMinus = value.toleranceMinusFormula ? round(value.toleranceMinusFormula({}, poItemNumber, nominal, acceptanceCriteria), 2) || value.toleranceMinus : value.toleranceMinus;

  return {nominal, tolerancePlus, toleranceMinus};
};

export const getCalculatedAcceptanceCriteria = (config, poItemNumber, acceptanceCriteria = {}) => {
  return mapObjIndexed((config, measurement) => {
    if (MEASUREMENTS_CONFIG[measurement].locations) {
      return map((value) => dispatchAcceptanceObject(value, poItemNumber, acceptanceCriteria), config);
    }

    return dispatchAcceptanceObject(config, poItemNumber, acceptanceCriteria);
  }, config);
};
