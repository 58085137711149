import React from "react";
import {Grid, MenuItem, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik, FieldArray, getIn} from "formik";
import {Input, Select} from "@core/components/Form";
import * as yup from "yup";
import testsConfig from "@core/configs/test";
import {TYPES} from "@core/constants/test";
import {Uploader} from "@core/components/Uploaders";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import styles from "./styles";

const SupplementTest = (props) => {
  const {classes, test} = props;

  const validationSchema = yup.object().shape({
    elements: yup.array().of(yup.object().shape({
      creator: yup.string().required("Creator is required!"),
      testType: yup.string().required("Test type is required!"),
      certificateType: yup.string().required("Certificate type is required!"),
      file: yup.string().required("File is required!")
    })),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const defaultElement = {
    creator: "",
    testType: "",
    certificateType: "",
    file: {
      preview: "",
      preview_path: ""
    },
  };

  const initialValues = {
    elements: test.properties.elements || [defaultElement],
    witnesses: test.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || [],
    testExecutionDate: test?.properties.testExecutionDate || ""
  };

  const onSubmit = (values, actions) => {
    const data = {
      elements: values.elements,
      inspectionDate: values.inspectionDate,
      inspectorJobNumber: values.inspectorJobNumber,
      tags: values.tags,
      testExecutionDate: values.testExecutionDate,
    };

    props.saveTest(data, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    if (props.closeNewTest) {
      props.closeNewTest();
    }
  };

  return (
    <Formik
      innerRef={props.formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={(props) => {
        const {
          values: {
            elements,
          },
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
        } = props;

        const change = (e) => {
          handleChange(e);
          setFieldTouched(e.target.name);
        };

        const handleUploadedFile = (file, index) => {
          setFieldValue(`elements.${index}.file.preview`, true);
          setFieldValue(`elements.${index}.file.preview_path`, file.file.dir + file.file.name);
        };

        return (
          <>
            <h1>Supplier tests</h1>
            <FieldArray name={"elements"}>
              {({push, remove}) => {
                return (
                  <>
                    <div className={classes.elements}>
                      {elements.map((el, index) => {
                        const path = `elements.${index}`;

                        return (
                          <Grid container spacing={5} marginBottom={2}>
                            <Grid item xs={3} className={classes.alignEnd}>
                              <Input
                                name={`${path}.creator`}
                                label={"Creator"}
                                value={el.creator}
                                onChange={change}
                                error={Boolean(getIn(errors, `${path}.creator`) && Boolean(getIn(touched, `${path}.creator`)))}
                                errorMessage={getIn(errors, `${path}.creator`)}
                              />
                            </Grid>
                            <Grid item xs={3} className={classes.alignEnd}>
                              <Select
                                name={`${path}.testType`}
                                label={"Test type"}
                                value={el.testType}
                                onChange={change}
                              >
                                {Object.values(testsConfig)
                                  .filter((config) => ![TYPES.SPLIT, TYPES.SUPPLEMENT].includes(config.type))
                                  .sort()
                                  .map((type) =>
                                    <MenuItem key={type} value={type.type}>{type.title}</MenuItem>
                                  )}
                              </Select>
                            </Grid>
                            <Grid item xs={2} className={classes.alignEnd}>
                              <Select
                                name={`${path}.certificateType`}
                                label={"Type"}
                                value={el.certificateType}
                                onChange={change}>
                                <MenuItem value={"3.1"}>3.1</MenuItem>
                                <MenuItem value={"3.2"}>3.2</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={2} className={classes.fileContainer}>
                              <Uploader
                                className={classes.fileUpload}
                                file={el.file.preview ? el.file.preview_path : ""}
                                fileType={"image/*, application/pdf"}
                                handleUploadedFile={(file) => {
                                  const found = elements.find((elem) => elem === el);
                                  const indexOfFound = elements.indexOf(found);
                                  handleUploadedFile(file, indexOfFound);
                                }}
                              />
                            </Grid>
                            {index !== 0 && <Grid item xs={2} className={classes.alignEnd}>
                              <Button variant={"contained"} color={"secondary"} size={"medium"} onClick={() => {
                                remove(index);
                              }}>Remove</Button>
                            </Grid>}
                          </Grid>
                        );
                      })}
                      <Grid container spacing={5}>
                        <Grid item xs={2}>
                          <Button fullWidth variant={"contained"} color={"primary"} size={"medium"} onClick={() => {
                            push(defaultElement);
                          }}>Add</Button>
                        </Grid>
                      </Grid>
                    </div>
                    <ProducerTestFooter />
                  </>
                );
              }}
            </FieldArray>
          </>
        );
      }}
    />
  );
};

export default withStyles(SupplementTest, styles);
