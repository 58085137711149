export default (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      marginRight: theme.spacing(1),
    }
  },
  popoverContent: {
    padding: theme.spacing(2),
    width: 300,
  }
});