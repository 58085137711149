import {lazy} from "react";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

export const ICONS_SVG = {
  [ICONISTS_ICONS.filter]: lazy(() => import("./icons/filtersAndSettings/filter-1-9.svg")),
  [ICONISTS_ICONS.filterFilled]: lazy(() => import("./icons/filtersAndSettings/filter-1-10.svg")),
  [ICONISTS_ICONS.archive]: lazy(() => import("./icons/foldersAndFiles/archive-9.svg")),
  [ICONISTS_ICONS.building]: lazy(() => import("./icons/building/building-9.svg")),
  [ICONISTS_ICONS.buildings]: lazy(() => import("./icons/building/buildings-9.svg")),
  [ICONISTS_ICONS.clipboard]: lazy(() => import("./icons/interfaceGeneral/clipboard-9.svg")),
  [ICONISTS_ICONS.dotGrid]: lazy(() => import("./icons/interfaceGeneral/dot-grid-3x3-9.svg")),
  [ICONISTS_ICONS.fileText]: lazy(() => import("./icons/foldersAndFiles/file-text-9.svg")),
  [ICONISTS_ICONS.folderPaper]: lazy(() => import("./icons/foldersAndFiles/folder-paper-9.svg")),
  [ICONISTS_ICONS.group]: lazy(() => import("./icons/people/group-2-9.svg")),
  [ICONISTS_ICONS.logout]: lazy(() => import("./icons/interfaceGeneral/arrow-box-left-9.svg")),
  [ICONISTS_ICONS.pageTextSearch]: lazy(() => import("./icons/foldersAndFiles/page-text-search-9.svg")),
  [ICONISTS_ICONS.paperPlane]: lazy(() => import("./icons/location/send-9.svg")),
  [ICONISTS_ICONS.people]: lazy(() => import("./icons/people/people-2-9.svg")),
  [ICONISTS_ICONS.playground]: lazy(() => import("./icons/things/playground-9.svg")),
  [ICONISTS_ICONS.preferences]: lazy(() => import("./icons/filtersAndSettings/settings-gear-1-9.svg")),
  [ICONISTS_ICONS.pumpjack]: lazy(() => import("./icons/natureAndEnergy/pumpjack-9.svg")),
  [ICONISTS_ICONS.settingsGear]: lazy(() => import("./icons/filtersAndSettings/settings-gear-2-9.svg")),
  [ICONISTS_ICONS.settingsSliderThree]: lazy(() => import("./icons/filtersAndSettings/settings-slider-three-9.svg")),
  [ICONISTS_ICONS.signature]: lazy(() => import("./icons/edit/signature-9.svg")),
  [ICONISTS_ICONS.sketchbook]: lazy(() => import("./icons/foldersAndFiles/sketchbook-9.svg")),
  [ICONISTS_ICONS.squareChecklistMagnifyingGlass]: lazy(() => import("./icons/interfaceGeneral/square-checklist-magnifying-glass-9.svg")),
  [ICONISTS_ICONS.squareChecklist]: lazy(() => import("./icons/interfaceGeneral/square-checklist-9.svg")),
  [ICONISTS_ICONS.stocks]: lazy(() => import("./icons/things/stocks-9.svg")),
  [ICONISTS_ICONS.thread]: lazy(() => import("./icons/things/thread-9.svg")),
  [ICONISTS_ICONS.toolbox]: lazy(() => import("./icons/edit/toolbox-9.svg")),
  [ICONISTS_ICONS.chevronLargeTop]: lazy(() => import("./icons/arrows/chevron-large-top-9.svg")),
  [ICONISTS_ICONS.chevronLargeDown]: lazy(() => import("./icons/arrows/chevron-large-down-9.svg")),
  [ICONISTS_ICONS.target]: lazy(() => import("./icons/interfaceGeneral/target-9.svg")),
  [ICONISTS_ICONS.around]: lazy(() => import("./icons/arAndVr/around-9.svg")),
  [ICONISTS_ICONS.shapesPlusXSquareCircle]: lazy(() => import("./icons/interfaceGeneral/shapes-plus-x-square-circle-9.svg")),
  [ICONISTS_ICONS.pageText]: lazy(() => import("./icons/foldersAndFiles/page-text-9.svg")),
  [ICONISTS_ICONS.focusRenew]: lazy(() => import("./icons/photographyAndVideo/focus-renew-9.svg")),
  [ICONISTS_ICONS.focusZoomIn]: lazy(() => import("./icons/photographyAndVideo/focus-zoom-in.svg")),
  [ICONISTS_ICONS.focusZoomOut]: lazy(() => import("./icons/photographyAndVideo/focus-zoom-out.svg")),
  [ICONISTS_ICONS.magnifyingGlassMinus]: lazy(() => import("./icons/interfaceGeneral/magnifying-glass-minus.svg")),
  [ICONISTS_ICONS.magnifyingGlassPlus]: lazy(() => import("./icons/interfaceGeneral/magnifying-glass-plus.svg")),
  [ICONISTS_ICONS.chevronBottom]: lazy(() => import("./icons/arrows/chevron-bottom-9.svg")),
  [ICONISTS_ICONS.calendar]: lazy(() => import("./icons/timeAndDate/calendar-1-9.svg")),
  [ICONISTS_ICONS.circleQuestionMark]: lazy(() => import("./icons/interfaceGeneral/circle-questionmark.svg")),
  [ICONISTS_ICONS.circleX]: lazy(() => import("./icons/interfaceGeneral/circle-x.svg")),
  [ICONISTS_ICONS.plusSmall]: lazy(() => import("./icons/interfaceGeneral/plus-small.svg")),
  [ICONISTS_ICONS.editList]: lazy(() => import("./icons/typography/edit-list.svg")),
  [ICONISTS_ICONS.fileDownload]: lazy(() => import("./icons/foldersAndFiles/file-download.svg")),
};