import {
  Box,
  Table,
  Dialog,
  DialogContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@mui/material";
import React from "react";
import {observer} from "mobx-react";
import TransverseRuptureStrengthForm from "@core/components/TestForms/TransverseRuptureStrength";
import TestResultTableCell from "@core/components/TestResultTableCell";
import {
  STRESS_AT_BREAK_COLUMN_NAMES,
  DIRECTIONS,
  HIGHLIGHTED_CELL_SX
} from "@core/constants/transverseRuptureStrength";
import {TEST_PROPS_COLUMN_NAMES} from "@core/components/TestViews/TransverseRuptureStrength/constants";

const TransverseRuptureStrength = ({addDataOpen, dataDialogClose, formRef, updateTest, test}) => {

  const {stressAtBreak = [], standardDeviation, meanValues, ...testProperties} = test?.properties ?? {};

  return (
    <>
      <Typography variant="h5" marginBottom={4}>Test information</Typography>

      <Box sx={{maxWidth: "700px"}}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              {Object.values(TEST_PROPS_COLUMN_NAMES).map((columnName, index) => (
                <TableCell key={index}>
                  {columnName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Object.keys(TEST_PROPS_COLUMN_NAMES).map((fieldName) => (
                <TableCell key={fieldName}>{testProperties[fieldName]}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      <Typography variant="h5" marginY={4}>Test results</Typography>

      <Box sx={{maxWidth: "700px", marginTop: 4}}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {Object.values(STRESS_AT_BREAK_COLUMN_NAMES).map((columnName) => (
                <TableCell key={columnName}>{columnName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stressAtBreak.map(({uniqueId, ...item}) => (
              <TableRow key={uniqueId}>
                <TableCell
                  className="vertical-header-col"
                />
                <TableCell>{item.specimenId}</TableCell>
                {Object.values(DIRECTIONS).map((direction) => (
                  <TableCell key={direction}>
                    <TestResultTableCell
                      value={item[direction]}
                      min={testProperties.acceptanceCriteria}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                className="vertical-header-col"
                sx={HIGHLIGHTED_CELL_SX}
              >
                Standard deviation
              </TableCell>
              <TableCell sx={HIGHLIGHTED_CELL_SX}></TableCell> {/* For the specimen ID column*/}
              {Object.values(DIRECTIONS).map((direction) => (
                <TableCell
                  key={direction}
                  sx={{
                    fontWeight: (theme) => theme.typography.fontWeightMedium,
                    ...HIGHLIGHTED_CELL_SX,
                  }}
                >
                  {standardDeviation?.[direction]}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                className="vertical-header-col"
                sx={HIGHLIGHTED_CELL_SX}
              >
                Mean values
              </TableCell>
              <TableCell sx={HIGHLIGHTED_CELL_SX}></TableCell> {/* For the specimen ID column*/}
              {Object.values(DIRECTIONS).map((direction) => (
                <TableCell
                  key={direction}
                  sx={{
                    fontWeight: (theme) => theme.typography.fontWeightMedium,
                    ...HIGHLIGHTED_CELL_SX
                  }}
                >
                  {meanValues?.[direction]}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <TransverseRuptureStrengthForm
            formRef={formRef}
            saveTest={updateTest}
            test={test}
            isProducerTest
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default observer(TransverseRuptureStrength);