import TestSlideOut from "./components/TestSlideOut";
import {FEATURES} from "@core/constants/features";
import {ALL_TESTS, TEST_CATEGORIES_LABELS} from "@core/constants/test";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import {useParams} from "react-router-dom";
import useStores from "../../../../../../useStores";
import TestsProgressBar from "@core/components/TestsProgressBar";

const TestsOverview = ({selectedGroup}) => {
  const [openTestId, setOpenTestId] = useState(null);
  const [slideOutOpen, setSlideOutOpen] = useState(false);
  const [loadedTestCategories, setLoadedTestCategories] = useState([]);

  const {SingleMaterialCampaignStore, UserStore} = useStores();
  const {id} = useParams();

  const tests = SingleMaterialCampaignStore.testsByCategory[selectedGroup] || [];

  const makeRequestOnEachTab = UserStore.features[FEATURES.PERFORM_REQUEST_ON_EACH_TAB];

  const getTestsByCategory = async () => {
    const makeRequest = !loadedTestCategories.includes(selectedGroup) || makeRequestOnEachTab;

    if(!selectedGroup || !makeRequest) return;

    selectedGroup === ALL_TESTS ?
      await SingleMaterialCampaignStore.getAllTests(id) :
      await SingleMaterialCampaignStore.getTestsByCategory(id, selectedGroup);

    setLoadedTestCategories((loadedTestCategories) => [...loadedTestCategories, selectedGroup]);
  };

  const onTestClick = (tests) => {
    const [test] = tests;

    setOpenTestId(test._id);
    setSlideOutOpen(true);
  };

  useEffect(() => {
    getTestsByCategory();
  }, [selectedGroup, id]);

  return (
    <>
      <Typography variant="h6" marginBottom={1}>
        {TEST_CATEGORIES_LABELS[selectedGroup]} Overview
      </Typography>

      <Box marginY={3}>
        <TestsProgressBar
          isLoaded={loadedTestCategories.includes(selectedGroup)}
          tests={tests}
        />
      </Box>

      <TestSummaryTable
        isLoaded={loadedTestCategories.includes(selectedGroup)}
        tests={tests}
        onTestClick={onTestClick}
      />
      <TestSlideOut
        close={() => setSlideOutOpen(false)}
        open={slideOutOpen}
        testId={openTestId}
      />
    </>
  );
};

export default observer(TestsOverview);