import React, {useState} from "react";
import {Button, MenuItem, Grid, FormControlLabel, Checkbox, Collapse} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {Formik} from "formik";
import {omit} from "ramda";
import * as yup from "yup";
import {Select, Input} from "@core/components/Form";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import {FilesUploader} from "@core/components/Uploaders";
import data from "./data";
import styles from "./styles";

const validationSchema = yup.object().shape({
  method: yup.string().required("Method is required!"),
  type: yup.string().required("Type is required!"),
  temp: yup.string().required("Temperature is required!"),
  tempUnits: yup.string().required("Temperature units are required!"),
  unitOfHoldingTime: yup.string(),
  holdingTimeUnits: yup.string().when("holdingTime", {
    is: (holdingTime) => Boolean(holdingTime),
    then: yup.string().required("Holding time units are required!"),
    otherwise: yup.string()
  }),
  holdingTime: yup.number().positive("Invalid time").nullable(true),
  holdingThickness: yup.string(),
  holdingThicknessUnits: yup.string().when("holdingThickness", {
    is: (holdingThickness) => Boolean(holdingThickness),
    then: yup.string().required("Holding thickness is required!"),
    otherwise: yup.string()
  }),
  coolingMedium: yup.string().required("Cooling medium is required!"),
  secondTemp: yup.string().when("type", {
    is: (value) => value === data.TYPES.QUENCH_AND_TEMPER,
    then: yup.string().required("Quench and temper temperature is required!")
  }),
  secondTempUnits: yup.string().when("type", {
    is: (value) => value === data.TYPES.QUENCH_AND_TEMPER,
    then: yup.string().required("Quench and temper temperature units are required!")
  }),
  secondCoolingMedium: yup.string().when("type", {
    is: (value) => value === data.TYPES.QUENCH_AND_TEMPER,
    then: yup.string().required("Quench and temper cooling medium is required!")
  }),
  secondUnitOfHoldingTime: yup.string(),
  secondHoldingTime: yup.number().positive("Invalid time").nullable(true),
  secondHoldingTimeUnits: yup.string().when("secondHoldingTime", {
    is: (holdingTime) => Boolean(holdingTime),
    then: yup.string().required("Quench and temper holding time units are required!"),
    otherwise: yup.string()
  }),
  secondHoldingThickness: yup.string(),
  secondHoldingThicknessUnits: yup.string().when("secondHoldingThickness", {
    is: (holdingThickness) => Boolean(holdingThickness),
    then: yup.string().required("Quench and temper Holding thickness is required!"),
    otherwise: yup.string()
  }),
  tolerancePlus: yup.string(),
  toleranceMinus: yup.string(),
  toleranceUnits: yup.string().when(["tolerancePlus", "toleranceMinus"], {
    is: (tolerancePlus, toleranceMinus) => toleranceMinus || tolerancePlus,
    then: yup.string().required("Tolerance units are required!"),
    otherwise: yup.string()
  }),
  heatingRateMin: yup.string(),
  heatingRateMax: yup.string(),
  heatingRateUnits: yup.string().when(["heatingRateMin", "heatingRateMax"], {
    is: (heatingRateMin, heatingRateMax) => heatingRateMin || heatingRateMax,
    then: yup.string().required("Heating rate units are required!"),
    otherwise: yup.string()
  }),
  coolingRateMin: yup.string(),
  coolingRateMax: yup.string(),
  coolingRateUnits: yup.string().when(["coolingRateMin", "coolingRateMax"], {
    is: (coolingRateMin, coolingRateMax) => coolingRateMin || coolingRateMax,
    then: yup.string().required("Cooling rate units are required!"),
    otherwise: yup.string()
  }),
  notes: yup.string(),
  witnesses: yup.array().of(yup.object()),
  inspectionDate: yup.string(),
  inspectorJobNumber: yup.string(),
  tags: yup.array().of(yup.string()),
  testExecutionDate: yup.string(),
});

const HeatTreatment = ({classes, isRelatedToCondition, formRef, ...props}) => {
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);

  const onSubmit = (values, actions) => {
    props.saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    actions.setSubmitting(false);

    if (props.closeNewTest) {
      props.closeNewTest();
    }
  };

  const initialValues = {
    method: "",
    type: "",
    temp: "",
    tempUnits: "celsius",
    unitOfHoldingTime: "time",
    holdingThickness: "",
    holdingThicknessUnits: "",
    holdingTime: null,
    holdingTimeUnits: "",
    secondUnitOfHoldingTime: "time",
    secondHoldingTime: null,
    secondHoldingTimeUnits: "",
    secondHoldingThickness: "",
    secondHoldingThicknessUnits: "",
    coolingMedium: "",
    images: [],
    secondTemp: "",
    secondTempUnits: "",
    secondCoolingMedium: "",
    strainHardening: false,
    tolerancePlus: "",
    toleranceMinus: "",
    toleranceUnits: "",
    heatingRateMin: "",
    heatingRateMax: "",
    heatingRateUnits: "",
    coolingRateMin: "",
    coolingRateMax: "",
    coolingRateUnits: "",
    notes: "",
    witnesses: props.test.witnesses.map((witness) => witness.company) || [],
    inspectionDate: props.test?.inspectionDate || "",
    inspectorJobNumber: props.test?.inspectorJobNumber || "",
    tags: [],
    testExecutionDate: "",
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={{...initialValues, ...props.test.properties}}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}

        render={(props) => {
          const {
            values: {
              method,
              type,
              temp,
              coolingMedium,
              images,
              unitOfHoldingTime,
              holdingTime,
              holdingTimeUnits,
              holdingThickness,
              holdingThicknessUnits,
              secondUnitOfHoldingTime,
              secondHoldingTime,
              secondHoldingTimeUnits,
              secondHoldingThickness,
              secondHoldingThicknessUnits,
              secondTemp,
              secondCoolingMedium,
              strainHardening,
              tempUnits,
              secondTempUnits,
              tolerancePlus,
              toleranceMinus,
              toleranceUnits,
              heatingRateMin,
              heatingRateMax,
              heatingRateUnits,
              coolingRateMin,
              coolingRateMax,
              coolingRateUnits,
              notes,
            },
            errors,
            isValid,
            handleSubmit,
            handleChange,
            touched,
            setFieldTouched,
            setFieldValue
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          const handleUploadedFile = (index, img) => {
            setFieldValue(`images.${index}.preview`, true);
            setFieldValue(`images.${index}.preview_path`, img.file.dir + img.file.name);
          };

          return (<Grid container spacing={2}>
            <Grid item container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <h1>Heat treatment</h1>
              </Grid>
              <Grid item xs={4}>
                <Select
                  value={method}
                  name='method'
                  label='Method'
                  required
                  error={Boolean(errors.method) && touched.method}
                  onChange={(event) => change("method", event)}
                >
                  {data.METHODS.map((method) =>
                    <MenuItem key={method} value={method}>{method}</MenuItem>
                  )}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Select
                  value={type}
                  name='type'
                  label='Type'
                  required
                  error={Boolean(errors.type) && touched.type}
                  onChange={(event) => change("type", event)}
                >
                  {Object.values(data.TYPES).map((type) =>
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  )}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={strainHardening}
                      value={strainHardening}
                      onClick={(event) => setFieldValue("strainHardening", event.target.checked)}
                      name="strainHardening"
                    />
                  )}
                  label="Strain hardening"
                />
              </Grid>
            </Grid>
            {type === data.TYPES.QUENCH_AND_TEMPER && (
              <Grid item container spacing={3}>
                <Grid item xs={4}>
                  <h3>Quench</h3>
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={3}>
              <Grid item container spacing={1} xs={4} alignItems="flex-end">
                <Grid item xs={8}>
                  <Input
                    label='Temperature'
                    name='temp'
                    value={temp}
                    required
                    error={Boolean(errors.temp) && touched.temp}
                    errorMessage={errors.temp}
                    onChange={(e) => change("temp", e)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    required
                    value={tempUnits}
                    name='tempUnits'
                    error={Boolean(errors.tempUnits) && touched.tempUnits}
                    onChange={(event) => change("tempUnits", event)}
                  >
                    <MenuItem key="celsius" value="celsius">°C</MenuItem>
                    <MenuItem key="fahrenheit" value="fahrenheit">°F</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Select
                  value={coolingMedium}
                  name='coolingMedium'
                  label='Cooling medium'
                  required
                  error={Boolean(errors.coolingMedium) && touched.coolingMedium}
                  onChange={(event) => change("coolingMedium", event)}
                >
                  {data.coolingMedium.map((type, index) =>
                    <MenuItem key={index} value={type.value}>{type.value}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
            {type === data.TYPES.QUENCH_AND_TEMPER && (
              <>
                <Grid item container spacing={3}>
                  <Grid item xs={4}>
                    <h3>Temper</h3>
                  </Grid>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item container spacing={1} xs={4} alignItems="flex-end">
                    <Grid item xs={8}>
                      <Input
                        label='Temperature'
                        name='secondTemp'
                        value={secondTemp}
                        required
                        error={Boolean(errors.secondTemp) && touched.secondTemp}
                        errorMessage={errors.secondTemp}
                        onChange={(e) => change("secondTemp", e)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        required
                        value={secondTempUnits}
                        name='secondTempUnits'
                        error={Boolean(errors.secondTempUnits) && touched.secondTempUnits}
                        onChange={(event) => change("secondTempUnits", event)}
                      >
                        <MenuItem key="celsius" value="celsius">°C</MenuItem>
                        <MenuItem key="fahrenheit" value="fahrenheit">°F</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      value={secondCoolingMedium}
                      name='secondCoolingMedium'
                      label='Cooling medium'
                      required
                      error={Boolean(errors.secondCoolingMedium) && touched.secondCoolingMedium}
                      onChange={(event) => change("secondCoolingMedium", event)}
                    >
                      {data.coolingMedium.map((type, index) =>
                        <MenuItem key={index} value={type.value}>{type.value}</MenuItem>
                      )}
                    </Select>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item container alignItems="flex-end" spacing={3}>
              <Grid item onClick={() => setAdvancedSettingsOpen(!advancedSettingsOpen)}>
                <h3 className={classes.advancedSettingsButton}>
                  Advanced settings{advancedSettingsOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={advancedSettingsOpen} timeout="auto" unmountOnExit>
                {type === data.TYPES.QUENCH_AND_TEMPER && (
                  <Grid item container spacing={3}>
                    <Grid item xs={4}>
                      <h3>Quench</h3>
                    </Grid>
                  </Grid>
                )}
                <Grid item container spacing={3} alignItems="flex-end">
                  <Grid item xs={4}>
                    <Select
                      value={unitOfHoldingTime}
                      name='unitOfHoldingTime'
                      label='Unit to measure the holding time'
                      error={Boolean(errors.unitOfHoldingTime) && touched.unitOfHoldingTime}
                      onChange={(event) => change("unitOfHoldingTime", event)}
                    >
                      <MenuItem key="time" value="time">Time</MenuItem>
                      <MenuItem key="thickness" value="thickness">Thickness</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item container spacing={1} xs={4} alignItems="flex-end">
                    <Grid item xs={8}>
                      <Input
                        label='Holding time'
                        name='holdingTime'
                        value={holdingTime}
                        type='number'
                        error={Boolean(errors.holdingTime) && touched.holdingTime}
                        errorMessage={errors.holdingTime}
                        onChange={(e) => change("holdingTime", e)} />
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        required={holdingTime}
                        value={holdingTimeUnits}
                        name='holdingTimeUnits'
                        error={Boolean(errors.holdingTimeUnits) && touched.holdingTimeUnits}
                        onChange={(event) => change("holdingTimeUnits", event)}
                      >
                        <MenuItem value="sec">sec</MenuItem>
                        <MenuItem value="min">min</MenuItem>
                        <MenuItem value="hr">hr</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  {unitOfHoldingTime === "thickness" && (
                    <>
                      <Grid item><h3 className={classes.everyText}>every</h3></Grid>
                      <Grid item container spacing={1} xs={3} alignItems="flex-end">
                        <Grid item xs={8}>
                          <Input
                            label='Thickness'
                            name='holdingThickness'
                            value={holdingThickness}
                            type='number'
                            error={Boolean(errors.holdingThickness) && touched.holdingThickness}
                            errorMessage={errors.holdingThickness}
                            onChange={(e) => change("holdingThickness", e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            required={holdingThickness}
                            value={holdingThicknessUnits}
                            name='holdingThicknessUnits'
                            error={Boolean(errors.holdingThicknessUnits) && touched.holdingThicknessUnits}
                            onChange={(event) => change("holdingThicknessUnits", event)}
                          >
                            <MenuItem value="in">in</MenuItem>
                            <MenuItem value="mm">mm</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                {type === data.TYPES.QUENCH_AND_TEMPER && (
                  <>
                    <Grid item container spacing={3}>
                      <Grid item xs={4}>
                        <h3>Temper</h3>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={3} alignItems="flex-end">
                      <Grid item xs={4}>
                        <Select
                          value={secondUnitOfHoldingTime}
                          name='secondUnitOfHoldingTime'
                          label='Unit to measure the holding time'
                          error={Boolean(errors.secondUnitOfHoldingTime) && touched.secondUnitOfHoldingTime}
                          onChange={(event) => change("secondUnitOfHoldingTime", event)}
                        >
                          <MenuItem key="time" value="time">Time</MenuItem>
                          <MenuItem key="thickness" value="thickness">Thickness</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item container spacing={1} xs={4} alignItems="flex-end">
                        <Grid item xs={8}>
                          <Input
                            label='Holding time'
                            name='secondHoldingTime'
                            value={secondHoldingTime}
                            type='number'
                            error={Boolean(errors.secondHoldingTime) && touched.secondHoldingTime}
                            errorMessage={errors.secondHoldingTime}
                            onChange={(e) => change("secondHoldingTime", e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            required={secondHoldingTime}
                            value={secondHoldingTimeUnits}
                            name='secondHoldingTimeUnits'
                            error={Boolean(errors.secondHoldingTimeUnits) && touched.secondHoldingTimeUnits}
                            onChange={(event) => change("secondHoldingTimeUnits", event)}
                          >
                            <MenuItem value="sec">sec</MenuItem>
                            <MenuItem value="min">min</MenuItem>
                            <MenuItem value="hr">hr</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      {secondUnitOfHoldingTime === "thickness" && (
                        <>
                          <Grid item><h3 className={classes.everyText}>every</h3></Grid>
                          <Grid item container spacing={1} xs={3} alignItems="flex-end">
                            <Grid item xs={8}>
                              <Input
                                label='Thickness'
                                name='secondHoldingThickness'
                                value={secondHoldingThickness}
                                type='number'
                                error={Boolean(errors.secondHoldingThickness) && touched.secondHoldingThickness}
                                errorMessage={errors.secondHoldingThickness}
                                onChange={(e) => change("secondHoldingThickness", e)} />
                            </Grid>
                            <Grid item xs={4}>
                              <Select
                                required={secondHoldingThickness}
                                value={secondHoldingThicknessUnits}
                                name='secondHoldingThicknessUnits'
                                error={Boolean(errors.secondHoldingThicknessUnits) && touched.secondHoldingThicknessUnits}
                                onChange={(event) => change("secondHoldingThicknessUnits", event)}
                              >
                                <MenuItem value="in">in</MenuItem>
                                <MenuItem value="mm">mm</MenuItem>
                              </Select>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item container spacing={3} alignItems="flex-end">
                  <Grid item xs={4}>
                    <Input
                      startAdornment="+"
                      label='Tolerance on temperature'
                      name='tolerancePlus'
                      value={tolerancePlus}
                      error={Boolean(errors.tolerancePlus) && touched.tolerancePlus}
                      errorMessage={errors.tolerancePlus}
                      onChange={(e) => change("tolerancePlus", e)}
                    />
                  </Grid>
                  <Grid item style={{padding: 0}} className={classes.slash}><h3>/&nbsp;</h3></Grid>
                  <Grid item xs={4} style={{paddingLeft: 0, paddingRight: 24}}>
                    <Input
                      startAdornment="-"
                      name='toleranceMinus'
                      value={toleranceMinus}
                      error={Boolean(errors.toleranceMinus) && touched.toleranceMinus}
                      errorMessage={errors.toleranceMinus}
                      onChange={(e) => change("toleranceMinus", e)}
                    />
                  </Grid>
                  <Grid item xs={2} style={{paddingLeft: 0, paddingRight: 24}}>
                    <Select
                      required={toleranceMinus || tolerancePlus}
                      value={toleranceUnits}
                      name='toleranceUnits'
                      error={Boolean(errors.toleranceUnits) && touched.toleranceUnits}
                      onChange={(event) => change("toleranceUnits", event)}
                    >
                      <MenuItem key="°C" value="°C">°C</MenuItem>
                      <MenuItem key="°F" value="°F">°F</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid item container spacing={3} alignItems="flex-end">
                  <Grid item xs={4}>
                    <Input
                      label='Heating rate min'
                      name='heatingRateMin'
                      value={heatingRateMin}
                      error={Boolean(errors.heatingRateMin) && touched.heatingRateMin}
                      errorMessage={errors.heatingRateMin}
                      onChange={(e) => change("heatingRateMin", e)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      label='Heating rate max'
                      name='heatingRateMax'
                      value={heatingRateMax}
                      error={Boolean(errors.heatingRateMax) && touched.heatingRateMax}
                      errorMessage={errors.heatingRateMax}
                      onChange={(e) => change("heatingRateMax", e)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      required={heatingRateMin || heatingRateMax}
                      value={heatingRateUnits}
                      name='heatingRateUnits'
                      error={Boolean(errors.heatingRateUnits) && touched.heatingRateUnits}
                      onChange={(event) => change("heatingRateUnits", event)}
                    >
                      <MenuItem value="°C/h">°C/h</MenuItem>
                      <MenuItem value="°F/h">°F/h</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid item container spacing={3} alignItems="flex-end">
                  <Grid item xs={4}>
                    <Input
                      label='Cooling rate min'
                      name='coolingRateMin'
                      value={coolingRateMin}
                      error={Boolean(errors.coolingRateMin) && touched.coolingRateMin}
                      errorMessage={errors.coolingRateMin}
                      onChange={(e) => change("coolingRateMin", e)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      label='Cooling rate max'
                      name='coolingRateMax'
                      value={coolingRateMax}
                      error={Boolean(errors.coolingRateMax) && touched.coolingRateMax}
                      errorMessage={errors.coolingRateMax}
                      onChange={(e) => change("coolingRateMax", e)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      required={coolingRateMin || coolingRateMax}
                      value={coolingRateUnits}
                      name='coolingRateUnits'
                      error={Boolean(errors.coolingRateUnits) && touched.coolingRateUnits}
                      onChange={(event) => change("coolingRateUnits", event)}
                    >
                      <MenuItem value="°C/h">°C/h</MenuItem>
                      <MenuItem value="°F/h">°F/h</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12} sx={{paddingTop: 0}}>
              <FilesUploader
                name="images"
                files={images.map((i) => i.preview_path)}
                onNewFile={(file, push) => push({
                  preview: true,
                  preview_path: file.file.dir + file.file.name
                })}
                changeFile={handleUploadedFile}
              />
            </Grid>
            <Grid item container spacing={3} alignItems="flex-end">
              <Grid item xs={8}>
                <Input
                  multiline
                  rows={3}
                  label='Additional remarks'
                  name='notes'
                  value={notes}
                  onChange={(e) => change("notes", e)}
                />
              </Grid>
            </Grid>
            {isRelatedToCondition ? (
              <Grid item container className={classes.gridRoot} justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <ProducerTestFooter />
            )}
          </Grid>
          );
        }}
      />
    </div>
  );
};

export default withStyles(HeatTreatment, styles);
