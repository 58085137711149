import DimensionsTags from "@core/components/DimensionsTags";
import React from "react";
import {Button} from "@mui/material";

export const columns = [{
  dataIndex: "serialNumber",
  isSorted: true,
  title: "Serial Number",
}, {
  isSorted: true,
  dataIndex: "productType",
  title: "Product Type",
  render: (product) => product.properties.productType.name,
}, {
  isSorted: true,
  dataIndex: "manufacturer",
  title: "Manufacturer",
  render: (product) => product.manufacturer?.name,
}, {
  dataIndex: "heat",
  title: "Heat",
}, {
  dataIndex: "certificationType",
  isSorted: true,
  title: "Certification Type",
}, {
  dataIndex: "nominalDimensions",
  isSorted: false,
  title: "Nominal Dimensions",
  render: (product) => {
    return (
      <DimensionsTags
        dimensions={product.properties.nominalSizes}
      />
    );
  },
},{
  isSorted: true,
  dataIndex: "productionData",
  title: "Production Data",
  render: () => (
    <Button>
      View certificate or product
    </Button>
  ),
}];