import ProducerTestFooter from "@core/components/ProducerTestFooter";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {observer} from "mobx-react";
import * as yup from "yup";
import {map, omit, prop} from "ramda";
import {Button, Grid} from "@mui/material";
import {Formik, FieldArray} from "formik";
import {MuiAutocomplete} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const validationSchema = yup.object().shape({
  elements: yup.array().of(yup.object().shape({
    statement: yup.string().required("Statement is required!"),
  })).min(1, "At least one statement is required!"),
  presetId: yup.string(),
  witnesses: yup.array().of(yup.object()),
  inspectionDate: yup.string(),
  inspectorJobNumber: yup.string(),
  tags: yup.array().of(yup.string()),
  testExecutionDate: yup.string(),
});

const StatementTest = observer(({test, ...props}) => {
  const [presets, setPresets] = useState([]);

  const fetchPresets = async () => {
    const response = await axios.get(ROUTES.STATEMENT_PRESET[ACTIONS.ALL]);

    setPresets(response.data.items);
  };

  useEffect(() => {
    fetchPresets();
  }, []);

  const onSubmit = (values) => {
    props.saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});

    if (props.closeNewTest) props.closeNewTest();
  };

  const initialValues = {
    elements: test.properties.elements || [],
    presetId: test.properties.presetId || "",
    witnesses: test.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || [],
    testExecutionDate: test?.properties.testExecutionDate || ""
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Certificate statements</h1>
        </Grid>
        <Grid item container spacing={3}>
          <Formik
            innerRef={props.formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => {
              const {
                values: {elements},
                setFieldValue,
                setFieldTouched,
              } = props;

              const onChangePreset = (preset) => {
                const statements = map(prop("value"), preset.statements);
                const elements = statements.map((statement) => ({statement}));
                setFieldValue("elements", elements);
                setFieldValue("presetId", preset._id);
              };

              const onRemovePreset = () => {
                setFieldValue("elements", []);
                setFieldValue("presetId", "");
              };

              return (
                <>
                  <Grid item xs={4}>
                    <MuiAutocomplete
                      url={ROUTES.STATEMENT_PRESET[ACTIONS.ALL]}
                      inputProps={{
                        label: "Select preset",
                        name: "presetId",
                      }}
                      formatOptions={(data) => data.items.map((item) => ({label: item.name, value: item._id}))}
                      onChange={(id) => {
                        if (!id) onRemovePreset();
                        else {
                          const preset = presets.find((p) => p._id === id);
                          onChangePreset(preset);
                        }

                        setFieldTouched("presetId", true, false);
                      }}
                    />
                  </Grid>
                  <FieldArray name="elements">
                    {({push, remove}) =>
                      <>
                        {elements.map((el, index) =>
                          <Grid item container spacing={3}>
                            <Grid item xs={8}>
                              <TextField
                                label={`Statement ${index + 1}`}
                                name={`elements.${index}.statement`}
                                required
                              />
                            </Grid>
                            <Grid item xs={1} container alignItems="flex-end">
                              {elements.length > 1 && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => remove(index)}
                                >
                                  Remove
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        )}
                        <Grid item container spacing={3}>
                          <Grid item xs={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => push({statement: ""})}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                        <ProducerTestFooter />
                      </>
                    }
                  </FieldArray>
                </>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
});

export default StatementTest;
