
export const MULTI_SIGN_TABS = {
  AVAILABLE_TO_SIGN: "available",
  NOT_AVAILABLE_TO_SIGN: "not_available",
};

export const MULTI_SIGN_TABS_TITLES = {
  [MULTI_SIGN_TABS.AVAILABLE_TO_SIGN]: "available to sign",
  [MULTI_SIGN_TABS.NOT_AVAILABLE_TO_SIGN]: "not available to sign"
};
