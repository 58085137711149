import AddDataDrawer from "@core/components/AddDataDrawer";
import CreateTests from "@core/components/CreateTests";
import ImportData from "./components/ImportData";
import {Button, ButtonGroup, Tooltip} from "@mui/material";
import React, {useState} from "react";
import {GoChecklist} from "react-icons/go";
import {RiFileAddFill} from "react-icons/ri";
import {useParams} from "react-router-dom";
import useStores from "../../../../../../useStores";
import ApproveMultipleTestsDrawer from "../ApproveMultipleTestsDrawer";

const ActionsGroupButton = () => {
  const {SingleMaterialCampaignStore, NotificationStore} = useStores();

  const [addTestsOpen, setAddTestsOpen] = useState(false);
  const [approveAllTestsOpen, setApproveAllTestsOpen] = useState(false);

  const {id} = useParams();
  const qcp = SingleMaterialCampaignStore.campaign.properties?.product.qcp;

  const createTests = (tests) => {
    const promises = tests.map((test) => SingleMaterialCampaignStore.createTest(id, {
      properties: test.properties,
      type: test.type,
      confidential: test.confidential,
      status: test.status,
      witnesses: test.witnesses,
    }));

    return Promise.all(promises);
  };

  const importData = async ({tests}) => {
    await SingleMaterialCampaignStore.importTests(id, tests);
    NotificationStore.showSuccess("Imported successfully!");
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        size="small"
      >
        <Button
          onClick={() => setAddTestsOpen(true)}
        >
          <RiFileAddFill size={18} />&nbsp;<span>Add tests</span>
        </Button>
        <Tooltip title="Approve multiple tests">
          <Button
            variant="containedGray"
            onClick={() => !approveAllTestsOpen && setApproveAllTestsOpen(true)}
          >
            <GoChecklist size={24} />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <AddDataDrawer
        open={addTestsOpen}
        close={() => setAddTestsOpen(false)}
        CreateTests={CreateTests}
        createTests={createTests}
        assignTests={() => {}}
        onTestsCreated={() => {}}
        ImportData={ImportData}
        importData={importData}
        qcp={qcp}
      />
      <ApproveMultipleTestsDrawer
        open={approveAllTestsOpen}
        close={() => setApproveAllTestsOpen(false)}/>
    </>
  );
};

export default ActionsGroupButton;