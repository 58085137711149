export const columns = [{
  dataIndex: "properties.productType",
  isSorted: "true",
  title: "Product type",
  render: (product) =>  product.properties.productType.name,
}, {
  isSorted: "true",
  dataIndex: "manufacturer",
  title: "Manufacturer",
  render: (product) => product.manufacturer?.name,
}, {
  isSorted: "true",
  dataIndex: "heat",
  title: "Heat",
}, {
  dataIndex: "serialNumber",
  title: "Serial Number",
}, {
  dataIndex: "certificationType",
  isSorted: "true",
  title: "Certification Type",
}];