import React, {useRef} from "react";
import {Grid} from "@mui/material";
import {Input} from "@core/components/Form";
import moment from "moment/moment";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const Date = ({type, label, value, onChange, format, classes}) => {
  const dateRef = useRef({});

  const date = dateRef.current.value || value;

  return (
    <Input
      inputProps={{ref: dateRef}}
      type={type}
      label={label}
      value={value}
      onChange={onChange}
      startAdornmentWrapperClass={classes.startAdornmentWrapperClass}
      startAdornment={
        <Grid
          container
          position="absolute"
          alignItems="center"
          width="auto"
          height="100%"
          backgroundColor="white"
          left="7px"
          top="0"
        >
          <Grid item marginRight={2}>
            {date ? moment(date).format(format) : format}
          </Grid>
        </Grid>
      }
    />
  );
};

export default withStyles(Date, styles);