import React, {useEffect, useMemo, useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import {
  columns,
} from "@core/components/MultipleTestSigning/components/MultipleSigningChoose/data";
import TableNavigation from "@core/components/TableNavigation";
import {map, prop, uniqBy, values} from "ramda";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import useStores from "../../../../../../useStores";
import {useParams, useRouteMatch} from "react-router-dom";
import {getRowsPerPage, setRowsPerPage} from "@core/helpers";
import {observer} from "mobx-react";
import {MULTI_SIGN_TABS, MULTI_SIGN_TABS_TITLES} from "@core/constants/multiSignTestsTabs";
import {SMC_MULTI_SIGN_PREFIX} from "./constants";

function ChooseTests({selectedTests, setSelectedTests, moveToNextStep}) {
  const {SingleMaterialCampaignStore} = useStores();
  const {allTests} = SingleMaterialCampaignStore;

  const match = useRouteMatch();
  const customFakePath = `${match.path}/${SMC_MULTI_SIGN_PREFIX}`;
  const {id: campaignId} = useParams();
  const savedSelectedColumns = useMemo(() => JSON.parse(localStorage.getItem(match.path)), []);
  const selectedColumns = useMemo(() => savedSelectedColumns || map(prop("dataIndex"), columns), []);
  const [currentView, setCurrentView] = useState(MULTI_SIGN_TABS.AVAILABLE_TO_SIGN);
  const availableToSignQueryParam = currentView === MULTI_SIGN_TABS.AVAILABLE_TO_SIGN;

  const selectedIds = selectedTests.map((test) => test._id);

  const loadTests = (queryParams) => {
    SingleMaterialCampaignStore.allTests.load({
      campaignId, availableToSign: availableToSignQueryParam, ...queryParams
    });
  };

  useEffect(() => {
    loadTests({offset: 0, limit: getRowsPerPage(customFakePath)});
  }, []);

  const onSelect = (ids) => {
    const oldSelected = selectedTests.filter((test) => ids.includes(test._id));
    const newSelected = allTests.data.filter((test) => ids.includes(test._id));

    const uniqSelected = uniqBy(prop("_id"), [...oldSelected, ...newSelected]);

    setSelectedTests(uniqSelected);
  };

  const onSubmit = () => {
    moveToNextStep();
  };

  const onOffsetChange = (offset) => {
    loadTests({offset});
  };

  const onLimitChange = (limit) => {
    loadTests({
      limit,
      offset: 0
    });
    setRowsPerPage(customFakePath, limit);
  };

  const onViewChange = (view) => {
    if (currentView === view) {
      return;
    }

    setCurrentView(view);
    loadTests({
      offset: 0,
      availableToSign: view === MULTI_SIGN_TABS.AVAILABLE_TO_SIGN
    });
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Box>
            <TableNavigation
              titles={MULTI_SIGN_TABS_TITLES}
              views={values(MULTI_SIGN_TABS)}
              currentView={currentView}
              query="view"
              onClick={onViewChange}
            />
            <Table
              setSelectedItems={onSelect}
              selectedItems={selectedIds}
              selectedColumns={selectedColumns}
              allowRowSelection={currentView === MULTI_SIGN_TABS.AVAILABLE_TO_SIGN}
              items={allTests.data}
              columns={columns}
              isLoaded={!allTests.status.loading}
              total={allTests.total}
            >
              <TableFooter
                isLoaded={!allTests.status.loading}
                items={allTests.data}
                total={allTests.total}
                limit={allTests.fetch?.limit}
                offset={allTests.fetch?.offset}
                usesUrlQueryParams={false}
                selectedItems={selectedTests}
                onOffsetChange={onOffsetChange}
                onLimitChange={onLimitChange}
              />
            </Table>
          </Box>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="secondaryNewDesign"
              size="small"
              disabled={!selectedTests.length}
              onClick={onSubmit}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default observer(ChooseTests);