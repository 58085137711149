import {ROUTES} from "@core/api/routes";
import {ConfirmationOnUrlChangeContext} from "@core/components/ConfirmationOnUrlChange/context";
import {ACTIONS} from "@core/constants/api";
import modules from "@core/constants/modules";
import {SHOULD_BLOCK_DEFAULT} from "@core/constants/shouldBlockDefault";
import {omitEmptyFields} from "@core/helpers";
import axios from "axios";
import {Formik} from "formik";
import {observer} from "mobx-react-lite";
import {equals} from "ramda";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import useStores from "../../useStores";
import DefiningStartingMaterialForm from "./components/DefiningStartingMaterialForm";
import UptimeLikeFormLayout from "@core/components/UptimeLikeFormLayout";
import {validationSchema, initialValuesDefault} from "./constants";
import {getInitialValues} from "./services";
import CampaignDetailsFields from "./components/CampaignDetailsFields";
import DefineEndProduct from "./components/DefineEndProduct";
import SubmitForm from "./components/SubmitForm";
import {useParams, useHistory} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {Grid} from "@mui/material";
import {STATUSES} from "@core/constants/singleMaterialCampaign";

const SingleMaterialCampaignForm = () => {
  const [initialValues, setInitialValues] = useState(initialValuesDefault);

  const formRef = useRef();
  const {id} = useParams();
  const history = useHistory();
  const editState = useMemo(() => !!id, [id]);
  const [isLoading, setIsLoading] = useState(!!editState);

  const {TransferStore, NotificationStore} = useStores();

  const {setShouldBlock} = useContext(ConfirmationOnUrlChangeContext);

  const shouldBlock = useCallback(() => {
    return !equals(formRef.current?.values, initialValues) && !isLoading;
  }, [initialValues, isLoading]);

  useEffect(() => {
    setShouldBlock(() => shouldBlock);

    return () => {
      setShouldBlock(() => SHOULD_BLOCK_DEFAULT);
    };
  }, [initialValues, isLoading]);

  useEffect(() => {
    TransferStore.getTransfersReceivers([modules.PRODUCER, modules.END_OWNER]);

    if (editState) {
      const setValuesAsync = async () => {
        const {data} = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.SINGLE](id));

        if(data.status === STATUSES.ACTIVE) history.push(`/single-material-campaigns/${data._id}`);
        
        setValues(data);
      };
      setValuesAsync();
    }
  }, []);

  const setValues = (data) => {
    const initialValues = getInitialValues(data);
    setInitialValues(initialValues);
    setIsLoading(false);
  };

  const createFromDraft  = async (campaign) => {
    const {data} = await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.CREATE_FROM_DRAFT](id), campaign);

    return data;
  };

  const create = async (campaign) => {
    const {data} = await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.CREATE], campaign);

    return data;
  };

  const createDraft = async (campaign) => {
    const {data} = await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN_DRAFT[ACTIONS.CREATE], campaign);

    return data;
  };

  const updateDraft = async (campaign) => {
    const {data} = await axios.patch(ROUTES.SINGLE_MATERIAL_CAMPAIGN_DRAFT[ACTIONS.UPDATE](id), campaign);

    return data;
  };

  const getFormattedData = (data) => {
    const {name, type, certificationType, purchaseOrder, manufacturingLocation, properties, singleProducts} = data;

    const campaign = omitEmptyFields({
      name,
      type,
      certificationType,
      purchaseOrder: purchaseOrder._id,
      manufacturingLocation,
      properties: {
        product: {
          qcp: properties.product.qcp,
          productType: {
            name: properties.product.productType.name,
            internalId: properties.product.productType.internalName,
            properties: properties.product.productType.properties,
          },
          materialSpecifications: properties.product.materialSpecifications,
          nominalSizes: properties.product.nominalSizes,
        },
        statistics: properties.statistics,
      },
    });

    return {...campaign, singleProducts};
  };
  
  const saveDraft = async (values) => {
    setIsLoading(true);
    const data = getFormattedData(values);

    if(id) {
      const draft = await updateDraft(data);
      setValues(draft);

      NotificationStore.showSuccess("Draft campaign updated successfully!");
    } else {
      const draft = await createDraft(data);

      NotificationStore.showSuccess("Draft campaign created successfully!");
      history.push(`/single-material-campaigns/create/${draft._id}`);
    }
  };

  const onSubmit = async (values) => {
    const data = getFormattedData(values);

    setIsLoading(true);

    const handler = id ? createFromDraft : create;
    const campaign = await handler(data);

    history.push(`/single-material-campaigns/${campaign._id}`);

    NotificationStore.showSuccess("Single product campaign created successfully!");
  };
  
  if(isLoading) {
    return (
      <Grid container justifyContent="center" paddingTop={7}>
        <CircularProgress size={48}/>
      </Grid>
    );
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {() => {
        return (
          <UptimeLikeFormLayout
            title={"Create campaign"}
            formRows={
              [
                {
                  sectionDescription: {
                    title: "Campaign details",
                    text: "A campaign follows the manufacturing of one QCP/ITP item. Connecting a campaign to a PO will enable the customer to track progress."
                  },
                  sectionForm: <CampaignDetailsFields/>
                },
                {
                  sectionDescription: {
                    title: "Define starting material situation",
                    text: "Choose if you are creating new material or if you have existing base materials (e.g. plate) that you will convert into a new product (e.g. pipe). To provide a progress estimate, you can estimate to amount to heats you intent to produce."
                  },
                  sectionForm: <DefiningStartingMaterialForm saveDraft={saveDraft} />
                },
                {
                  sectionDescription: {
                    title: "Define end product",
                    text: "Define to which material specification(s) and grade(s) the end product will manufactured including nominal sizes. Choosing 3.2 certification will require all underlying tests to be signed by a third party inspector."
                  },
                  sectionForm: <DefineEndProduct />
                },
              ]
            }
            displayValidationErrors
            formFooter={<SubmitForm saveDraft={saveDraft} />}
          />
        );
      }}
    </Formik>
  );
};

export default observer(SingleMaterialCampaignForm);