import TextField from "@core/components/FormikTextField";
import {CERTIFICATION_TYPES} from "@core/constants/certificationTypes";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {groupBy, indexBy, prop, keys} from "ramda";
import {Button, Grid, MenuItem} from "@mui/material";
import SelectField from "@core/components/FormikSelect";
import {END_PRODUCT, initialValuesDefault, SPECIFICATION} from "../../constants";
import {useFormikContext} from "formik";
import ProductTypesAutocomplete from "@core/components/ProductTypeAutocomplete";
import useStores from "../../../../useStores";
import {getLocationAddress, getQcpVersions} from "@core/helpers";
import QcpSideBar from "@core/components/QcpSideBar";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import MaterialSpecifications from "./MaterialSpecifications";
import EndProducts from "./EndProducts";

function DefineEndProduct() {
  const [qcpToView, setQcpToView] = useState(null);
  const {UserStore, QcpStore} = useStores();
  const {setFieldValue, setFieldTouched, values, touched, isSubmitting} = useFormikContext();

  const {mainLocation = {}, locations = []} = UserStore.user.data.company ?? {};
  
  useEffect(() => {
    QcpStore.qcps.load({limit: 100, offset: 0});
  }, []);

  const qcps = QcpStore.qcps.data;

  const qcpsById = useMemo(() => indexBy(prop("_id"), qcps), [qcps.length]);

  const qcpVersions = useMemo(() => getQcpVersions(qcps, values.properties.product.qcpName), [qcps.length, values.properties.product.qcpName]);

  const usedNorms = useMemo(
    () => values.properties.product.materialSpecifications.map((specification) => specification.norm),
    [values.properties.product.materialSpecifications]
  );

  const {norms} = useFetchedCertificateNorms();

  const getEndProduct = useCallback((properties) => {
    return properties.reduce((acc, property) => ({...acc, [property.name]: ""}), END_PRODUCT);
  }, []);

  const getFormattedSpecifications = useCallback((specifications) => {
    const groupedByNorm = groupBy(prop("norm"), specifications);

    return keys(groupedByNorm).map((norm) => ({
      norm,
      grades: groupedByNorm[norm].map(({grade}) => grade),
    }));
  }, []);

  const getQcpEndProduct = useCallback((properties, nominalQuantity) => {
    return properties.reduce((acc, {name, value}) => {
      acc[name] = value;

      return acc;
    }, {quantity: nominalQuantity});
  }, []);

  const removeQcpValues = useCallback(() => {
    setFieldValue("manufacturingLocation", "");
    setFieldValue("certificationType", "");
    setFieldValue("properties.product.materialSpecifications",  [SPECIFICATION]);
    setFieldValue("properties.product", initialValuesDefault.properties.product);
  }, []);

  useEffect(() => {
    if(!touched.properties?.product.qcpName) return;

    setFieldValue("properties.product.qcp", qcpVersions.qcpItems[0]?._id);
  }, [values.properties.product.qcpName]);
 
  useEffect(() => {
    if(!touched.properties?.product.qcpName) return;

    if(!values.properties.product.qcp) {
      removeQcpValues();

      return;
    }

    const qcp = qcpsById[values.properties.product.qcp] || {};

    setFieldValue("manufacturingLocation", qcp.properties.manufacturingLocation);
    setFieldValue("certificationType", qcp.properties.certificationType);

    const specifications = getFormattedSpecifications(qcp.properties.materialSpecifications);

    setFieldValue("properties.product.materialSpecifications", specifications);
    setFieldValue("properties.product.productType", {...qcp.properties.productType, _id: qcp.properties.productType.internalName});

    const endProduct = getQcpEndProduct(qcp.properties.productType.properties, qcp.properties.productType.nominalQuantity);

    setFieldValue("properties.product.nominalSizes", [endProduct]);
  }, [values.properties.product.qcp]);

  useEffect(() => {
    if(!values.properties.product.productType._id || values.properties.product.qcp) return;

    const endProducts = getEndProduct(values.properties.product.productType.properties);
    setFieldValue("properties.product.nominalSizes", [endProducts]);
  }, [values.properties.product.productType._id]);

  return (
    <>
      <Grid container flexWrap="wrap" rowGap={3}>
        <Grid item xs={12} container columnGap={4}>
          <Grid item flex={1}>
            <SelectField
              label='QCP/ITP'
              name='properties.product.qcpName'
              disabled={isSubmitting}
            >
              {qcpVersions.qcps.map((qcp) => (
                <MenuItem key={qcp.name} value={qcp.name}>
                  {qcp.name}
                </MenuItem>
              ))}
              <MenuItem value={undefined}>N/A</MenuItem>
            </SelectField>
          </Grid>
          <Grid item flex={1}>
            <Grid container columnGap={4} justifyContent="space-between">
              <Grid item xs>
                <SelectField
                  required={values.properties.product.qcpName}
                  label='QCP/ITP Item'
                  name='properties.product.qcp'
                  disabled={isSubmitting || !values.properties.product.qcpName}
                >
                  {qcpVersions.qcpItems.map((qcp) => (
                    <MenuItem key={qcp._id} value={qcp._id}>
                      {qcp.qcpItem}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item alignSelf="flex-end">
                <Button
                  disabled={!values.properties.product.qcp}
                  onClick={() => setQcpToView(`${values.properties.product.qcpName} Rev. ${qcpsById[values.properties.product.qcp].revision}`)}
                  variant="contained"
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container columnGap={4}>
          <Grid item flex={1}>
            <SelectField
              name='certificationType'
              label="Certification type"
              required
              disabled={isSubmitting || values.properties.product.qcp}
            >
              {CERTIFICATION_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item flex={1}>
            <SelectField
              name='manufacturingLocation'
              label="Manufacturing location"
              required
              disabled={isSubmitting || values.properties.product.qcp}
            >
              <MenuItem key={mainLocation._id} value={mainLocation._id}>
                {getLocationAddress(mainLocation)}
              </MenuItem>
              {locations.map((location) => (
                <MenuItem key={location._id} value={location._id}>
                  {getLocationAddress(location)}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <MaterialSpecifications norms={norms} usedNorms={usedNorms}/>
        </Grid>
        
        <Grid item xs={12} container>
          <Grid item xs={4}>
            {values.properties.product.qcp ? (
              <TextField
                disabled
                required
                label="Product type"
                name="properties.product.productType.internalName"
              />
            ) : (
              <ProductTypesAutocomplete
                formatOptions={(data) => data.items.map((item) => ({label: item.internalName, value: item}))}
                required
                inputProps={{
                  label: "Product type",
                  name: "properties.product.productType.internalName",
                  disabled: isSubmitting,
                  required: true,
                }}
                onChange={(product) => {
                  setFieldValue("properties.product.productType", product);
                  setFieldTouched("properties.product.productType", true, false);
                }}
              />
            )}
          </Grid>
        </Grid>
        
        <Grid item xs={12} container>
          <EndProducts getEndProduct={getEndProduct}/>
        </Grid>
        
        <QcpSideBar
          open={!!qcpToView}
          close={() => setQcpToView(null)}
          qcp={qcpToView}
        />
      </Grid>
    </>
  );
}

export default observer(DefineEndProduct);