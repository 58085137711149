export const FEATURES = {
  PARSE_CSV: "Parse CSV file",
  PARSE_XLS: "Parse XLSX, XLS files",
  CAMPAIGN: "Campaign",
  PARSE_ZIP: "Parse zip file",
  ZENDESK: "Zendesk Widget",
  SINGLE_MATERIAL_CAMPAIGN: "Single Material Campaigns",
  ORDER_IMPORT: "Order Import",
  QCP: "Qcp",
  PERFORM_REQUEST_ON_EACH_TAB: "Perform request on each tab",
  BUNDLE_CAMPAIGNS: "Bundle Campaigns",
};

export const EXT_NAMES_BY_FEATURE = {
  [FEATURES.PARSE_CSV]: [".csv"],
  [FEATURES.PARSE_XLS]: [".xls", ".xlsx"],
  [FEATURES.PARSE_ZIP]: [".zip"]
};