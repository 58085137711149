import React from "react";
import {MenuItem, Grid, FormControl} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import {omit} from "ramda";
import * as yup from "yup";
import {Input, MultipleSelect, Select} from "@core/components/Form";
import styles from "./styles";
import {TEST_RESULTS} from "@core/constants/testResults";
import {
  testStandards,
  acceptances,
  conditions,
  currentTypes,
  particleTypes,
  particleVisibilities,
  solutions,
  concentrations,
  cleanings,
  demagnetizations,
  examinedSurfaces
} from "./data";
import IdentificationOfInspectedItems from "../../IdentificationOfInspectedItems";
import {isAcceptable} from "@core/helpers/tests";
import MuiSelect from "@core/components/MuiSelect";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";

const MagneticParticleTest = ({
  classes,
  test,
  certificate,
  saveTest,
  isProducerTest,
  inspectedCertificates,
  formRef
}) => {
  const validationSchema = yup.object().shape({
    testStandard: yup.string().required("Test standard is required!"),
    acceptance: yup.string().required("Acceptance is required!"),
    condition: yup.string().required("Condition is required!"),
    result: yup.string().required("Result is required!"),
    materialObj: yup.object().shape({
      particles: yup.string(),
      type: yup.string().required("Type is required!"),
      visibility: yup.string().required("Visibility is required!"),
      solution: yup.string().when("type", {
        is: "Wet particles",
        then: yup.string().required("Solution is required!")
      }),
      concentration: yup.string().when("type", {
        is: "Wet particles",
        then: yup.string().required("Concentration is required!")
      })
    }),
    technique: yup.object().shape({
      current: yup.string(),
      residualMagnetism: yup.string(),
      currentType: yup.string().required("Current type is required!"),
      demagnetization: yup.string().required("Demagnetization is required!"),
      examinedSurface: yup.string().required("Examined surface is required!")
    }),
    cleaning: yup.object().shape({
      before: yup.string().required("Cleaning before examination is required!"),
      after: yup.string().required("Cleaning after examination is required!")
    }),
    additionalRemarks: yup.string(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const certificateNorm = certificate?.properties?.norm;

  const initialValues = {
    testStandard: test?.properties.testStandard || certificateNorm || "",
    acceptance: test?.properties.acceptance || "",
    condition: test?.properties.condition || "",
    result: test?.properties.result || "",
    materialObj: test?.properties.materialObj || {
      particles: "",
      type: "",
      visibility: "",
      solution: "",
      concentration: ""
    },
    technique: test?.properties.technique || {
      current: "",
      currentType: "",
      demagnetization: "",
      examinedSurface: [],
      residualMagnetism: ""
    },
    cleaning: test?.properties.cleaning || {
      before: "",
      after: ""
    },
    additionalRemarks: test?.properties.additionalRemarks || "",
    witnesses: test?.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || [],
    testExecutionDate: test?.properties.testExecutionDate || ""
  };

  const onSave = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);
  };

  return (
    <div>
      <Formik
        validateOnMount
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSave}
        render={(props) => {
          const {
            values: {
              acceptance,
              condition,
              result,
              materialObj: {particles, type, solution, concentration, visibility},
              technique: {current, currentType, demagnetization, examinedSurface, residualMagnetism},
              cleaning: {before, after},
              additionalRemarks,
            },
            handleChange,
            setFieldTouched,
            setFieldValue,
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          const changeMultiple = (name, e) => {
            setFieldValue(name, e);
            setFieldTouched(name, true, false);
          };

          const acceptable = isAcceptable(result);

          return <>
            {isProducerTest && (
              <Grid container spacing={3} marginBottom={4}>
                <Grid item xs={12}>
                  <h1>Magnetic Particle Examination</h1>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={testStandards}
                />
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <Select
                  value={acceptance}
                  label="Acceptance criteria"
                  name="acceptance"
                  required
                  onChange={(e) => change("acceptance", e)}
                >
                  {acceptances.map((value, index) =>
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>

            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Select
                  value={condition}
                  label="Examination conditions"
                  name="condition"
                  required
                  onChange={(e) => change("condition", e)}
                >
                  {conditions.map((value, index) =>
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>

            <IdentificationOfInspectedItems
              isProducerTest={isProducerTest}
              inspectedCertificates={inspectedCertificates}
              test={test}
            />

            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12}>
                <h3>Magnetic particles</h3>
              </Grid>
              <Grid item container spacing={3} marginBottom={2}>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Input
                    label="Trade name"
                    name="materialObj.particles"
                    value={particles}
                    onChange={(e) => change("materialObj.particles", e)}
                    onBlur={() => setFieldValue("materialObj.particles", particles.trim())}
                  />
                </Grid>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Select
                    value={type}
                    label="Form"
                    name="materialObj.type"
                    required
                    onChange={(e) => change("materialObj.type", e)}
                  >
                    {particleTypes.map((value, index) =>
                      <MenuItem key={index} value={value}>{value}</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Select
                    value={visibility}
                    label="Type"
                    name="materialObj.visibility"
                    required
                    onChange={(e) => change("materialObj.visibility", e)}
                  >
                    {particleVisibilities.map((value, index) =>
                      <MenuItem key={index} value={value}>{value}</MenuItem>
                    )}
                  </Select>
                </Grid>
                {(type === "Wet particles" && (
                  <>
                    <Grid item xs={isProducerTest ? 4 : 3}>
                      <Select
                        value={solution}
                        label="Solution in"
                        name="materialObj.solution"
                        required
                        onChange={(e) => change("materialObj.solution", e)}
                      >
                        {solutions.map((value, index) =>
                          <MenuItem key={index} value={value}>{value}</MenuItem>
                        )}
                      </Select>
                    </Grid>
                    <Grid item xs={isProducerTest ? 8 : 6}>
                      <Select
                        value={concentration}
                        label="Concentration"
                        name="materialObj.concentration"
                        required
                        onChange={(e) => change("materialObj.concentration", e)}
                      >
                        {concentrations.map((value, index) =>
                          <MenuItem key={index} value={value}>{value}</MenuItem>
                        )}
                      </Select>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12}>
                <h3>Examination technique</h3>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Select
                    value={currentType}
                    label="Current type"
                    name="technique.currentType"
                    required
                    onChange={(e) => change("technique.currentType", e)}
                  >
                    {currentTypes.map((value, index) =>
                      <MenuItem key={index} value={value}>{value}</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Input
                    label="Current value"
                    name="technique.current"
                    value={current}
                    onChange={(e) => change("technique.current", e)}
                    onBlur={() => setFieldValue("technique.current", current.trim())}
                  />
                </Grid>
                <Grid item xs={isProducerTest ? 8 : 6}>
                  <MultipleSelect
                    label={"Examined surface"}
                    required
                    value={examinedSurface}
                    elements={examinedSurfaces}
                    onChange={(e) => changeMultiple("technique.examinedSurface", e)}
                  />
                </Grid>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Select
                    value={demagnetization}
                    label="Demagnetization"
                    name="technique.demagnetization"
                    required
                    onChange={(e) => change("technique.demagnetization", e)}
                  >
                    {demagnetizations.map((value, index) =>
                      <MenuItem key={index} value={value}>{value}</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={isProducerTest ? 8 : 6}>
                  <Input
                    label="Residual magnetism"
                    name="technique.residualMagnetism"
                    value={residualMagnetism}
                    onChange={(e) => change("technique.residualMagnetism", e)}
                    onBlur={() => setFieldValue("technique.residualMagnetism", residualMagnetism.trim())}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12}>
                <h3>Surface cleaning</h3>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Select
                    value={before}
                    label="Before examination"
                    name="cleaning.before"
                    required
                    onChange={(e) => change("cleaning.before", e)}
                  >
                    {cleanings.map((value, index) =>
                      <MenuItem key={index} value={value}>{value}</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Select
                    value={after}
                    label="After examination"
                    name="cleaning.after"
                    required
                    onChange={(e) => change("cleaning.after", e)}
                  >
                    {cleanings.map((value, index) =>
                      <MenuItem key={index} value={value}>{value}</MenuItem>
                    )}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Select
                  value={result}
                  name='result'
                  label='Result'
                  required
                  onChange={(event) => change("result", event)}
                >
                  <MenuItem value={TEST_RESULTS.NO_RECORDABLE_INDICATIONS}>{TEST_RESULTS.NO_RECORDABLE_INDICATIONS}</MenuItem>
                  <MenuItem value={TEST_RESULTS.ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.ACCEPTABLE_INDICATIONS}</MenuItem>
                  <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.NOT_ACCEPTABLE_INDICATIONS}</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <FormControl variant="standard" className={classes.formControl}>
                  <Input
                    label='Additional remarks'
                    name='additionalRemarks'
                    value={additionalRemarks}
                    onChange={(e) => change("additionalRemarks", e)}
                    multiline
                    rows={5}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {isProducerTest ? (
              <ProducerTestFooter />
            ) : (
              <LabTestFooter
                onSubmit={saveTest}
                result={acceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE}
              />
            )}
          </>;
        }}
      />
    </div>
  );
};

export default withStyles(MagneticParticleTest, styles);
