import TextField from "@core/components/FormikTextField";
import MuiSelect from "@core/components/MuiSelect";
import {TEST_RESULTS} from "@core/constants/testResults";
import {getIsWithinRequirements} from "@core/helpers";
import * as yup from "yup";
import {times, map, omit, partition} from "ramda";
import {TEST_STANDARDS, DEFAULT_ELEMENTS_AMOUNT, DEFAULT_TEST_RESULTS, TEST_RESULT} from "./constants";
import {Grid} from "@mui/material";
import React from "react";
import {Formik} from "formik";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestResults from "./components/TestResults";
import Footer from "./components/Footer";

const PositiveMaterialIdentification = ({test, closeNewTest, saveTest, formRef, isProducerTest, client: owner, user}) => {
  const {witnesses, inspectorJobNumber, inspectionDate, properties} = test ?? {};

  const {
    client,
    laboratory,
    testStandard,
    testResults,
    acceptanceCriteria,
    numberOfInspectedElements,
    specimenIds,
    tags,
    testExecutionDate
  } = properties ?? {};

  const validationSchema = yup.object().shape({
    client: isProducerTest ? yup.string() : yup.string().required("Client field is required!"),
    laboratory: isProducerTest ? yup.string() : yup.string().required("Laboratory field is required!"),
    testStandard: yup.string(),
    numberOfInspectedElements: yup.number().min(1, "Number of controlled elements should be > 1").required("Number of controlled elements is required!"),
    specimenIds: yup.array().of(yup.string().required("Specimen ID is required!")).min(1).required(),
    testResults: yup.array().of(yup.object().shape({
      element: yup.string().required("Element is required!"),
      values: yup.array().of(yup.number().min(0).typeError("Value is required!").required("Value is required!")).min(1).required(),
      formula: yup.string(),
    })),
    acceptanceCriteria: yup.lazy(((item) => {
      return yup.object().shape(map((value) =>
        yup.object().shape({
          min: yup.number().min(0, "Min should be > 0"),
          max: yup.number().min(value.min || 0, `Max should be > ${value.min || 0}`),
        }), item));
    })),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const initialValues = {
    client: !isProducerTest ? client ?? owner.name ?? "" : "",
    laboratory: !isProducerTest ? laboratory ?? user.company.name ?? "" : "",
    testStandard: testStandard ?? "",
    specimenIds: specimenIds ?? [""],
    acceptanceCriteria: acceptanceCriteria ?? {},
    numberOfInspectedElements: numberOfInspectedElements ?? DEFAULT_ELEMENTS_AMOUNT,
    testResults: testResults ?? DEFAULT_TEST_RESULTS,
    witnesses: witnesses.map((witness) => witness.company) ?? [],
    inspectionDate: inspectionDate ?? "",
    inspectorJobNumber: inspectorJobNumber ?? "",
    tags: tags ?? [],
    testExecutionDate: testExecutionDate ?? "",
  };

  const getResult = ({acceptanceCriteria, testResults}) => {
    const isAcceptable = testResults.every((result) => {
      const {min, max} = acceptanceCriteria[result.element] ?? {};

      return result.values.every((value) => getIsWithinRequirements(value, min, max));
    });

    return isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
  };

  const onSubmit = (values, actions) => {
    values.result = getResult(values);

    const formData = omit(["witnesses"], values);

    saveTest(formData, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    closeNewTest && closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({values, setFieldValue, touched}) => {

        return (
          <>
            <Grid container spacing={3}>
              <Grid item container spacing={3}  sx={{maxWidth: "1200px"}}>
                {isProducerTest && (
                  <Grid item xs={12}>
                    <h1>Positive Material Identification Test</h1>
                  </Grid>
                )}
                {!isProducerTest && (
                  <>
                    <Grid item xs={3}>
                      <ClientField
                        isFromProducer={!!owner.name}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        disabled
                        name="laboratory"
                        label="Laboratory"
                        required
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={3}>
                  <MuiSelect
                    name="testStandard"
                    defaultOptions={TEST_STANDARDS}
                    label="Test standard"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    inputProps={{min: 1}}
                    type="number"
                    name="numberOfInspectedElements"
                    label="Number of controlled elements"
                    required
                    onChange={(value) => {
                      if(!touched.numberOfInspectedElements) return;

                      const [withFormula, noFormula] = partition((result) => result.formula, values.testResults);

                      const oldTestResults = noFormula.slice(0, value);
                      const missingElements = value - noFormula.length;
                      const newTestResults = missingElements > 0 ? oldTestResults.concat(times(() => TEST_RESULT, missingElements)) : oldTestResults;

                      setFieldValue("testResults", newTestResults.concat(withFormula));
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TestResults />
              </Grid>
            </Grid>
            <Footer
              isProducerTest={isProducerTest}
              saveTest={saveTest}
              result={getResult(values)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default PositiveMaterialIdentification;