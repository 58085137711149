import TextField from "@core/components/FormikTextField";
import {useFormikContext} from "formik";
import React, {useState} from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import {useParams} from "react-router-dom";
import SelectBaseMaterialsForm from "../../../components/SelectBaseMaterialsForm";

const DefiningStartingMaterialForm = ({saveDraft}) => {
  const [baseMaterialFormOpen, setBaseMaterialFormOpen] = useState(false);
  const {values, setFieldValue, isSubmitting} = useFormikContext();
  
  const {id} = useParams();
  
  const onRadioGroupChange = (e, valueRaw) => {
    const value = JSON.parse(valueRaw);
    setFieldValue("existingMaterial", value);
    setFieldValue("properties.statistics.estimatedHeats", "");
    setFieldValue("properties.statistics.estimatedHeatLots", "");
  };
  
  const setSelectedBaseMaterials = async (ids) => {
    setFieldValue("singleProducts", ids);

    await saveDraft({...values, singleProducts: ids});
  };

  return (
    <Grid item xs={12}>
      <Box sx={{paddingTop: 1}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup
              row
              name="existingMaterial"
              onChange={onRadioGroupChange}
              value={values.existingMaterial}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                disabled={isSubmitting}
                label={(
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    Choose existing material
                  </Box>
                )}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                disabled={isSubmitting}
                label={(
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    Create new material
                  </Box>
                )}
              />
            </RadioGroup>
          </Grid>
          {values.existingMaterial ? (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() => setBaseMaterialFormOpen(true)}
                  >
                    Select base materials now
                  </Button>
                  <SelectBaseMaterialsForm
                    selectedIds={values.singleProducts}
                    baseMaterialFormOpen={baseMaterialFormOpen}
                    setBaseMaterialFormOpen={setBaseMaterialFormOpen}
                    setSelectedIds={setSelectedBaseMaterials}
                    campaignId={id}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <h3>(Estimated) Amount of Heat(s) and Heat Lot(s)</h3>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      required
                      disabled={isSubmitting}
                      label='Heats'
                      name='properties.statistics.estimatedHeats'
                      type="number"
                      inputProps={{min: 0}}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      disabled={isSubmitting}
                      label='Heat lots'
                      name='properties.statistics.estimatedHeatLots'
                      type="number"
                      inputProps={{min: 0}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default DefiningStartingMaterialForm;