import QcpSideBar from "@core/components/QcpSideBar";
import useStores from "../../../../../../useStores";
import ActionsGroupButton from "../ActionsGroupButton";
import React, {useCallback, useState} from "react";
import {observer} from "mobx-react-lite";
import {Badge, Typography, Grid, Divider, Link} from "@mui/material";

const linkStyles = {
  cursor: "pointer",
  color: "inherit",
  textDecoration: "underline",
  textDecorationColor: "#AAA2A2"
};

const GeneralInfo = () => {
  const [qcpOpen, setQcpOpen] = useState(false);

  const {SingleMaterialCampaignStore} = useStores();

  const {certificationType, name, purchaseOrder, properties} = SingleMaterialCampaignStore.campaign || {};
  const {qcp} = properties?.product || {};

  const qcpFullName = `${qcp?.name} Rev. ${qcp?.revision}`;

  const toggleQcp = useCallback(() => {
    setQcpOpen(!qcpOpen);
  }, [qcpOpen]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Badge badgeContent={certificationType} color="primary">
                <Typography variant="h4" sx={{fontSize: "2rem"}}>{name} Campaign</Typography>
              </Badge>
            </Grid>
            <Grid item>
              <ActionsGroupButton />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {!!purchaseOrder?._id && (
              <>
                <Grid item>
                  Customer:&nbsp;
                  <Link sx={linkStyles}>
                    {purchaseOrder.receiver.name}
                  </Link>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  PO:&nbsp;
                  <Link
                    sx={linkStyles}
                    href={`/transfers/${purchaseOrder._id}`}
                    target="_blank"
                  >
                    {purchaseOrder.orderNumber}
                  </Link>
                </Grid>
              </>
            )}
            {!!purchaseOrder?._id && !!qcp?._id && (
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
            )}
            {!!qcp?._id && (
              <Grid item>
                  QCP/ITP:&nbsp;
                <Link
                  sx={linkStyles}
                  onClick={toggleQcp}
                >
                  {qcpFullName}
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <QcpSideBar
        open={qcpOpen}
        close={toggleQcp}
        qcp={qcpFullName}
      />
    </>
  );
};

export default observer(GeneralInfo);