import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
// import {TestService} from "@core/services";
import axios from "axios";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import ResizableDrawer from "@core/components/ResizableDrawer";
import Test from "@core/components/Test";
import DrawerHeader from "@core/components/DrawerHeader";
import useStores from "../../../../../../../../useStores";

// uncomment the code below when ready to implement signatures

const TestSlideOut = ({close, open, testId}) => {
  const [test, setTest] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const {SingleMaterialCampaignStore, NotificationStore} = useStores();
  // const {SingleMaterialCampaignStore, NotificationStore, UserStore} = useStores();
  
  // const campaign = SingleMaterialCampaignStore.campaign;
  // const userId = UserStore.user.data._id;

  useEffect(() => {
    if(!testId) return;

    setIsLoaded(false);
    axios.get(ROUTES.TEST[ACTIONS.SINGLE](testId)).then((response) => {
      setTest(response.data);
      setIsLoaded(true);
    });
  }, [testId]);

  const updateTest = async (data) => {
    await SingleMaterialCampaignStore.updateTest(testId, data);
    setTest({...test, ...data});
    NotificationStore.showSuccess("Test updated successfully");
  };

  const deleteTest = async () => {
    await SingleMaterialCampaignStore.removeTest(testId, test.category);
    close();
    NotificationStore.showSuccess("Test deleted successfully");
  };
  
  return (
    <ResizableDrawer
      open={open}
      close={close}
      keepMounted={false}
    >
      <DrawerHeader
        close={close}
        text="Tests Reports (1)"
      />
      {isLoaded ? (
        <Box
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            padding: 2,
          }}
        >
          <Test
            test={test}
            isLast
            isFirst
            // shareLink={shareLink}
            timeline={false}
            onTestUpdate={updateTest}
            // approveTest={(test) => TestService.approve([test], campaign, userId)}
            // assignInspector={() => TestService.assignInspector([test], {[test._id]: campaign})}
            deleteTest={deleteTest}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </ResizableDrawer>
  );
};

export default observer(TestSlideOut);