import {commonValidationSchema} from "@core/components/TestForms/CorrosionTest/data";
import React, {useEffect} from "react";
import {Grid, MenuItem} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import {useFormikContext} from "formik";
import SelectField from "@core/components/FormikSelect";
import {TEST_RESULTS} from "@core/constants/testResults";
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  ...commonValidationSchema,
  testReactant: yup.string().required("Test solution is required"),
  bendingAngle: yup.number().positive("Bending angle Should be > 0"),
  mandrelDiameter: yup.number().positive("Mandrel diameter Should be > 0"),
  magnification: yup.number().positive("Magnification should be > 0"),
});

const ManualForm = () => {
  const {values, setFieldValue} = useFormikContext();

  useEffect(() => {
    setFieldValue("mandrelDiameter", values.diameter);
  }, [values.diameter]);

  return (
    <Grid item container spacing={3}>
      <Grid item xs={6}>
        <TextField
          disabled
          required
          name="testReactant"
          label="Test solution"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="bendingAngle"
          label="Bending angle"
          type="number"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="mandrelDiameter"
          label="Mandrel diameter"
          type="number"
          endAdornment="MM"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="magnification"
          label="Magnification"
          type="number"
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          name="result"
          label='Test result'
          required
        >
          <MenuItem value={TEST_RESULTS.ABSENCE_OF_CRACKS}>
            {TEST_RESULTS.ABSENCE_OF_CRACKS}
          </MenuItem>
          <MenuItem value={TEST_RESULTS.PRESENCE_OF_CRACKS}>
            {TEST_RESULTS.PRESENCE_OF_CRACKS}
          </MenuItem>
        </SelectField>
      </Grid>
    </Grid>
  );
};

export default ManualForm;