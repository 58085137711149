import {TEST_RESULTS} from "@core/constants/testResults";
import React from "react";
import {MenuItem, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import * as yup from "yup";
import {omit} from "ramda";
import {isAcceptable} from "@core/helpers/tests";
import {Input, Select, MultipleSelect} from "@core/components/Form";
import IdentificationOfInspectedItems from "../../IdentificationOfInspectedItems";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import styles from "./styles";

const VisualTest = ({
  classes,
  test,
  saveTest,
  closeNewTest,
  isProducerTest,
  inspectedCertificates,
  formRef,
  ...props
}) => {
  const validationSchema = yup.object().shape({
    lightIntensity: yup.number()
      .positive("Light intensity should to be > 0")
      .nullable(true)
      .typeError("Light intensity should be a number!"),
    surface: yup.string(),
    examinedSurface: yup.array().required("Examined surface is required!"),
    result: yup.string().required("Result is required!"),
    notes: yup.string(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
    testExecutionDate: yup.string(),
  });

  const initialValues = {
    lightIntensity: test?.properties.lightIntensity || null,
    surface: test?.properties.surface || "Machining",
    examinedSurface: test?.properties.examinedSurface || [],
    result: test?.properties.result || "",
    notes: test?.properties.notes || "",
    witnesses: test?.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || [],
    testExecutionDate: test?.properties.testExecutionDate || ""
  };

  const onSubmit = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);

    if (!closeNewTest) return;

    props.closeNewTest();
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
        validateOnMount
        render={({
          values: {
            lightIntensity,
            surface,
            examinedSurface,
            result,
            notes,
          },
          errors,
          handleChange,
          touched,
          setFieldTouched,
          setFieldValue
        }) => {
          const change = (name, event) => {
            event?.persist && event.persist();
            handleChange(event);
            setFieldTouched(name, true, false);
          };

          const changeMultiple = (name, event) => {
            setFieldValue(name, event);
            setFieldTouched(name, true, false);
          };

          const acceptable = isAcceptable(result);

          return <>
            <Grid container spacing={5} marginBottom={4}>
              {isProducerTest && (
                <Grid item xs={12} pb={0}>
                  <h1>Visual Test</h1>
                </Grid>
              )}
            </Grid>

            <IdentificationOfInspectedItems
              isProducerTest={isProducerTest}
              inspectedCertificates={inspectedCertificates}
              test={test}
            />

            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={12} className={classes.inspectionTitle}>
                <h3>Inspection parameters</h3>
              </Grid>

              <Grid item xs={isProducerTest ? 4 : 3}>
                <Input
                  name="lightIntensity"
                  value={lightIntensity}
                  type="number"
                  endAdornment="lux"
                  error={Boolean(errors.lightIntensity) && touched.lightIntensity}
                  label="Light intensity"
                  onChange={(event) => change("lightIntensity", event)}
                />
              </Grid>

              <Grid item xs={isProducerTest ? 4 : 3}>
                <Select
                  name="surface"
                  value={surface}
                  label='Surface cleaning'
                  onChange={(event) => change("surface", event)}
                >
                  {["Cleaning with solvent", "Pickling", "Machining", "Grinding", "N/A"].map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                </Select>
              </Grid>
            </Grid>

            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <MultipleSelect
                  value={examinedSurface}
                  label='Examined surface'
                  elements={["100% of surface", "Bevel ends", "External surface", "Internal surface", "Welds"]}
                  onChange={(event) => changeMultiple("examinedSurface", event)}
                  required
                />
              </Grid>
            </Grid>

            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Select
                  value={result}
                  name='result'
                  label='Result'
                  required
                  onChange={(event) => change("result", event)}
                >
                  <MenuItem value={TEST_RESULTS.NO_INDICATION}>{TEST_RESULTS.NO_INDICATION}</MenuItem>
                  <MenuItem value={TEST_RESULTS.ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.ACCEPTABLE_INDICATIONS}</MenuItem>
                  <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE_DEFECTS}>{TEST_RESULTS.NOT_ACCEPTABLE_DEFECTS}</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Input
                  label="Notes"
                  rows={4}
                  multiline
                  value={notes}
                  name="notes"
                  onChange={(event) => change("notes", event)}
                  onBlur={() => setFieldValue("notes", notes.trim())}
                />
              </Grid>
            </Grid>
            {isProducerTest ? (
              <ProducerTestFooter />
            ) : (
              <LabTestFooter
                onSubmit={onSubmit}
                result={acceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE}
              />
            )}
          </>;
        }}
      />
    </div>
  );
};

export default withStyles(VisualTest, styles);
