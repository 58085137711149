import React from "react";
import {
  useTheme,
  Box,
} from "@mui/material";
import FiltersList from "./components/FiltersList";
import {COMPONENTS_BY_TAB} from "./constants";

const Content = ({activeTab, activeSubTab, showFilters, isLoaded}) => {
  const theme = useTheme();

  const Component = COMPONENTS_BY_TAB[activeTab];

  return (
    <Box
      sx={{
        padding: theme.spacing(2.5),
      }}
    >
      {showFilters && (
        <FiltersList open={showFilters}/>
      )}
      <Box sx={{marginTop: 2}}>
        {Component && (
          <Component
            activeSubTab={activeSubTab}
            isLoaded={isLoaded}
          />
        )}
      </Box>
    </Box>
  );
};

export default Content;