import React from "react";
import {
  IconButton,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {AiOutlineWarning} from "react-icons/ai";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import {uniq, groupBy, prop} from "ramda";
import {getLocationAddress, mode} from "@core/helpers";
import TableSkeleton from "@core/components/TableSkeleton";
import CertificateDimensionsTags from "@core/components/CertificateDimensionsTags";
import {FILTERS} from "@core/constants/filters";
import styles from "./styles";
import {PAGE_TABS} from "../constants";

const OrderSummary = ({classes, certificates, filters, setFilters, isLoaded}) => {
  const lineItems = uniq(certificates.filter((certificate) => certificate.lineItem).map((certificate) => certificate.lineItem));
  const grouppedByPoItem = groupBy(prop("lineItem"), certificates);

  return (
    <div className={classes.content}>
      <h3
        id={PAGE_TABS.ORDER_SUMMARY}
        className={classes.title}
      >
          Order Summary
      </h3>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell>PO Item No.</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Product Type</TableCell>
            <TableCell>Nominal Dimensions</TableCell>
            <TableCell>Material - Grade</TableCell>
            <TableCell>Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            <>
              {!lineItems.length && (
                <TableRow>
                  <TableCell colSpan={6}>
                      No data.
                  </TableCell>
                </TableRow>
              )}
              {lineItems.map((li) => {
                const certificates = grouppedByPoItem[li];
                const errors = [];

                const productTypes = certificates.map((certificate) => certificate.properties.productType.name);
                const mostCommonProductType = mode(productTypes);

                if(productTypes.filter((pt) => pt !== mostCommonProductType).length) errors.push("product type");

                const quantity = certificates.reduce((sum, certificate) => {
                  const quantity = certificate.items.reduce((sum, item) => Number(item.quantity) + sum, 0);

                  return quantity + sum;
                }, 0);

                const specifications = certificates.map((certificate) => `${certificate.properties.norm} - ${certificate.properties.grade}`);
                const mostCommonSpecification = mode(specifications);

                if(specifications.filter((pt) => pt !== mostCommonSpecification).length) errors.push("specification");

                const locations = certificates.map((certificate) => getLocationAddress(certificate.location));
                const mostCommonLocation = mode(locations);

                if(locations.filter((l) => l !== mostCommonLocation).length) errors.push("location");

                return (
                  <TableRow>
                    <TableCell>
                      <div className={classes.poItemContainer}>
                        <Tooltip title="Filter order by this PO Item">
                          <Link
                            className={classes.poItemName}
                            underline="hover"
                            onClick={() => {
                              const poItems = uniq([...filters[FILTERS.PO_ITEM_NO] || [], li]);
                              setFilters({...filters, [FILTERS.PO_ITEM_NO]: poItems});
                            }}
                          >
                            <span>{li}</span>
                          </Link>
                        </Tooltip>
                        {filters[FILTERS.PO_ITEM_NO]?.includes(li) && (
                          <Tooltip title="Remove filter order by this PO Item">
                            <IconButton
                              classes={{root: classes.removeIcon}}
                              size="small"
                              color="default"
                              onClick={() => {
                                const poItems = filters[FILTERS.PO_ITEM_NO].filter((poItem) => poItem !== li);
                                setFilters({...filters, [FILTERS.PO_ITEM_NO]: poItems});
                              }}
                            >
                              <CancelSharpIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!!errors.length && (
                          <Tooltip title={`Not all certificates for this PO Item have the same ${errors.join(", ")}`}>
                            <span>
                              <AiOutlineWarning className={classes.warningIcon} />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{quantity}</TableCell>
                    <TableCell>{mostCommonProductType}</TableCell>
                    <TableCell>
                      <CertificateDimensionsTags
                        certificates={certificates}
                      />
                    </TableCell>
                    <TableCell>{mostCommonSpecification}</TableCell>
                    <TableCell>{mostCommonLocation}</TableCell>
                  </TableRow>
                );}
              )}
            </>
          ) : (
            <TableSkeleton columns={6} />
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(OrderSummary, styles);