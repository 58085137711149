import Tags from "@core/components/Tags";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {STATUSES, STATUSES_DESCRIPTION, TYPES} from "@core/constants/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {getActivityNumber, getTestName, getTestResult} from "@core/helpers";
import {Box, Tooltip} from "@mui/material";
import moment from "moment";
import React from "react";

const MAX_NOTES_LENGTH = 50;

export default [
  {
    isSorted: "true",
    dataIndex: "type",
    title: "Test type",
    render: (test) => {
      const isChemicalCompTest = test.type === TYPES.CHEMICAL && test.properties.analysis;
      const testName = getTestName(test);

      return (
        <span>
          {testName}
          {isChemicalCompTest && ` - ${test.properties.analysis}`}
          {!!test.properties?.tags?.length && (
            <Box sx={{marginLeft: 1, display: "inline"}}>
              <Tags
                tags={test.properties.tags}
              />
            </Box>
          )}
        </span>
      );
    }
  }, {
    isSorted: "true",
    dataIndex: "_id",
    title: "Test ID",
    render: (test) => test._id || "-"
  }, {
    dataIndex: "activityNumber",
    title: "Activity Number",
    render: (test) => getActivityNumber(test) || "-"
  }, {
    dataIndex: "result",
    title: "Test Result",
    render: (test) => getTestResult(test).text
  }, {
    isSorted: "true",
    dataIndex: "date_created",
    title: "Requested on",
    render: (test) => moment(test.date_created).format(DATE_FORMAT) || "-"
  }, {
    dataIndex: "status",
    title: "Status",
    render: (test) => STATUSES_DESCRIPTION[test.status] || "-"
  }, {
    isSorted: "true",
    dataIndex: "company.name",
    title: "Requested by",
    render: (test) => {
      if (!test.company) return "Deleted company";

      if (test.company._id === test.assignee) return test.properties.client || "-";

      return test.company.name;
    }
  }, {
    isSorted: "true",
    dataIndex: "internalWorkOrder",
    title: "Work Order",
    render: (test) => test.internalWorkOrder || "-"
  }, {
    isSorted: "true",
    dataIndex: "internalItemNumber",
    title: "Work Order Item",
    render: (test) => test.internalItemNumber || "-"
  }, {
    isSorted: "true",
    dataIndex: "item_heat",
    title: "Heat",
    render: (test) => test.item_heat || "-"
  }, {
    isSorted: "true",
    dataIndex: "lotId",
    title: "Lot ID",
    render: (test) => test.lotId || "-"
  }, {
    dataIndex: "certificateId",
    title: "Material Certificate ID",
    render: (test) => test.certificateId || "-"
  }, {
    isSorted: "true",
    dataIndex: "norm",
    title: "Material Specification",
    render: (test) => test.properties.norm || test.norm || "-"
  }, {
    isSorted: "true",
    dataIndex: "grade",
    title: "Grade/UNS",
    render: (test) => test.properties.grade || test.grade || "-"
  }, {
    isSorted: "true",
    dataIndex: "productType",
    title: "Product type",
    render: (test) => test.productType?.name || "-",
  }, {
    dataIndex: "inspectionPlace",
    title: "Inspection place",
    render: (test) => {
      if(!test.witnesses?.length) return "-";

      if (test.assignee) return test.assignee.name;

      if (test.company) return test.company.name;

      return "-";
    }
  }, {
    isSorted: "true",
    dataIndex: "inspectionDate",
    title: "Inspection date",
    render: (test) => {
      if(test.inspectionWaivedDocument) return "Inspection waived";

      return test.inspectionDate ? moment(test.inspectionDate).format(DATE_FORMAT) : "-";
    },
  }, {
    isSorted: "true",
    dataIndex: "inspectorJobNumber",
    title: "Inspector job number",
    render: (test) => test.inspectorJobNumber || "-"
  }, {
    dataIndex: "inspectionStatus",
    title: "Inspection result",
    render: (test) => {
      if (![STATUSES.INSPECTED, STATUSES.APPROVED].includes(test.status) || !test.witnesses?.length) return "-";

      const isAccepted = test.witnesses?.find((witness) => witness.status === WITNESS_STATUSES.ACCEPTED);

      return isAccepted ? "Inspection passed" : "Inspection not passed";
    }
  }, {
    isSorted: "true",
    dataIndex: "witnesses",
    title: "Witnesses",
    render: (test) => test.witnesses?.map((item) => (item.company?.name)).join() || "-"
  }, {
    dataIndex: "notes",
    title: "Notes",
    render: (test) => {
      if (!test.notes) return "-";

      return test.notes.length > MAX_NOTES_LENGTH ? (
        <Tooltip title={test.notes}>
          <span>{`${test.notes.substring(0, MAX_NOTES_LENGTH)}...`}</span>
        </Tooltip>
      ) : test.notes;
    }
  }
];