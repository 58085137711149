import {selectClasses} from "@mui/material";

export default (theme) => ({
  selectRoot: {
    display: "flex",
    alignItems: "flex-end"
  },

  root: {
    width: "100%",
  },

  rootMargin: {
    marginTop: 10,
  },

  control: {
    display: "block",
    width: "100%"
  },

  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 15,
    padding: "13px 26px 13px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus, &:hover": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  inputNoLabel: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 15,
    padding: "13px 26px 13px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },

  label: {
    width: "100%",
    position: "relative",
    whiteSpace: "nowrap"

  },

  helperTest: {
    color: "#f44336",
    position: "absolute",
    overflow: "hidden",
    marginTop: 3,
    lineHeight: "15px",
  },
  placeholder: {
    display: "none"
  },

  error: {
    [`& .${selectClasses.select}`]: {
      borderColor: "#f44336",
      borderRadius: 4
    },
  },
  disabled: {
    "& div[aria-pressed]:hover": {
      cursor: "not-allowed",
      borderRadius: 4,
      borderColor: "#ced4da",
      boxShadow: "none",
    },

  },
  asteriskLabel: {
    position: "relative",
    "&:after" : {
      position: "absolute",
      content: "' *'",
      color: "#ff0000"
    }
  },
  asteriskField: {
    position: "relative",
    "&:after" : {
      position: "absolute",
      left: 1,
      top: -2,
      content: "' *'",
      color: "#e91e63",
      transform: "scale(.8)",
    }
  },
  selectAdornment: {
    marginBottom: 14,
    marginLeft: 2
  },
});

